import React from 'react'
import { useState, useEffect } from 'react';
import { Link,useNavigate } from 'react-router-dom';
import Https from '../../Https';
import AlertSuccess from '../ReturnResponse/AlertSuccess';
import AlertWarning from '../ReturnResponse/AlertWarning';
import AlertDanger from '../ReturnResponse/AlertDanger';

function ForgetPassword() {

    const navigate = useNavigate();
    const[successAlertMsg, setSuccessAlertMsg] = useState();
    const[warningAlertMsg, setWarningAlertMsg] = useState();
    const[dangerAlertMsg, setDangerAlertMsg] = useState();

    const [mobile, setMobile] = useState();

    const handleChange = (e) => {
        const mobile_value = e.target.value.replace(/\D/g, "");
        console.log(mobile_value.length);
        if(mobile_value.length>10)
        {
            return false;
        }
        else
        {
            setMobile(mobile_value);
        } 
    }
    const getPassword = () =>{
        if(mobile.length==10)
        {
            Https.post('/forget-password',{mobile:mobile}).then((response) =>{
                console.log(response);
                if(response.data.response.status===200)
                {
                    setSuccessAlertMsg(response.data.response.message);
                    setTimeout(() =>{
                        setSuccessAlertMsg('');
                    },5000);
                    setMobile('');
                }
                else
                {
                    setWarningAlertMsg(response.data.response.message);
                    setTimeout(() =>{
                        setWarningAlertMsg('');
                    },5000);
                }
                
            });
        }
        else
        {
            setWarningAlertMsg('Invalid Mobile Number.');
            setTimeout(() =>{
                setWarningAlertMsg('');
            },5000);
        }
    }


























  return (
    <>
    <div className="main-wrapper">
        <div className="page-content change-password-col register-col">
            <div className="list no-hairlines custom-form">
                <div className="back-btn"><a href="#" className="back link">
                        <img src="../../assets/img/left-arrow-big-black.svg" alt="" /></a>
                </div>
                <div className="register-icon">
                    <img src="assets/img/register-top-img.png" alt="" />
                </div>
                <div className="logo">
                    <a href="javascript:void(0)">
                        <img src="doccure_assets/img/logo.png" alt="Ace Medicare" />
                    </a>
                </div>
                <AlertSuccess alert={successAlertMsg} />
                <AlertWarning alert={warningAlertMsg} />
                <AlertDanger alert={dangerAlertMsg} />
                <div className="register-inner-col">
                    <div className="top-title">
                        <div>
                            <h3>Registered Mobile Number</h3>
                        </div>
                        
                    </div>
                    <ul className="change-list">
                        <li className="item-content item-input">
                            <div className="item-col">
                                <div className="item-input-wrap">
                                    <input type="text" className='allow_only_number' value={mobile} onChange={handleChange}   placeholder="Enter Mobile Number" />
                                </div>
                            </div>
                        </li>
                        <li className="item-content item-input">
                            <div className="item-input-wrap submit-btn-col">
                                <button type="button" onClick={getPassword} className="btn btn-submit">Get Password</button>
                            </div>
                        </li>
                    </ul>
                    <span className="login-back">Don't have an login ? <a href="/login">Login Now!</a></span>
                </div>
                <div className="register-inner-col">
                    <img src="doccure_assets/img/login-banner.png" alt="Ace Medicare" />
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default ForgetPassword