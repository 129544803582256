import React from "react";
import { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Https from "../../Https";
import AlertSuccess from "../ReturnResponse/AlertSuccess";
import AlertWarning from "../ReturnResponse/AlertWarning";
import AlertDanger from "../ReturnResponse/AlertDanger";

function StepSecond() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const navigate = useNavigate();
  const [successAlertMsg, setSuccessAlertMsg] = useState();
  const [warningAlertMsg, setWarningAlertMsg] = useState();
  const [dangerAlertMsg, setDangerAlertMsg] = useState();
  const [activeIndex, setActiveIndex] = useState(null);
  const userDetails = localStorage.getItem("user_info");
  const jsonUserDetails = JSON.parse(userDetails);
  const [fileName, setFileName] = useState("No file chosen");
  // get all departments
  const [departments, setDepartment] = useState([]);
  ////////// use effect for fetch active department list
  useEffect(() => {
    fetchAllDepartments();
  }, []);
  /////////   call api for get active department list
  const fetchAllDepartments = () => {
    Https.get("get_active_departments").then((response) => {
      setDepartment(response.data.data);
    });
  };
  // get all date & days
  const [dateDays, setDateDays] = useState([]);
  ////////// use effect for fetch all date & days list
  useEffect(() => {
    fetchDateDays();
  }, []);
  /////////   call api for get all date & days list
  const fetchDateDays = () => {
    Https.get("get_next_serven_days_with_date").then((response) => {
      setDateDays(response.data.data);
    });
  };
  // get all date & days
  const [schedules, setSchedules] = useState([]);
  ////////// use effect for fetch all date & days list
  useEffect(() => {
    fetchSchedules();
  }, []);
  /////////   call api for get all date & days list
  const fetchSchedules = () => {
    Https.get("get_schedules").then((response) => {
      setSchedules(response.data.data);
    });
  };
  const [required, setRequired] = useState(true);

  // get schedule on change department
  const onChangeDepartment = (event) => {
    setDepartmentId(event.target.value);
    if(event.target.value==12)
    {
      set_appointment_schedule_status("none");
      setRequired(false);
      // const input = document.getElementById("schedule_id");
      // input.removeAttribute("required");
    }
    else
    {
      set_appointment_schedule_status("inline");
      setRequired(true);
    }
  };

  const selectDay = (event) => {
    const selected_date =
      event.target.parentNode.getAttribute("data-selected_date");
    const selected_day =
      event.target.parentNode.getAttribute("data-selected_day");
    setAppointmentDate(selected_date);
    setAppointmentDay(selected_day);
    Https.post('get_selected_appointment_day_schedule',{selected_date:selected_date}).then(response=>{
      console.log(response);
      setSchedules(response.data);
   });
  };
  const handleItemClick = (index) => {
    local_appointmentDay = '';
    setActiveIndex(index);
  };

  // user local store step first data
  const appointment_step_second_deconde = JSON.parse(
    localStorage.getItem("appointment_step_second")
  );

  if (appointment_step_second_deconde === undefined || appointment_step_second_deconde === null || appointment_step_second_deconde === "" ) 
  {
    var local_department = "";
    var local_symptoms = "";
    var local_prescription = "";
    var local_appointmentMode = "";
    var local_appointmentDate = "";
    var local_appointmentDay = "";
    var local_appointmentSchedule = "";
    var local_fileName="";
  } else {
    var local_department = appointment_step_second_deconde.departmentId;
    var local_symptoms = appointment_step_second_deconde.symptoms;
    var local_prescription = appointment_step_second_deconde.prescription;
    var local_fileName = appointment_step_second_deconde.fileName;
    var local_appointmentMode = appointment_step_second_deconde.appointmentMode;
    var local_appointmentDate = appointment_step_second_deconde.appointmentDate;
    var local_appointmentDay = appointment_step_second_deconde.appointmentDay;
    var local_appointmentSchedule =
      appointment_step_second_deconde.appointmentSchedule;
  }

  const [departmentId, setDepartmentId] = useState(local_department);
  const [symptoms, setSymptoms] = useState(local_symptoms);
  const [prescription, setPrescription] = useState();
  const [appointmentMode, setAppointmentMode] = useState(local_appointmentMode);
  const [appointmentDate, setAppointmentDate] = useState(local_appointmentDate);
  const [appointmentDay, setAppointmentDay] = useState(local_appointmentDay);
  const [appointmentSchedule, setAppointmentSchedule] = useState(
    local_appointmentSchedule
  );

  const submitSecondStep = () => {
    const appointment_step_second = {
      departmentId: departmentId,
      symptoms: symptoms,
      prescription: prescription,
      appointmentMode: appointmentMode,
      appointmentDate: appointmentDate,
      appointmentDay: appointmentDay,
      appointmentSchedule: appointmentSchedule,
      fileName:fileName
    };
    if (false
      // appointmentDate == "" ||
      // appointmentDate == undefined ||
      // appointmentDate == null
    ) {
      setWarningAlertMsg("Please Select Appointment Date");
      setTimeout(() => {
        setWarningAlertMsg("");
      }, 5000);
    } else {
      localStorage.setItem(
        "appointment_step_second",
        JSON.stringify(appointment_step_second)
      );
      navigate("/appointment/step-third");
    }
  };
  const handleFileChange = (event) => {
    setPrescription(event.target.value);
    const selectedFile = event.target.files[0];
    setFileName(selectedFile ? selectedFile.name : "No file chosen");
  };
  const [appointment_schedule_status, set_appointment_schedule_status] = useState();
  const display_status = {
    display:appointment_schedule_status
  }
  return (
    <>
      <div className="main-wrapper">
        <div className="page-content change-password-col register-col">
          <div className="list no-hairlines custom-form">
            <div className="back-btn">
              <a href="#" className="back link">
                <img src="../../assets/img/left-arrow-big-black.svg" alt="" />
              </a>
            </div>

            <AlertSuccess alert={successAlertMsg} />
            <AlertWarning alert={warningAlertMsg} />
            <AlertDanger alert={dangerAlertMsg} />

            <div className="page-content00 profile-settings">
              <div className="container">
                <div className="tab-content">
                  <div className="tab-pane active" id="payment">
                    <div className="panel panel-default">
                      <div
                        id="collapseThree"
                        className="panel-collapse collapse"
                      >
                        <div className="panel-body">
                          <div className="widget-title">
                            <h4 className="font-600">Make Appointment</h4>
                          </div>
                          <form
                            action="javascript:void(0)"
                            onSubmit={submitSecondStep}
                          >
                            <div className="setting-widget">
                              <div className="list no-hairlines-md pricing-col no-border">
                                <ul>
                                  <li className="item-content item-input">
                                    <div className="item-col">
                                      <div className="item-title item-label">
                                        Department
                                        <span>*</span>
                                      </div>
                                      <div className="item-input-wrap">
                                        <select
                                          className="form-control"
                                          name="departmentId"
                                          id="departmentId"
                                          required="required"
                                          onChange={onChangeDepartment}
                                        >
                                          <option value="">
                                            {" "}
                                            Select Department{" "}
                                          </option>
                                          {departments.map(
                                            (department, index) => (
                                              <option
                                                value={department.id}
                                                selected={
                                                  department.id ==
                                                  local_department
                                                }
                                              >
                                                {department.name}
                                              </option>
                                            )
                                          )}
                                        </select>
                                        <span className="input-clear-button"></span>
                                      </div>
                                    </div>
                                  </li>
                                  <li className="item-content item-input">
                                    <div className="item-col">
                                      <div className="item-title item-label">
                                        Describe Your Symptoms?
                                        <span>*</span>
                                      </div>
                                      <div className="item-input-wrap">
                                        <textarea
                                          type="text"
                                          required
                                          placeholder="Describe Your Symptoms?"
                                          className="form-control appt"
                                          name="symptoms"
                                          onChange={(e) =>
                                            setSymptoms(e.target.value)
                                          }
                                          value={symptoms}
                                        />
                                        <span className="input-clear-button"></span>
                                      </div>
                                    </div>
                                  </li>
                                  {/* 
                                  <li className="item-content item-input">
                                    <div className="item-col">
                                      <div className="item-title item-label">
                                        Upload Your Prescription
                                      </div>
                                      <div className="item-input-wrap custom-file-input">
                                        <input
                                          class="input-hidden"
                                          type="file"
                                          id="prescription"
                                          name="prescription"
                                          multiple
                                          onChange={handleFileChange}
                                          value={prescription}
                                        />
                                        <span className="input-clear-button"></span>
                                        <label
                                          for="prescription"
                                          className="inputFileLabel"
                                        >
                                          Choose a file
                                        </label>
                                        <span class="file-name" id="fileName">
                                          {fileName}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  */}
                                  <li className="item-content item-input">
                                    <div className="item-col">
                                      <div className="item-title item-label">
                                        Appointment Mode
                                        <span>*</span>
                                      </div>
                                    </div>
                                  </li>
                                  <li className="for-tab-fullwidth">
                                    <div className="custom-control custom-radio custom-control-inline d-flex justify-content-between">
                                      <div>
                                        <input
                                          type="radio"
                                          className="custom-control-input"
                                          name="appointmentMode"
                                          onChange={(e) =>
                                            setAppointmentMode(e.target.value)
                                          }
                                          value="video"
                                          id="customRadio2"
                                        />
                                        <label
                                          className="custom-control-label"
                                          for="customRadio2"
                                        >
                                          <span>Video</span>
                                        </label>
                                      </div>
                                      <div>
                                        <input
                                          type="radio"
                                          className="custom-control-input"
                                          name="appointmentMode"
                                          onChange={(e) =>
                                            setAppointmentMode(e.target.value)
                                          }
                                          value="audio"
                                          id="customRadio1"
                                        />
                                        <label
                                          className="custom-control-label"
                                          for="customRadio1"
                                        >
                                          <span>Audio</span>
                                        </label>
                                      </div>
                                    </div>
                                  </li>
                                  
                                  <li className="for-tab-fullwidth" style={display_status}>
                                    <div className="date-col-day dateBox">
                                      {dateDays.map((dateDay, index) => (
                                        <div
                                          key={index}
                                          onClick={() => handleItemClick(index)}
                                          className={`dateAnchor ${
                                            index === activeIndex
                                              ? "active"
                                              : ""
                                          }`
                                        }
                                        >
                                          <a
                                            href="javascript:void(0)"
                                            onClick={selectDay}
                                            data-selected_date={
                                              dateDay.complete_date
                                            }
                                            data-selected_day={
                                              dateDay.complete_day
                                            }
                                            className="dateItem"
                                          >
                                            <span>{dateDay.date}</span>
                                            <sup>
                                              <strong>{dateDay.day}</strong>
                                            </sup>
                                          </a>
                                        </div>
                                      ))}
                                    </div>
                                  </li>
                
                                  <li className="item-content item-input" style={display_status}>
                                    <div className="item-col">
                                      <div className="item-title item-label">
                                        Schedule
                                        <span>*</span>
                                      </div>
                                      <div className="item-input-wrap">
                                        <select
                                          className="form-control"
                                          name="schedule_id"
                                          id="schedule_id"
                                          required={required}
                                          onChange={(e) =>
                                            setAppointmentSchedule(
                                              e.target.value
                                            )
                                          }
                                        >
                                          <option value="">
                                            {" "}
                                            Select Schedule{" "}
                                          </option>
                                          {schedules.map((schedule, index) => (
                                            <option
                                              value={schedule}
                                              selected={
                                                local_appointmentSchedule ==
                                                schedule
                                              }
                                            >
                                              {schedule}
                                            </option>
                                          ))}
                                        </select>
                                        <span className="input-clear-button"></span>
                                      </div>
                                    </div>
                                  </li>
                                  

                                </ul>
                              </div>
                            </div>
                            <div className="setting-widget">
                              <div className="list no-hairlines-md pricing-col no-border">
                                <ul>
                                  <li className="item-content item-input col-50">
                                    <div className="item-col">
                                      <Link
                                        to="/appointment/step-first"
                                        className="btn"
                                        style={{
                                          backgroundColor: "#20c0f3",
                                          borderRadius: "50px",
                                          textTransform: "uppercase",
                                          color: "#fff",
                                          border: "0",
                                          padding: "12px 15px",
                                          width: "100%",
                                          display: "inline-block"
                                        }}
                                      >
                                        Previous
                                      </Link>
                                    </div>
                                  </li>
                                  <li className="item-content item-input col-50 dob">
                                    <div className="item-col">
                                      <button type="submit" className="btn">
                                        {" "}
                                        Next
                                      </button>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default StepSecond;
