import React from 'react'
import { useState, useEffect,useRef } from 'react';
import { Link,useNavigate,useLocation,useParams } from 'react-router-dom';
import Https from '../../Https';
import AlertSuccess from '../ReturnResponse/AlertSuccess';
import AlertWarning from '../ReturnResponse/AlertWarning';
import AlertDanger from '../ReturnResponse/AlertDanger';
import axios from 'axios';

function AppointmentFollowUp() {
    const { pathname } = useLocation();
    useEffect(() => {
	  window.scrollTo(0, 0);
	}, [pathname]);
    const {appt_no} = useParams();
    const navigate = useNavigate();
    const[successAlertMsg, setSuccessAlertMsg] = useState();
    const[warningAlertMsg, setWarningAlertMsg] = useState();
    const[dangerAlertMsg, setDangerAlertMsg] = useState();

    // user local storage data
    const userDetails = localStorage.getItem('user_info');
    const jsonUserDetails = JSON.parse(userDetails);
    const userToken = jsonUserDetails.token;
    const [userId, setUserId] = useState(jsonUserDetails.user_info.id);
    //////////  get appointment follow up document row
    const [followUpApptStatus, setFollowUpApptStatus] = useState(false);
    useEffect(()=>{
        fetchAppointmentFollowUpDocumentRow();
    },[]);
    const fetchAppointmentFollowUpDocumentRow = () =>{
        const fdata = new FormData();
        fdata.append("userId",userId);
        fdata.append("appt_no",appt_no);
        Https.post('/get_appt_followup_document_by_appt_id',fdata).then((response) =>{
            if(response.data.code === 200)
            {
                setFollowUpApptStatus(true)
            }
        });  
    }
    
    if(followUpApptStatus==true)
    {
        var ApptFStatus = {
            display:"none"
        }
    }
    else
    {
        var ApptFStatus = {
        }
    }

    //////////  script for document upload
    const [documentFirst, setDocumentFirst] = useState(null);
    const [documentSecond, setDocumentSecond] = useState(null);
    const [documentThird, setDocumentThird] = useState(null);
    const [documentFour, setDocumentFour] = useState(null);
    const [documentFive, setDocumentFive] = useState(null);
    const [remarks, setRemarks] = useState(null);
    const handelDocumentFirst = (e) => {
        setDocumentFirst(e.target.files[0]);
    }
    const handelDocumentSecond = (e) => {
        setDocumentSecond(e.target.files[0]);
    }
    const handelDocumentThird = (e) => {
        setDocumentThird(e.target.files[0]);
    }
    const handelDocumentFour = (e) => {
        setDocumentFour(e.target.files[0]);
    }
    const handelDocumentFive = (e) => {
        setDocumentFive(e.target.files[0]);
    }

    const upload_followup_document = (event) => {
        event.preventDefault();
        const fdata = new FormData();
        fdata.append("documentFirst",documentFirst);
        fdata.append("documentSecond",documentSecond);
        fdata.append("documentThird",documentThird);
        fdata.append("documentFour",documentFour);
        fdata.append("documentFive",documentFive);
        fdata.append("remarks",remarks);
        fdata.append("userId",userId);
        fdata.append("appt_no",appt_no);
        Https.post('appointment_follow_up_document',fdata).then((response) => {
            console.log(response);
            // setSuccessAlertMsg(response.data.message);
            // setTimeout(() =>{
            //     setWarningAlertMsg('');
            //     window.location.reload();
            // },5000);
        })
    }
    //////////  script for appointment follow up
    const [followUpMessage, setFollowUpMessage] = useState();
    const AppointmentFlowUp = (event) => {
        event.preventDefault();
        const fdata = new FormData();
        fdata.append("message",followUpMessage);
        fdata.append("userId",userId);
        fdata.append("appt_no",appt_no);
        fdata.append("documentFirst",documentFirst);
        Https.post('store_appointment_follow_up',fdata).then((response) => {
            setSuccessAlertMsg(response.data.message);
            setTimeout(() =>{
                setWarningAlertMsg('');
                // window.location.reload();
                navigate('/my-appointment');
            },3000);
        })
    }
    //////////  get appointment follow up  row
    const [apptFollowUpStatus, setApptFollowUpStatus] = useState(false);
    useEffect(()=>{
        fetchAppointmentFollowUpRow();
    },[]);
    const fetchAppointmentFollowUpRow = () =>{
        const fdata = new FormData();
        fdata.append("userId",userId);
        fdata.append("appt_no",appt_no);
        Https.post('/get_last_appt_followup_user_id',fdata).then((response) =>{
            if(response.data.code === 200)
            {
                setApptFollowUpStatus(true)
            }
        });  
    }
    if(followUpApptStatus==true)
    {
        var ApptFollowUpReq = {
            display:"none"
        }
    }
    else
    {
        var ApptFollowUpReq = {
        }
    }
    //////////  script for get appointment follow up
    const [apptFollowUpList, setApptFollowUpList] = useState([]);
    useEffect(()=>{
        fetchAllApptFollowUp();
    },[]);
    const fetchAllApptFollowUp = () =>{
        const fdata = new FormData();
        fdata.append("userId",userId);
        fdata.append("appt_no",appt_no);
        Https.post('/get_all_appt_followup_user_id',fdata).then((response) =>{
            console.log('this is followup list update')
            console.log(response.data.data);
            setApptFollowUpList(response.data.data);
        });  
    }
    
  return (
    <>
    <div className="main-wrapper">
        <div className="page-content change-password-col register-col">
            <div className="list no-hairlines custom-form">
                <div className="tab-content">
                    <div className="tab-pane active" id="basic-info">
                        <div className="panel panel-default">
                            <div id="collapseOne" className="panel-collapse collapse in">
                                <div className="panel-body">
                                    <div className="setting-widget">
                                        <div className="list no-hairlines-md">
                                        <AlertSuccess alert={successAlertMsg} />
                                        <AlertWarning alert={warningAlertMsg} />
                                        <AlertDanger alert={dangerAlertMsg} />
                                            <div className="widget-title">
                                                <h5>Appointment Follow Up</h5>
                                            </div>
                                            <form enctype='multipart/form-data' method='post' onSubmit={AppointmentFlowUp} action='javascript:void(0)' style={ApptFollowUpReq}>
                                                <div className="row form-row">
                                                <div className="col-12 col-md-6">
                                                        <div className="form-group">
                                                            <label for="document_first">Upload Report or Other Document</label>
                                                            <input className="form-control" required name="document_first" id="document_first" type="file" style={{opacity:"inherit", position:"relative"}}
                                                            onChange={handelDocumentFirst}
                                                            /> 
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <div className="form-group">
                                                            <label for="document_five">Message</label>
                                                            <textarea
                                                            type="text"
                                                            required
                                                            placeholder="Describe Your Problem?"
                                                            className="form-control"
                                                            name="followUpMessage"
                                                            onChange={(e) =>
                                                                setFollowUpMessage(e.target.value)
                                                            }
                                                            value={followUpMessage}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            <div className="submit-section">
                                                <button type="submit" className="btn btn-primary submit-btn"> Submit</button>
                                            </div>
                                            </form>
                                 
                                            <form enctype='multipart/form-data' method='post'  onSubmit={upload_followup_document} action='javascript:void(0)' style={ApptFStatus}>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default AppointmentFollowUp