import React from 'react'
import { Link } from "react-router-dom"; 

export default function Footer() {
  return (
    <>
      <footer className="footer">
        <div className="footer-top">
        
        </div>
        {/* <div className="footer-bottom">
       
        </div> */}
    </footer>
    <header className="header header-trans">
        <nav className='mobileNavbar'>
            <ul className="mobile_nav_bar">
                <li>
                    <Link to="/home">
                        <div class="text-center mobileNavbarLiDiv">
                            <i class="fa fa-home" aria-hidden="true"></i>
                            <p>Home </p>
                        </div>
                    </Link>
                </li>
                <li>
                    {/*<Link to="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#appointment_model">*/}
                    <a href="/appointment/step-first">
                        <div class="text-center mobileNavbarLiDiv">
                            <i class="fa fa-medkit" aria-hidden="true"></i>
                            <p>Appointment </p>
                        </div>
                    </a>
                </li>
                <li>
                    <a href="javascript:void(0)">
                        <div class="text-center mobileNavbarLiDiv">
                            <i class="fas fa-share"></i>
                            <p>Refer & Earn </p>
                        </div>
                    </a>
                </li>
                <li>
                    <a href="tel:8603002000">
                        <div class="text-center mobileNavbarLiDiv">
                            <i class="fa fa-phone-square" aria-hidden="true"></i>
                            <p>Call </p>
                        </div>
                    </a>
                </li>
                <li>
                    <Link to="/manage-profile">
                        <div class="text-center mobileNavbarLiDiv">
                            <i class="fa fa-user" aria-hidden="true"></i>
                            <p>Profile </p>
                        </div>
                    </Link>
                </li>
            </ul>
        </nav>
    </header>
    </>
  )
}
