import React from 'react'
import { useState, useEffect } from 'react';
import { Link,useNavigate } from 'react-router-dom';
import Https from '../../Https';
import AlertSuccess from '../ReturnResponse/AlertSuccess';
import AlertWarning from '../ReturnResponse/AlertWarning';
import AlertDanger from '../ReturnResponse/AlertDanger';

function StepThird() {

    const navigate = useNavigate();
    const[successAlertMsg, setSuccessAlertMsg] = useState();
    const[warningAlertMsg, setWarningAlertMsg] = useState();
    const[dangerAlertMsg, setDangerAlertMsg] = useState();

    const [btn_status, set_btn_status] = useState(false);
    // user local storage data
    const userDetails = localStorage.getItem('user_info');
    const jsonUserDetails = JSON.parse(userDetails);
    const userToken = jsonUserDetails.token;
    const [userId, setUserId] = useState(jsonUserDetails.user_info.id);
    const [walletAmount, setWalletAmount] = useState();

    useEffect(() => {
        checkUser();
      }, []);
      const checkUser = () => {
        Https.get('get-patient-detail-by-id/'+userId).then((response) => {
          console.log('patient details');
          console.log(response)
          setWalletAmount(response.data.data.wallet_amount)
        });
      }
    
    // user local store step first data
    const appointment_step_first = JSON.parse(localStorage.getItem('appointment_step_first'));
    const appointment_step_second = JSON.parse(localStorage.getItem('appointment_step_second'));

    const [department, setDepartment] = useState([]);
    ////////// use effect for fetch active department list
    useEffect(()=>{
        fetchAllDepartments();
    },[]);
    /////////   call api for get active department list                
    const fetchAllDepartments = () =>{
        Https.post('/get_departments_row',{department_id:appointment_step_second.departmentId}).then((response) =>{
            console.log('get_departments_row');
            console.log(response);
            setDepartment(response.data.data);
        });  
    }
    var department_fee_discount = (department.fee * department.fee_discount_percent)/100;

    const bookAppointment = () => {
        const headers =  {"Authorization" : "Bearer ${userToken}"};
        Https.post('/book_appointment',{appointment_step_first:appointment_step_first,appointment_step_second:appointment_step_second,userId:userId}).then((response) =>{
            console.log(response.data);
            if(response.data.code==200)
            {
                setWarningAlertMsg(response.data.message);
                setTimeout(() =>{
                    setWarningAlertMsg('');
                },5000);
                localStorage.removeItem("appointment_step_first")
                localStorage.removeItem("appointment_step_second")
                navigate('/appointment/success-response/'+response.data.appointment_no);
            }
            else
            {
                setWarningAlertMsg(response.data.message);
                setTimeout(() =>{
                    setWarningAlertMsg('');
                },5000);
            }
        });
    }
    // alert(department.id);
    if(department.id==12)
    {
        var display_status = {
            display:"none"
        }
    }
    else
    {
        var display_status = {
            // display:"inline"
        }
    }
    const confirm_appointment_paynow = (event) => {
        set_btn_status(true);
        event.preventDefault();
        const fData = new FormData();
        fData.append("firstName",appointment_step_first.firstName);
        fData.append("last_name",appointment_step_first.last_name);
        fData.append("mobile",appointment_step_first.mobile);
        fData.append("email",appointment_step_first.email);
        fData.append("gender",appointment_step_first.gender);
        fData.append("age",appointment_step_first.age);
        fData.append("height",appointment_step_first.height);
        fData.append("weight",appointment_step_first.weight);
        fData.append("dibetic",appointment_step_first.dibetic);
        fData.append("bloodPressure",appointment_step_first.bloodPressure);
        fData.append("alcohol",appointment_step_first.alcohol);
        fData.append("smoke",appointment_step_first.smoke);
        fData.append("bloodSugure",appointment_step_first.bloodSugure);

        fData.append("departmentId",appointment_step_second.departmentId);
        fData.append("symptoms",appointment_step_second.symptoms);
        // fData.append("prescription",appointment_step_second.prescription);
        fData.append("prescription",prescriptionImage);
        fData.append("fileName",appointment_step_second.fileName);
        fData.append("appointmentMode",appointment_step_second.appointmentMode);
        fData.append("appointmentDate",appointment_step_second.appointmentDate);
        fData.append("appointmentDay",appointment_step_second.appointmentDay);
        fData.append("appointmentSchedule",appointment_step_second.appointmentSchedule);
        fData.append("userId",userId);
        console.log(fData);
        Https.post('make_payment/',fData).then(response =>{
            console.log(response);
            if(response.data.status=="direct_booking")
            {
                window.location.href = response.data.payment_response;
            }
            else
            {
                window.location.href = response.data.data.instrumentResponse.redirectInfo.url;
            }
            // console.log(response.data.data.instrumentResponse.redirectInfo.url);
            // window.location.href = response.data.data.instrumentResponse.redirectInfo.url;
           
        })
    }
    
    ///// image selection script
    const [prescriptionImage, setPrescriptionImage] = useState();
    const uploadPrescription = (e) => {
        setPrescriptionImage(e.target.files[0]);
    }
    

  return (
    <>
    <div className="main-wrapper">
        <div className="page-content change-password-col register-col">
            <div className="list no-hairlines custom-form">
                <div className="back-btn"><a href="#" className="back link">
                        <img src="../../assets/img/left-arrow-big-black.svg" alt="" /></a>
                </div>
                <AlertSuccess alert={successAlertMsg} />
                <AlertWarning alert={warningAlertMsg} />
                <AlertDanger alert={dangerAlertMsg} />
                <div class="page-content profile-settings">
                    <div class="container">
                        <div class="tab-content">
                            <div class="tab-pane active" id="checkout">
                                <div class="panel panel-default">
                                    <div id="collapseTwo" class="panel-collapse collapse">
                                        <div class="panel-body">
                                            <form method='post' onSubmit={confirm_appointment_paynow} action='javascript:void(0)'>
                                                <div class="booking-summary">
                                                    <div class="title-col">
                                                        <h5>Booking Summary</h5>
                                                    </div>
                                                    <div class="summary-col">
                                                        <div class="summary-col-inner">
                                                            <div>Name <span>{appointment_step_first.firstName}</span></div>
                                                            <div>Mobile <span>{appointment_step_first.mobile}</span></div>
                                                            <div>Email <span>{appointment_step_first.email}</span></div>
                                                            
                                                            <div>Age <span>{appointment_step_first.age} Year's</span></div>
                                                            <div>Height <span>{appointment_step_first.height}</span></div>
                                                            <div>Weight <span>{appointment_step_first.weight} Kg</span></div>
                                                            <div>Are You Diabetic? <span>{appointment_step_first.dibetic}</span></div>
                                                            <div>Have Blood Pressure? <span>{appointment_step_first.bloodPressure}</span></div>
                                                            <div>Do You Smoke? <span>{appointment_step_first.smoke}</span></div>
                                                            <div>Do You Drink Alcohol? <span>{appointment_step_first.alcohol}</span></div>
                                                        </div>
                                                    </div>
                                                    <div class="summary-col">
                                                        <div class="summary-col-inner">
                                                            <div>
                                                                Department <span>{department.name}</span>
                                                            </div>

                                                            <div>
                                                                Symptoms <span>{appointment_step_second.symptoms}</span>
                                                            </div>
                                                            <div style={display_status}>Date <span>{appointment_step_second.appointmentDate}</span></div>
                                                            <div style={display_status}>Day <span>{appointment_step_second.appointmentDay}</span></div>
                                                            <div style={display_status}>Time <span>{appointment_step_second.appointmentSchedule}</span></div>
                                                            <div>Appointment Fee <span>₹{department.fee}.00</span></div>
                                                            <div>Discount on Fee <span>- ₹{(+department_fee_discount) + (+walletAmount)}.00</span></div>
                                                            <div>Total Appt. Fee <span> ₹{department.fee - department_fee_discount - walletAmount}.00</span></div>
                                                        </div>
                                                    </div>
                                                    <br></br>
                                                    <div className="item-input-wrap custom-file-input">
                                                        <input
                                                        class="input-hidden"
                                                        type="file"
                                                        id="prescription"
                                                        name="prescription"
                                                        onChange={uploadPrescription}
                                                        
                                                        />
                                                        <span className="input-clear-button"></span>
                                                        <label
                                                        for="prescription"
                                                        className="inputFileLabel"
                                                        >
                                                        Choose a file
                                                        </label>
                                                        <span class="file-name" id="fileName">
                                                        Upload Prescription File
                                                        </span>
                                                    </div>
                                                    
                                                </div>
                                                <div className="setting-widget">
                                                    <div className="list no-hairlines-md pricing-col no-border">
                                                        <ul>
                                                            <li className="item-content item-input col-50 gender">
                                                                <div className="item-col">
                                                                    <Link to="/appointment/step-second" className="btn" style={{backgroundColor:"#20c0f3",borderRadius:"50px",textTransform:"uppercase",color:"#fff",border:"0",padding:"12px 15px",width:"100%",display:"inline-block"}}>Previous</Link>
                                                                </div>
                                                            </li>
                                                            <li className="item-content item-input col-50 dob">
                                                                <div className="item-col">
                                                                    <button type='submit' disabled={btn_status} className="btn"> Pay Now</button>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default StepThird