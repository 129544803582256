import React, { useState,useEffect } from 'react'
import { Link,useNavigate } from 'react-router-dom';
import Https from '../../Https'
import AlertDanger from '../ReturnResponse/AlertDanger'
import AlertSuccess from '../ReturnResponse/AlertSuccess'
import AlertWarning from '../ReturnResponse/AlertWarning'
export default function OrderSummary() {

    const[successAlertMsg, setSuccessAlertMsg] = useState();
    const[warningAlertMsg, setWarningAlertMsg] = useState();
    const[dangerAlertMsg, setDangerAlertMsg] = useState();
    const place_order = JSON.parse(localStorage.getItem('place_order'));

    var userDetails = JSON.parse(localStorage.getItem('user_info'));

    //////////  use effect for fetch package details
    const [myCarts, setMyCarts] = useState([]); 
    useEffect(() =>{ 
        fetch_my_session_cart_data();  
    },[]); 
    /////////   call api for get product with product details
    const fetch_my_session_cart_data = () =>{
        Https.get('/get-user-session-cart-data/'+userDetails.user_info.id).then(response=>{
            console.log('my cart') 
            setMyCarts(response.data);  
            console.log(response); 
        });  
    }
    var total_amount = 0;
    const listItems = myCarts.map((myCart) =>
        total_amount += (+myCart.sale_price)
    );

    const confirmOrder = () => {

    }
  return (
    <>
    <div className="main-wrapper">
        <div className="page-content change-password-col register-col">
            <div className="list no-hairlines custom-form">
                <div className="back-btn"><a href="#" className="back link">
                        <img src="../../assets/img/left-arrow-big-black.svg" alt="" /></a>
                </div>
                
                <AlertSuccess alert={successAlertMsg} />
                <AlertWarning alert={warningAlertMsg} />
                <AlertDanger alert={dangerAlertMsg} />


                <div class="page-content profile-settings">
                    <div class="container">
                        <div class="tab-content">
                        <div class="tab-pane active" id="checkout">
                            <div class="panel panel-default">
                                <div id="collapseTwo" class="panel-collapse collapse">
                                    <div class="panel-body">
                                    <form method='post' action='javascript:void(0)'>
                                        <div class="booking-summary">
                                            <div class="title-col">
                                                <h5>Order Summary</h5>
                                            </div>
                                            <div id="collapseTwo" class="panel-collapse collapse">
                                                <div class="panel-body">
                                                    <div class="patient-appointments-col">
                                                        <div class="patient-widget">
                                                            <div class="patient-top-details">
                                                                <div>
                                                                    <span class="invoice-id"><i class="fa fa-user" aria-hidden="true"></i> &nbsp;
                                                                     {place_order.firstName} {place_order.last_name}</span>
                                                                </div>
                                                                <div>
                                                                    <span class="date-col">
                                                                    <i class="fa fa-phone" aria-hidden="true"></i>&nbsp;
                                                                    {place_order.mobile}</span>
                                                                </div>
                                                            </div>
                                                            <div class="invoice-widget">
                                                                <div class="pat-info-left">
                                                                    <div class="pat-info-cont">
                                                                        <small class="pat-name"><i class="fa fa-map-marker" aria-hidden="true"></i>&nbsp;                                  {place_order.address} {place_order.pinCode} {place_order.pinCode} {place_order.city_district} {place_order.state}</small>
                                                                        <div class="patient-details-col">
                                                                            <span  class="pat-name">
                                                                            <i class="fas fa-rupee-sign"></i> &nbsp;
                                                                                {total_amount}.00
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        {myCarts.map((myCart, index)=>(
                                            <div class="tab-pane active" id="appoinments">
                                                <div class="panel panel-default">
                                                    <div id="collapseOne" class="panel-collapse collapse in">
                                                        <div class="panel-body">
                                                            <div class="patient-appointments-col">
                                                                <div class="patient-widget">
                                                                    <div class="invoice-widget">
                                                                        <div class="pat-info-left">
                                                                            <div class="patient-img">
                                                                                <a href={`/products/product-detail/${myCart.slug}`}>
                                                                                    <img src={`https://acemedicare-admin.hospicares.in/products/product/${myCart.main_image}`} class="rounded-circle" width="55" alt={myCart.title} />
                                                                                </a>
                                                                            </div>
                                                                            <div class="pat-info-cont">
                                                                                <h4 class="pat-name"><a href="#">{myCart.title}</a></h4>
                                                                                <div class="hour-col">
                                                                                    <div class="item-text"><i class="fas fa-rupee-sign"></i> {myCart.sale_price}.00<span class="smile"></span></div>
                                                                                </div>
                                                                                <div class="patient-details-col">
                                                                                    <span class="">
                                                                                    Qty: {myCart.session_qty} 
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        <div className="setting-widget">
                                            <div className="list no-hairlines-md pricing-col no-border">
                                                <ul>
                                                    <li className="item-content item-input col-50 gender">
                                                        <div className="item-col">
                                                            <Link to="/pharmacy/place-order" className="btn" style={{backgroundColor:"#20c0f3",borderRadius:"50px",textTransform:"uppercase",color:"#fff",border:"0",padding:"12px 15px",width:"100%",display:"inline-block"}}>Previous</Link>
                                                        </div>
                                                    </li>
                                                    <li className="item-content item-input col-50 dob">
                                                        <div className="item-col">
                                                            <button type='submit' onClick={confirmOrder} className="btn"> Confirm</button>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}
