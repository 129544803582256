import React, { useEffect, useState } from "react";
import Https from "../Https";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import HospitalAppointmentModel from "./modules/HospitalAppointmentModel";
import Header from "./includes/Header";
import Footer from "./includes/Footer";
import SearchPacakgeWithCity from "./modules/SearchPacakgeWithCity";
export const SERVER_URL = process.env.REACT_APP_GATEKEEPER_URL;


export default function SearchPackageResult(props) {
    
    // function for load comonent from top
	const { pathname } = useLocation();
	useEffect(() => {
	  window.scrollTo(0, 0);
	}, [pathname]);
    // variable for hospital
    const [hospitals, setHospitals] = useState([]);
    const {citys_id} = useParams();
    const {package_id} = useParams();
    const navigate = useNavigate();
    // alert(city_id + ' - ' + package_id);
    // define function for fetch hospital api
    useEffect(()=>{
        fetchSearchReasults();
    },[]);  

    // function for api get hospital list
    const fetchSearchReasults = () =>{
        Https.get('search-hospital-packages/'+citys_id+'/'+package_id).then(response=>{
            setHospitals(response.data);
            console.log(response.data);  
        });  
    }

    // variable for hospital image pat
    const [hosImgPath, sethosImgPath] = useState([]);
    // define function for fetch hospital api
    useEffect(()=>{
        fetchHosImgPath();
    },[]);
    // function for api get hospital image path
    const fetchHosImgPath = () =>{
        Https.get('vendor-image-path').then(response=>{
            sethosImgPath(response.data);
        });
    }
    //////////  variable & function for search form
    const [cities, setCities] = useState([]);
    const [treatPackages, setTreatPackages] = useState([]);

    ////////// use effect for fetch active package list
    useEffect(()=>{
        fetchAllPackages();
    },[]);
    /////////   call api for get active package list                
    const fetchAllPackages = () =>{
        Https.get('all-active-packages').then(response=>{
        setTreatPackages(response.data.data);
        });  
    }
     //////////  use effect for fetch state list
     useEffect(() =>{
        fetAllCities();
    },[]);
    //////////  call api for get all states
    const fetAllCities = () =>{
        Https.get('get-hospital-cities').then(response=>{
            setCities(response.data.data);
        })
    }
    const [cityInput, setCityInput] = useState(citys_id);
    const [packageInput, setPackageInput] = useState(package_id);
   
    const searchPackage = (e) =>{
        
        Https.get('search-hospital-packages/'+cityInput+'/'+packageInput).then(response =>{
            if(cityInput==undefined || packageInput==undefined)
            {

            }
            else
            {
                navigate('/search-package-results/'+cityInput+'/'+packageInput);
                window.location.reload(); 
            } 
        });
    }
    const selectCity = (e) =>{
        const selectedCityID = e.target.value;
        setCityInput(selectedCityID);
        Https.get('find-package-by-city-id/'+selectedCityID).then(response =>{
        //    console.log(response.data.data);
           setTreatPackages(response.data.data);
        });
    }
    const [hospitalId, setHospitalId] = useState();
    const [hospitalName, setHospitalName] = useState();
    const getHospital = (event) =>{
        const hospiName = event.target.attributes.getNamedItem('data-hospitalName').value;
        const hospiId = event.target.attributes.getNamedItem('data-hospitalId').value;
        setHospitalId(hospiId);
        setHospitalName(hospiName);
    }
    const [firstName, setFirstName] = useState("");
    const [last_name, setLastName] = useState("");
    const [mobile, setMobile] = useState("");
    const [email, setEmail] = useState("");
    const [problems, setProblems] = useState("");
    //    const [cityInput, setCityInput] = useState("");
    //    const [packageInput, setPackageInput] = useState("");
    const enquiryType = 'Hospital_Package';


   const submitInquiryforContactOtherTest = () => {
    
    }
    const testFunction = () =>{
        if(firstName=='' || mobile=='' || cityInput=='' || packageInput=='')
        {
        alert('All field is required');
        }
        else
        {
        Https.post('store-contact-inquiry',{first_name: firstName,last_name:last_name, mobile: mobile, email:email, problems: problems, enquiryType:enquiryType,city_id:cityInput,package_id:packageInput,hospitalId:hospitalId,hospitalName:hospitalName}).then(response => {
        console.log(response.status);
        if(response.status==200)
        {
            setFirstName("");
            setLastName("");
            setMobile("");
            setEmail("")
            setProblems("");
            alert('Your request has been registered successfully. One of our agent will get in touch soon. Thank You!');
            window.location.reload();

        }
        else
        {
            setFirstName("");
            setLastName("");
            setMobile("");
            setEmail("")
            setProblems("");
        }
        })
        }
    }

  return (
    <>
    <div className="main-wrapper">
        
        <div class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12 col-lg-12 col-xl-12">
                        <div>
                            <div>
                                <div>
                                    <SearchPacakgeWithCity package_slug={package_id} city_id={citys_id} />
                                </div>
                            </div>
                        </div>
                        <div class="page-content pt-0">
                            <div class="patients-list">
                                <div class="container">
                                {hospitals.map((hospital, index)=>(
                                    <div class="patient-widget">
                                        <div class="patient-top-details">
                                            <div>
                                                <span><i class="fas fa-map-marker-alt"></i> &nbsp; <small>{hospital.shop_address} {hospital.city_name} {hospital.state_name}</small></span>
                                            </div>
                                        </div>
                                        <div class="pat-info-left">
                                            <div class="patient-img" style={{flex:"0 0 100px"}}>
                                                <Link class="" to={`/${hospital.role_name}-package-detail/${hospital.shop_slug}/${hospital.package_slug}`}>
                                                    <img src={hosImgPath+'/'+hospital.vendor_img} class="img-fluid"alt="User Image" style={{borderRadius:"0px",height:"100px"}} />
                                                </Link>
                                            </div>
                                            <div class="pat-info-cont">
                                                <h4 class="pat-name">
                                                    <Link class="" to={`/${hospital.role_name}-package-detail/${hospital.shop_slug}/${hospital.package_slug}`}>{hospital.shop_name}</Link></h4>
                                                
                                                <div class="patient-details-col">
                                                    <h5 class="doc-department">Package: {hospital.package_name}</h5>
                                                </div>
                                                <div class="status-col">
                                                    <div class="status-btn">
                                                        <Link class="btn success" to={`/${hospital.role_name}-package-detail/${hospital.shop_slug}/${hospital.package_slug}`}>View Details</Link>
                                                        
                                                    </div>
                                                    <div class="status-btn">
                                                        <Link class="btn view-eye" to="#" onClick={(event)=>getHospital(event)}data-bs-toggle="modal" data-hospitalName={hospital.shop_name} data-hospitalId={hospital.vendor_pk_id} data-bs-target="#hospital_appointment_model">
                                                        Get Quote </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                </div>
                            </div>
                        </div>
                        {/* 
                        {hospitals.map((hospital, index)=>(
                        
                            <div class="card">
                                <div class="card-body">
                                    <div class="doctor-widget">
                                        <div class="doc-info-left">
                                            <div class="">
                                                <Link class="view-pro-btn" to="/"> 
                                                  
                                                    <img src={hosImgPath+'/'+hospital.vendor_img} class="" alt="User Image" style={{width: "300px", height: "230px"}} />
                                                </Link>
                                            </div>
                                            <div class="" style={{margin: "10px"}}>
                                                <h2 class="doc-name"><Link class="view-pro-btn" to="/">{hospital.shop_name}</Link></h2>
                                                
                                                <h5 class="doc-department">Package: {hospital.package_name}</h5>
                                                <p align="justify">{hospital.about_shop }</p>
                                                
                                                <div class="clinic-details">
                                                <p class="doc-location"><i class="fas fa-map-marker-alt"></i> {hospital.shop_address} {hospital.city_name} {hospital.state_name} <i class="fa fa-rupee"></i></p>
                                               
                                            </div>
                                           
                                            </div>
                                        </div> 
                                        <div class="doc-info-right">
                                        
                                        <div class="clinic-booking">  
                                            <Link class="view-pro-btn" to={`/${hospital.role_name}-package-detail/${hospital.shop_slug}/${hospital.package_slug}`}>View Details</Link>
                                            <Link class="view-pro-btn" to="#" onClick={(event)=>getHospital(event)}data-bs-toggle="modal" data-hospitalName={hospital.shop_name} data-hospitalId={hospital.vendor_pk_id} data-bs-target="#hospital_appointment_model">
                                            Get Quote </Link>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            
                        ))}
                        */}
                    </div>
                </div>
            </div>
        </div> 
    </div>
    {/* <HospitalAppointmentModel /> */}
    {/* apporntment model open here */}
    <div class="modal fade custom-modal custom-medicalrecord-modal" id="hospital_appointment_model"
        style={{display: "none;",paddingTop: "85px"}} data-select2-id="hospital_appointment_model" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Book Your FREE Consultation Now</h5>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
                </button>
            </div>
            <form id="medical_records_form" enctype="multipart/form-data">
                <div class="modal-body">
                <div class="row">
                    <div class="col-12 col-md-6" style={{borderRight: "1px dashed #333"}}>
                        <div class="widget about-widget">
                            <span class="widget-title"><strong>Simplifying Surgery Experience</strong></span>
                            <p><small>Consult with our expert surgeon for more than 50+ Procedures</small></p>
                        </div>
                        <div class="widget education-widget">
                            <h4 class="widget-title">Next Steps</h4>
                            <div class="experience-box">
                                <ul class="experience-list">
                                    <li>
                                        <div class="experience-user">
                                            <div class="before-circle"></div>
                                        </div>
                                        <div class="experience-content">
                                            <div class="timeline-content">
                                                <a href="javascript:void(0)" class="name"><small>Once you share your details, our care coordinator will get in touch with you.</small></a>
                                                <br/>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="experience-user">
                                            <div class="before-circle"></div>
                                        </div>
                                        <div class="experience-content">
                                            <div class="timeline-content">
                                                <a href="javascript:void(0)" class="name"><small>The coordinator will understand your symptoms and health condition in detail.</small></a>
                                                <br/>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="experience-user">
                                            <div class="before-circle"></div>
                                        </div>
                                        <div class="experience-content">
                                            <div class="timeline-content">
                                                <a href="javascript:void(0)" class="name"><small>Your consultation will be scheduled at the earliest.</small></a>
                                                <br/>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 border-right">
                        <form action="javascript:void(0)" onSubmit={submitInquiryforContactOtherTest}>
                            <strong>Patient Procedure</strong>
                            <div class="form-group">
                                {/* <label for="first_name">Patient Name</label> */}
                                <input type="text" class="form-control" placeholder="Patient Name" required name="firstName" onChange={e => setFirstName(e.target.value)} value={firstName} />
                            </div>
                            <div class="form-group">
                                <input type="text" required placeholder="Enter 10 Digit Mobile Number" class="form-control allow_only_number" maxlength="10" name="mobile" onChange={e => setMobile(e.target.value)} value={mobile}/>
                            </div>
                            <div class="form-group">
                                <input type="text" required placeholder="Hospital Name" class="form-control" name="hospitalName" value={hospitalName}/>
                                <input type="hidden" class="form-control" name="hospitalName" value={hospitalId}/>
                            </div>
                            <div class="form-group">
                                <select className="form-control" name="city_id" id="city_id" placeholder="Search Location" required="required" onChange={(e)=>selectCity(e)}>
                                    <option value=""> Select City </option>
                                    {cities.map((cities,index)=>(
                                    <option value={cities.cityRowId} selected={citys_id == cities.cityRowId} >{cities.cityName}</option>
                                    
                                    ))}
                                </select>
                            </div>
                            <div class="form-group">
                                <select className="form-control" name="package_id" id="package_id" required="required" onChange={e=>setPackageInput(e.target.value)} placeholder="Search Doctors, Clinics, Hospitals, Diseases Etc">
                                    <option value=""> Select Procedure </option>
                                    {treatPackages.map((treatPackages, index)=>(
                                        <option value={treatPackages.package_id} selected={package_id == treatPackages.id}>{treatPackages.package_name}</option>
                                    ))}
                                </select>
                            </div>
                            <div class="form-group text-center ">
                                <button type="submit" className="btn btn-primary submit-btn" onClick={testFunction}>Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
                </div>
            </form>
            </div>
        </div>
    </div>
    {/* appointment model close here */}
    </>
    
  )
}
