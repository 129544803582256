import React from "react";
import ServiceSideBar from "../modules/ServiceSideBar";

export default function PhysiotherapyatHome(){
  return(
    <>
      <div class="content">
            <div class="container-fluid">
                <div class="row">
                  <div class="col-sm-9">
                    <div class="card">
                      <div class="card-body">
                        <h3> What Exactly Is Physiotherapy? </h3><br></br>
                        <p className=""> Physiotherapy, also known as physical therapy, is an allied health profession that employs biomechanics or kinesiology, manual therapy, exercise therapy, and electrotherapy to assist patients in restoring, maintaining, and increasing their physical mobility, strength, and function.</p>
                        <p className=""> Physiotherapists can help patients regain mobility because they understand how the body works and are trained in clinical skills to assess, diagnose, and treat disabilities. Physiotherapists can assist patients in recovering from injuries and disabilities such as back pain, neck pain, knee pain, and ligament problems.</p>
                        <p className=""> Physiotherapy can also help patients recover from Parkinson's disease, paralysis, stroke, multiple sclerosis, and cerebral palsy. Furthermore, by treating patients at home, physiotherapists can heal both chronic and acute problems.</p><br></br>

                        <h3> The Advantages of Home Physiotherapy</h3><br></br>
                        <p className=""> Physiotherapy has been shown to be effective in restoring patients' health and improving their physical strength, function, and mobility over the years. To enhance your physiotherapy experience, our Physiotherapists will come to your home and perform physiotherapy. Some of the advantages of having a physiotherapist come to your home include:</p>
                        <ul>
                          <li>Allows for convenience</li>
                          <li>Provides individualised care</li>
                          <li>Accelerates the healing process</li>
                          <li>There are no mobility issues.</li>
                          <li>Improved time management</li>
                          <li>Cost-effective</li>
                          <li>Family assistance and supervision</li>
                        </ul>
                        <p className="">Say goodbye to all your physiotherapy treatment worries and hassles with our affordable and accountable home services.</p><br></br>

                        <h3> When Do You Require the Services of a Physiotherapist? </h3><br></br>
                        <p className=""> Call it the curse of the modern lifestyle, but knee pain, back pain, and neck strain have all become commonplace. When a certain type of pain persists over time and occurs after performing a specific movement, it is best to see a physiotherapist or hire a physiotherapist to come to your home. You can also use our home physiotherapy service for a more convenient treatment.</p>
                        <p className=""> People can seek physiotherapy for any of the following medical issues: neurological issues, neuromusculoskeletal issues, cardiovascular issues, and respiratory issues.</p><br></br>

                          <h3> How Can We Assist?</h3><br></br>
                          <p className=""> Our physiotherapists treat patients in the privacy and comfort of their own homes. They assess, diagnose, and develop a treatment plan based on the needs of the patient. A physiotherapy session lasts about 30 minutes, depending on the severity of the problem. To improve the patient's mobility, our experts assist with basic and advanced movement exercises. </p><br></br>

                          <h3> Why Should You Do Physiotherapy Exercises?</h3><br></br>
                          <p className=""> Physiotherapy exercises have been shown to be an effective way to treat and prevent injuries and pain caused by a variety of diseases.</p>
                          <p className=""> Physiotherapists are medically trained individuals who use these exercises to strengthen muscles and improve function. They evaluate a person's problems and then recommend the best set of physiotherapy exercises for quick relief. These exercises range from simple to advanced postures.aid in the repair of the body and the restoration of full bodily function </p>
                          <p className=""> You can expect any benefit from physiotherapy if you diligently follow up with the recommended exercises, just as you can expect any benefit from medication if you take the medicines as prescribed by a doctor.</p>
                          <p className=""> Physiotherapy also aids in the restoration of a healthy emotional and mental state, which is essential for holistic treatment.</p>
                      </div>
                      <div class="container-fluid">
                        <div class="row">
                            <div class="col-sm-4">
                              <h4>Paralysis</h4><br></br>
                              <p class="text-justify">Did you know that with frequent physiotherapy treatment, muscle tone may be increased, allowing the paralysed patient.</p><br></br>
                              <h4>Back Ache</h4><br></br>
                              <p class=""> Sedentism, bad posture, and regular travel can all contribute to chronic back discomfort. You no longer have to live with that anguish or live a limited life. </p><br></br>
                              <h4> Asthma</h4><br></br>
                              <p class="">Physiotherapy aids in respiratory muscle training, which lowers the start of asthma episodes and improves breathing and physical ability.</p><br></br>
                            </div>
                            <div class="col-sm-4">
                              <h4>Parkinson's Illness</h4><br></br>
                              <p className=""> Parkinsonism is a neurological movement condition. Physiotherapy can assist the patient in regaining mobility and independence.</p><br></br>
                              <h4> Obstructive Pulmonary Disease (COPD)</h4><br></br>
                              <p className="">Physiotherapy is used as part of a multidisciplinary rehabilitation programme and has been shown to be a beneficial intervention.</p><br></br>
                              <h4> Respiratory</h4><br></br>
                              <p className=""> Another prominent application of physiotherapy is in respiratory issues such as asthma, chronic obstructive pulmonary disease, and cystic fibrosis.</p><br></br>
                            </div>
                            <div class="col-sm-4">
                              <h4>Following a Heart Attack</h4><br></br>
                              <p className=""> Physiotherapy after a heart attack aids in the reduction of heart rate and blood pressure both at rest and during activity.</p><br></br>
                              <h4> Physiotherapy for Children</h4><br></br>
                              <p className=""> Physiotherapy is critical in assisting a kid with disabilities to realise their full potential. It aims to enhance and preserve the physical, psychological, and social well-being of the kid.</p><br></br>
                              <h4> Rehabilitation Surgery</h4><br></br>
                              <p className=""> If you have recently had surgery, physiotherapy at home can help you recover faster.With our experienced in-home physiotherapy treatment, you may regain your mobility.</p>
                            </div>
                        </div>
                      </div>
                      <div class="card-body">
                            <h3>Frequently Asked Questions</h3><br></br>
                            <h4>Ques:  Is physiotherapy the same as massage?</h4><br></br>
                            <p className="">Ans: No. Physiotherapy takes a scientific approach to problem resolution. While the therapy may include a few massage-like methods.</p><br></br>
                            <h4>Ques: What is the cost of each physiotherapy session?</h4><br></br>
                            <p className="">Ans: The cost of each session is determined by the patient's needs and treatment plan.</p><br></br>
                            <h4>Ques: Do you have a nearby physiotherapy treatment centre?</h4><br></br>
                            <p className="">Ans: No. Our specialists come to your home and treat you at your leisure.</p><br></br>
                            <h4>Ques: What is the length of each session?</h4><br></br>
                            <p className="">Ans: Each session can last up to one hour.</p><br></br>
                            <h4> Ques: Will the physiotherapist come to see me on weekends?</h4><br></br>
                            <p className="">Ans: Yes. Our expert will come to you on weekdays or weekends at a time that is convenient for you.</p>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
      </div>
    </>
  );
}