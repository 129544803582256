import Https from "../../Https";
import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import AlertDanger from "../ReturnResponse/AlertDanger";
import AlertSuccess from "../ReturnResponse/AlertSuccess";
import AlertWarning from "../ReturnResponse/AlertWarning";
import SearchAllCities from "./SearchAllCities";

export default function AppointmentModel() {
    const[successAlertMsg, setSuccessAlertMsg] = useState();
    const[warningAlertMsg, setWarningAlertMsg] = useState();
    const[dangerAlertMsg, setDangerAlertMsg] = useState();
    //////////  get all active cities for package
    // variabe for cities
    const [cities, setCities] = useState([]);
    const [indianCities, setIndianCities] = useState([]);
    //////////  use effect for fetch state list
    useEffect(() =>{
        fetAllCities();
    },[]);
    //////////  call api for get all states
    const fetAllCities = () =>{
        Https.get('get-hospital-cities').then(response=>{
            setCities(response.data.data);
            console.log(response);
        })
    }
    //////////  variable & function for all treatement packages
    // variable for active treatment packages
    const [treatPackages, setTreatPackages] = useState([]);

    ////////// use effect for fetch active package list
    useEffect(()=>{
        fetchAllPackages();
    },[]);
    /////////   call api for get active package list
    const fetchAllPackages = () =>{
        Https.get('all-active-packages').then(response=>{
        setTreatPackages(response.data.data);
        });
    }

    useEffect(() =>{
        selectIndianCity();
    },[]);
    const selectIndianCity = (e) =>{
        Https.get('get_all_india_cities/').then(response =>{
            console.log('india cities');
           console.log(response.data.data);
           setIndianCities(response.data.data);
        });
    }
    ///////////  get user detail from local storage  //////////
    const userDetails = localStorage.getItem('user_info');
    const jsonUserDetails = JSON.parse(userDetails);
    // console.log('user detail on home');
    // console.log(jsonUserDetails);
    const [btn_status, set_btn_status] = useState(false);



   const [firstName, setFirstName] = useState(jsonUserDetails.user_info.name);
   const [last_name, setLastName] = useState(jsonUserDetails.user_info.last_name);
   const [mobile, setMobile] = useState(jsonUserDetails.user_info.mobile);
   const [email, setEmail] = useState(jsonUserDetails.user_info.email);
   const [problems, setProblems] = useState("");
   const [cityInput, setCityInput] = useState("");
   const [enquiryTypeInput, setEnquiryTypeInput] = useState("");
   const [indiaCityInput, setIndiaCityInput] = useState("");
   const [packageInput, setPackageInput] = useState("");
   const enquiryType = 'Consultation';


   const submitInquiryforContactOtherTest = () => {
    alert('hello');
    
    }
    const testFunction = () =>{
        set_btn_status(true)
        if(firstName=='' || mobile=='' || cityInput=='' || packageInput=='')
        {
        alert('All field is required');
        }
        else
        {
        Https.post('store-contact-inquiry',{first_name: firstName,last_name:last_name, mobile: mobile, email:email, problems: problems, enquiryType:enquiryType,city_id:cityInput,package_id:packageInput}).then(response => {
        console.log(response.status);
        if(response.status==200)
        {
            setFirstName("");
            setLastName("");
            setMobile("");
            setEmail("")
            setProblems("");
            alert('Your request has been registered successfully. One of our agent will get in touch soon. Thank You!');
            window.location.reload();

        }
        else
        {
            setFirstName("");
            setLastName("");
            setMobile("");
            setEmail("")
            setProblems("");
        }
        })
        }
    }
    const selectCity = (e) =>{
        const selectedCityID = e.target.value;
        setCityInput(selectedCityID);
        Https.get('find-package-by-city-id/'+selectedCityID).then(response =>{
        //    console.log(response.data.data);
           setTreatPackages(response.data.data);
        });
    }










    const ourServiceInquiry = () =>{
        set_btn_status(true)
        if(firstName=='' || mobile=='' || indiaCityInput=='' || enquiryTypeInput=='')
        {
            setWarningAlertMsg('All field is required');
            setTimeout(() =>{
                setWarningAlertMsg('');
            },5000);
            
        }
        else
        {
        Https.post('store-contact-inquiry',{first_name: firstName,last_name:last_name, mobile: mobile, email:email, problems: problems, enquiryType:enquiryTypeInput,city_id:indiaCityInput}).then(response => {
        console.log(response.status);
        if(response.status==200)
        {
            // alert('Your request has been registered successfully. One of our agent will get in touch soon. Thank You!');
            setSuccessAlertMsg("Your request has been registered successfully. One of our agent will get in touch soon. Thank You!");
            // setTimeout(() =>{
            //     setSuccessAlertMsg('');
            //     window.location.reload();
            // },3000);
            setFirstName("");
            setLastName("");
            setMobile("");
            setEmail("")
            setProblems("");
            window.location.reload();

        }
        else
        {
            setFirstName("");
            setLastName("");
            setMobile("");
            setEmail("")
            setProblems("");
        }
        })
        }
    }








    const [searchResultStatus, setSearchResultStatus] = useState(false);
    const [searchCities, setSearchCities] = useState([]);
    const [inputCityName, setInputCityName] = useState();
    const [cityInputId, setCityInputId] = useState();
    // const [indiaCityInput, setIndiaCityInput] = useState();


    const liveSearchCity = (event) => {
        var searchData = event.target.value;
        
        if(searchData==null || searchData==''|| searchData==undefined)
        {
            setSearchResultStatus(false);
        }
        else
        {
            setSearchResultStatus(true);
        }
        setInputCityName(searchData);
        Https.get('search-city-with-city-name/'+inputCityName).then(response=>{
            setSearchCities(response.data.data);
            console.log('search city result')
            console.log(response);
        });
     }

     const searchCityResult = (event) => {
        const city_id = event.target.getAttribute('data-city_id');
        const city_name = event.target.getAttribute('data-city_name');
        setIndiaCityInput(city_id);
        setCityInputId(city_id);
        setInputCityName(city_name);
        setSearchResultStatus(false);
     }
    

  return (
    <>
         <div className="modal fade custom-modal custom-medicalrecord-modal" id="appointment_model"
        style={{display: "none;",paddingTop: "32px"}} data-select2-id="appointment_model" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">Book FREE Consultation Now</h5>
                    <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                    </button>
                </div>
                
                <form id="medical_records_form" enctype="multipart/form-data">
                    <div className="modal-body">
                    <div className="row">
                    <AlertSuccess alert={successAlertMsg} />
                    <AlertWarning alert={warningAlertMsg} />
                    <AlertDanger alert={dangerAlertMsg} />
                    <div className="col-12 col-md-6 border-right">
                        <form action="javascript:void(0)" onSubmit={submitInquiryforContactOtherTest}>
                            <strong>Patient Procedure</strong>
                            <div className="form-group">
                                {/* <label for="first_name">Patient Name</label> */}
                                <input type="text" className="form-control" placeholder="Patient Name" required name="firstName" onChange={e => setFirstName(e.target.value)} value={firstName} />
                            </div>
                            <div className="form-group">
                                <input type="text" required placeholder="Enter 10 Digit Mobile Number" className="form-control allow_only_number" maxlength="10" name="mobile" onChange={e => setMobile(e.target.value)} value={mobile}/>
                            </div>
                            <div className="form-group">
                                <select className="form-control" name="city_id" id="city_id" placeholder="Search Location" required="required" onChange={e=>setCityInput(e.target.value)}>
                                    <option value=""> Select City </option>
                                    {cities.map((cities,index)=>(
                                    <option value={cities.cityRowId}>{cities.cityName}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-group">
                                <select className="form-control" name="package_id" id="package_id" required="required" onChange={e=>setPackageInput(e.target.value)} placeholder="Search Doctors, Clinics, Hospitals, Diseases Etc">
                                    <option value=""> Select Procedure </option>
                                    {treatPackages.map((treatPackages, index)=>(
                                        <option value={treatPackages.id}>{treatPackages.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-group text-center ">
                                <button type="submit" disabled={btn_status} className="btn btn-primary submit-btn" onClick={testFunction}>Submit</button>
                            </div>
                        </form>
                    </div>
                        <div className="col-12 col-md-6" style={{borderRight: "1px dashed #333"}}>
                            <div className="widget education-widget">
                                <h4 className="widget-title">Next Steps</h4>
                                <div className="experience-box">
                                    <ul className="experience-list">
                                        <li>
                                            <div className="experience-user">
                                               <div className="before-circle"></div>
                                            </div>
                                            <div className="experience-content">
                                                <div className="timeline-content">
                                                    <a href="javascript:void(0)" className="name"><small>Once you share your details, our care coordinator will get in touch with you.</small></a>
                                                    <br/>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="experience-user">
                                                <div className="before-circle"></div>
                                            </div>
                                            <div className="experience-content">
                                                <div className="timeline-content">
                                                    <a href="javascript:void(0)" className="name"><small>The coordinator will understand your symptoms and health condition in detail.</small></a>
                                                    <br/>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="experience-user">
                                                <div className="before-circle"></div>
                                            </div>
                                            <div className="experience-content">
                                                <div className="timeline-content">
                                                    <a href="javascript:void(0)" className="name"><small>Your consultation will be scheduled at the earliest.</small></a>
                                                    <br/>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    </div>
                </form>
                </div>
            </div>
        </div>
        
        {/* Ambulance service Appointment Model */}
        <div className="modal fade custom-modal custom-medicalrecord-modal" id="getAnAmbulance_model"
        style={{display: "none;",paddingTop: "32px"}} data-select2-id="getAnAmbulance_model" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">Book Your Ambulance Now</h5>
                    <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                    </button>
                </div>
                <form id="medical_records_form" enctype="multipart/form-data">
                    <div className="modal-body">
                    <div className="row">
                    <AlertSuccess alert={successAlertMsg} />
                    <AlertWarning alert={warningAlertMsg} />
                    <AlertDanger alert={dangerAlertMsg} />
                        <div className="col-12 col-md-6 border-right">
                            <form action="javascript:void(0)" onSubmit={submitInquiryforContactOtherTest}>
                                <strong>Patient Name</strong>
                                <div className="form-group">
                                    {/* <label for="first_name">Patient Name</label> */}
                                    <input type="text" className="form-control" placeholder="Patient Name" required name="firstName" onChange={e => setFirstName(e.target.value)} value={firstName} />
                                </div>
                                <div className="form-group">
                                    <input type="text" required placeholder="Enter 10 Digit Mobile Number" className="form-control allow_only_number" maxlength="10" name="mobile" onChange={e => setMobile(e.target.value)} value={mobile}/>
                                </div>
                                <div className="form-group">
                                    {/*
                                    <select className="form-control" name="indian_city_id" id="indian_city_id" placeholder="Search Location" required="required" onChange={e=>setIndiaCityInput(e.target.value)}>
                                        <option value=""> Select City </option>
                                        {indianCities.map((indianCities,index)=>(
                                        <option value={indianCities.city_id}>{indianCities.city_name}</option>
                                        ))}
                                    </select>
                                    */}
                                    <input type="text" className="form-control" value={inputCityName} onChange={liveSearchCity} required="required" placeholder="Search City" />
                                    {
                                    searchResultStatus ?
                                    <div className='dropdown' style={{position:"absolute","background":"#fff",padding: "5px 5px 5px 5px",width:"380px","zIndex":"1",height:"200px",overflowX: "hidden",overflowY: "auto"}}>
                                    {searchCities.map((searchCitie, index)=>(
                                        <div className="dropdown-row" style={{padding: "5px 5px 5px 5px"}}>
                                            <a href='javascript:void(0)' onClick={searchCityResult} data-city_name={searchCitie.name} data-city_id={searchCitie.id} >{searchCitie.name}</a>
                                        </div>
                                    ))}
                                    </div>
                                    :
                                    null
                                    }
                                </div>
                                <div className="form-group">
                                    <select className="form-control" name="package_id" id="package_id" required="required" onChange={e=>setEnquiryTypeInput(e.target.value)}>
                                        <option value=""> Select Ambulance </option>
                                        <option value="5">Air Ambulance</option>
                                        <option value="6">ICU Ambulance</option>
                                        <option value="7">Doctor Ambulance</option>
                                        <option value="8">Nurse Ambulance</option>
                                        <option value="9">Dead Body Ambulance</option>
                                    </select>
                                </div>
                                <div className="form-group text-center ">
                                    <button type="submit" disabled={btn_status} className="btn btn-primary submit-btn" onClick={ourServiceInquiry}>Submit</button>
                                </div>
                            </form>
                        </div>
                        <div className="col-12 col-md-6" style={{borderRight: "1px dashed #333"}}>
                            <div className="widget education-widget">
                                <h4 className="widget-title">Next Steps</h4>
                                <div className="experience-box">
                                    <ul className="experience-list">
                                        <li>
                                            <div className="experience-user">
                                            <div className="before-circle"></div>
                                            </div>
                                            <div className="experience-content">
                                                <div className="timeline-content">
                                                    <a href="javascript:void(0)" className="name"><small>Once you share your details, our care coordinator will get in touch with you.</small></a>
                                                    <br/>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="experience-user">
                                                <div className="before-circle"></div>
                                            </div>
                                            <div className="experience-content">
                                                <div className="timeline-content">
                                                    <a href="javascript:void(0)" className="name"><small>The coordinator will understand your symptoms and health condition in detail.</small></a>
                                                    <br/>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="experience-user">
                                                <div className="before-circle"></div>
                                            </div>
                                            <div className="experience-content">
                                                <div className="timeline-content">
                                                    <a href="javascript:void(0)" className="name"><small>Your consultation will be scheduled at the earliest.</small></a>
                                                    <br/>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </form>
                </div>
            </div>
        </div>
        {/* Pharmacy pop Model */}
        <div className="modal fade custom-modal custom-medicalrecord-modal" id="pharmacy_model"
        style={{display: "none;",paddingTop: "85px"}} data-select2-id="pharmacy_model" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">coming soon</h5>
                    <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                    </button>
                </div>
                
                </div>
            </div>
        </div>
        {/* Home Care service Appointment Model */}
        <div className="modal fade custom-modal custom-medicalrecord-modal" id="homeCare_model"
        style={{display: "none;",paddingTop: "85px"}} data-select2-id="homeCare_model" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">Book Home Care Service Now</h5>
                    <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                    </button>
                </div>
                <form id="medical_records_form" enctype="multipart/form-data">
                    <div className="modal-body">
                    <div className="row">
                    <AlertSuccess alert={successAlertMsg} />
                    <AlertWarning alert={warningAlertMsg} />
                    <AlertDanger alert={dangerAlertMsg} />
                        <div className="col-12 col-md-6" style={{borderRight: "1px dashed #333"}}>
                            <div className="widget education-widget">
                                <h4 className="widget-title">Next Steps</h4>
                                <div className="experience-box">
                                    <ul className="experience-list">
                                        <li>
                                            <div className="experience-user">
                                            <div className="before-circle"></div>
                                            </div>
                                            <div className="experience-content">
                                                <div className="timeline-content">
                                                    <a href="javascript:void(0)" className="name"><small>Once you share your details, our care coordinator will get in touch with you.</small></a>
                                                    <br/>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="experience-user">
                                                <div className="before-circle"></div>
                                            </div>
                                            <div className="experience-content">
                                                <div className="timeline-content">
                                                    <a href="javascript:void(0)" className="name"><small>The coordinator will understand your symptoms and health condition in detail.</small></a>
                                                    <br/>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="experience-user">
                                                <div className="before-circle"></div>
                                            </div>
                                            <div className="experience-content">
                                                <div className="timeline-content">
                                                    <a href="javascript:void(0)" className="name"><small>Your consultation will be scheduled at the earliest.</small></a>
                                                    <br/>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 border-right">
                            <form action="javascript:void(0)" onSubmit={submitInquiryforContactOtherTest}>
                                <strong>Patient Name</strong>
                                <div className="form-group">
                                    {/* <label for="first_name">Patient Name</label> */}
                                    <input type="text" className="form-control" placeholder="Patient Name" required name="firstName" onChange={e => setFirstName(e.target.value)} value={firstName} />
                                </div>
                                <div className="form-group">
                                    <input type="text" required placeholder="Enter 10 Digit Mobile Number" className="form-control allow_only_number" maxlength="10" name="mobile" onChange={e => setMobile(e.target.value)} value={mobile}/>
                                </div>
                                <div className="form-group">
                                    {/*
                                    <select className="form-control" name="indian_city_id" id="indian_city_id" placeholder="Search Location" required="required" onChange={e=>setIndiaCityInput(e.target.value)}>
                                        <option value=""> Select City </option>
                                        {indianCities.map((indianCities,index)=>(
                                        <option value={indianCities.city_id}>{indianCities.city_name}</option>
                                        ))}
                                    </select>
                                    */}
                                    <input type="text" className="form-control" value={inputCityName} onChange={liveSearchCity} required="required"
                                    placeholder="Search City"  />
                                    {
                                    searchResultStatus ?
                                    <div className='dropdown' style={{position:"absolute","background":"#fff",padding: "5px 5px 5px 5px",width:"490px","zIndex":"1"}}>
                                    {searchCities.map((searchCitie, index)=>(
                                        <div className="dropdown-row" style={{padding: "5px 5px 5px 5px"}}>
                                            <a href='javascript:void(0)' onClick={searchCityResult} data-city_name={searchCitie.name} data-city_id={searchCitie.id} >{searchCitie.name}</a>
                                        </div>
                                    ))}
                                    </div>
                                    :
                                    null
                                    }
                                </div>
                                <div className="form-group">
                                    <select className="form-control" name="package_id" id="package_id" required="required" onChange={e=>setEnquiryTypeInput(e.target.value)}>
                                        <option value=""> Select Home Care Service </option>
                                        <option value="11">Doctors At Home</option>
                                        <option value="12">Nurse At Home</option>
                                        <option value="13">Physiotherapist At Home</option>
                                        <option value="14">IcuSetup At Home</option>
                                    </select>
                                </div>
                                <div className="form-group text-center ">
                                    <button type="submit" disabled={btn_status} className="btn btn-primary submit-btn" onClick={ourServiceInquiry}>Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                    </div>
                </form>
                </div>
            </div>
        </div>

        




    </>
    )
}