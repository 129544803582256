import React, { useEffect, useState } from "react";
import Https from "../Https";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import DoctoratHome from "./HomeCareServices/DoctoratHome";
import ICUsetupatHome from "./HomeCareServices/ICUsetupatHome";
import NurseatHome from "./HomeCareServices/NurseatHome";
import PhysiotherapyatHome from "./HomeCareServices/PhysiotherapyatHome";
import Header from "./includes/Header";
import Footer from "./includes/Footer";
import Bottom from "./modules/Bottom";


export default function HomeCareEnquiry() {
	// function for load comonent from top
	const { pathname } = useLocation();
	const modify_pathName = pathname.replace('/','');
	useEffect(() => {
	  window.scrollTo(0, 0);
	}, [pathname]);
	var serviceType = '';


    const {type} = useParams();

	if(type=='doctors')
	{
		serviceType = <DoctoratHome />;
	}
	if(type=='nurse')
	{
		serviceType = <NurseatHome />;
	}
	if(type=='physiotherapist')
	{
		serviceType = <PhysiotherapyatHome />;
	}
	if(type=='icuSetup')
	{
		serviceType = <ICUsetupatHome />;
	}


	const [serviceCities, setServiceCities] = useState([]);
	//////////  use effect for fetch state list
	useEffect(() =>{
		fetAllCities();
	},[]); 
	// alert(modify_pathName);
	//////////  call api for get all states
	const fetAllCities = () =>{
		Https.get('ace-service-cities/'+modify_pathName).then(response=>{
			console.log(response.data.data); 
			setServiceCities(response.data.data);
		})
	}


	const [firstName, setFirstName] = useState("");
	const [last_name, setLastName] = useState("");
	const [mobile, setMobile] = useState("");
	const [email, setEmail] = useState("");
	const [passport_no, setPassportNo] = useState("");
	const [age, setAge] = useState("");
	const [gender, setGender] = useState("");
	const [nationality ,setNationality] = useState("");
	const [messages, setMessages] = useState("");
	const [cityInput, setCityInput] = useState("");
	const enquiryType = type+' at home';

	const submitInquiryforContactOther = () => {
		if(firstName=='' || mobile=='')
		{
			alert('Name or Mobile Number field is required');
		}
		else
		{
			Https.post('store-contact-inquiry',{first_name: firstName,last_name:last_name, mobile: mobile, email:email,passport_no:passport_no,age: age,gender:gender, nationality:nationality, messages: messages, enquiryType:enquiryType}).then(response => {
			console.log(response.status);
				if(response.status==200)
				{
					setFirstName("");
					setLastName("");
					setMobile("");
					setEmail("")
					setPassportNo("");
					setAge("");
					setGender("");
					setNationality("")
					setMessages("");
					alert('Your request has been registered successfully. One of our agent will get in touch soon. Thank You!');

				}
				else
				{
					setFirstName("");
					setLastName("");
					setMobile("");
					setEmail("")
					setPassportNo("");
					setAge("");
					setGender("");
					setNationality("")
					setMessages("");
				}
			})
		}
	}
  return (
    <>
	    <div className="main-wrapper">
			<section class="section section-search-1">
			<div class="container">
				<div class="breadcrumb-bar">
				<div class="container-fluid">
					<div class="row align-items-center">
						<div class="col-md-12 col-12">
							
						</div>
					</div>
				</div>
				</div>
				<div class="row ">
				<div class="col-md-6 mb-6 search-doctor">
					<div class="search-area" style={{ maxWidth: "545px" }}>
					<h2 class="text-center">Enquiry for <span style={{ textTransform: "capitalize", color: "red" }}>{type}</span> at Home</h2>
					<form class="search-input" action="javascript:void(0)" onSubmit={submitInquiryforContactOther}>
						<div class="row">
						<div class="col-6 col-md-6">
							<div class="form-group">
							<label>First Name</label>
							<input type="text" class="form-control" required name="firstName" onChange={e => setFirstName(e.target.value)} value={firstName} />
							</div>
						</div>
						<div class="col-6 col-md-6">
							<div class="form-group">
							<label>Last Name</label>
							<input className="form-control" type="text" name="last_name" onChange={e => setLastName(e.target.value)} value={last_name} />
							</div>
						</div>
						<div class="col-6 col-md-6">
							<div class="form-group">
							<label>Mobile No</label>
							<input type="text" required class="form-control allow_only_number" maxlength="10" name="mobile" onChange={e => setMobile(e.target.value)} value={mobile} />
							</div>
						</div>
						<div class="col-6 col-md-6">
							<div class="form-group">
							<label>Email ID</label>
							<input className="form-control" required type="email" name="email" onChange={e => setEmail(e.target.value)} value={email} />
							</div>
						</div>
						<div class="col-6 col-md-6">
							<div class="form-group">
							<label>City</label>
							<select className="form-control" name="city_id" id="city_id" placeholder="Search Location" required="required" onChange={e=>setCityInput(e.target.value)}>
								<option value=""> Select Cities </option>
								{serviceCities.map((serviceCity,index)=>(
								<option value={serviceCity.city_id}>{serviceCity.city_name}</option>
								))}
							</select>
							{/* <input type="text" class="form-control" name="age" onChange={e => setAge(e.target.value)} value={age} /> */}
							</div>
						</div>
						<div class="col-6 col-md-6">
							<div class="form-group">
							<label>Gender</label>
							<select class="form-select form-control" name="gender" onChange={e => setGender(e.target.value)}>
								<option value="Male">Male</option>
								<option value="FeMale">FeMale</option>
								<option value="Other">Other</option>
							</select>
							</div>
						</div>
						<div class="col-12 col-md-12">
							<div class="form-group">
							<label>Message</label>
							<input type="text" class="form-control" name="messages" onChange={e => setMessages(e.target.value)} value={messages} />
							</div>
						</div>
						</div>
						<div class="submit-section">
						<button type="submit" class="btn btn-primary search-btn submit-btn">Submit</button>
						</div>
					</form>
					</div>
				</div>
				<div class="col-md-6">
					<img src="../doccure_assets/img/gulsanji_4.png" class="img-fluid dr-img" alt="" style={{width:"500px"}} />
				</div>
				</div>
			</div>
			</section>
			{serviceType}
			<Bottom />
		</div>
    </>
  )
}
