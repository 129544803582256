import { useState, useEffect,useRef } from 'react';
import { Link,useNavigate,useLocation,useParams } from 'react-router-dom';
function DoctorOnCall(props) {
    const {appt_no} = useParams();
  return (
    <div>
        {/*
        <iframe src={`http://localhost:3001/${appt_no}`} allow="camera;microphone" style={{"width":"100%","height": "440px"}}></iframe>
        */}
        <iframe src={`https://zoom.hospicares.in/${appt_no}`} allow="camera;microphone" style={{"width":"100%","height": "800px"}}></iframe>
    </div>
  )
}

export default DoctorOnCall
