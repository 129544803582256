import React from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { useState, useEffect} from 'react'
import Https from '../Https';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UserAuth from '../UserAuth';
import AlertSuccess from './ReturnResponse/AlertSuccess';
import AlertWarning from './ReturnResponse/AlertWarning';
import AlertDanger from './ReturnResponse/AlertDanger';


export default function PhonePayLogin() {
    const[successAlertMsg, setSuccessAlertMsg] = useState();
    const[warningAlertMsg, setWarningAlertMsg] = useState();
    const[dangerAlertMsg, setDangerAlertMsg] = useState();
    // function for load comonent from top
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    
    const navigate = useNavigate();
    const[mobile, setMobile] = useState([]);
    const[password, setPassword] = useState([]);

    ////////////////////////////////////////////////////////
    /////////  function & variable for login //////////
    ////////////////////////////////////////////////////////
    const getToken = () => {
        const tokenString = sessionStorage.getItem('token');
        const userToken = JSON.parse(tokenString);
        return userToken;
    }
    const getUser = () => {
        const userString = sessionStorage.getItem('user');
        const userDetail = JSON.parse(userString);
        return userDetail;
    }

    const [token, setToken] = useState(getToken());
    const [user, setUser] = useState(getUser());

    
    const saveToken = (user, token) => {
        sessionStorage.setItem('token', JSON.stringify(token));
        sessionStorage.setItem('user', JSON.stringify(user));
        setToken(token); setUser(user);
        navigate('/dashboard');
    }

    const submitLogin = () =>{
        Https.post('/new-login', {mobile:mobile, password:password}).then((response) =>{
            console.log(response.data);
            if(response.data.code==400)
            {
                setWarningAlertMsg(response.data.response.message);
                setTimeout(() =>{
                    setWarningAlertMsg('');
                },5000);
            }
            else if(response.data.code==200)
            {
                setWarningAlertMsg(response.data.response.message);
                setTimeout(() =>{
                    setWarningAlertMsg('');
                },5000);
                localStorage.setItem('user_info', JSON.stringify(response.data.response.data));
                // navigate('/home');
                // location.reload('/home');
                // window.location('/home');
                window.location.replace("/home");
            }
            else
            {
                navigate('/login');
            }
            
        })
    }
  return (
    <> 
    <div className="main-wrapper">
        <div className="page-content change-password-col register-col">
            <div className="list no-hairlines custom-form">
                <div className="back-btn"><a href="index.html" className="back link">
                        <img src="../../assets/img/left-arrow-big-black.svg" alt="" /></a>
                </div>
                <div className="register-icon">
                    <img src="assets/img/register-top-img.png" alt="" />
                </div>
                <div className="logo">
                    <a href="javascript:void(0)">
                        <img src="doccure_assets/img/logo.png" alt="doccure" />
                    </a>
                </div>
                <div className="register-inner-col">
                    <div className="top-title">
                        <div>
                            <h3>Login AceMedicare</h3>
                        </div>
                    </div>
                    <AlertSuccess alert={successAlertMsg} />
                    <AlertWarning alert={warningAlertMsg} />
                    <AlertDanger alert={dangerAlertMsg} />
                    <ul className="change-list">
                        <li className="item-content item-input">
                            <div className="item-col">
                                <div className="item-input-wrap">
                                    <input type="number" className='input_number allow_only_number' name="mobile" id="mobile" placeholder='Mobile Number' onChange={e=>setMobile(e.target.value)}/>
                                </div>
                            </div>
                        </li>
                        <li className="item-content item-input">
                            <div className="item-col">
                                <div className="item-input-wrap">
                                    <input type="password" name="password" id="password" onChange={e=>setPassword(e.target.value)} placeholder="Password"/>
                                    
                                </div>
                            </div>
                        </li>
                        <span className="login-back"><a href="/forget-password"><strong style={{float:"right"}}>Forgot Password ?</strong></a></span>
                        <li className="item-content item-input">
                            <div className="item-input-wrap submit-btn-col">
                                <button className="btn btn-submit" onClick={submitLogin}  type="submit">Login</button>
                            </div>
                        </li>
                    </ul>
                    <span className="login-back">Don’t have an account? <a href="/mobile-verification">Register</a></span>
                </div>
                <div className="register-inner-col">
                    <img src="doccure_assets/img/login-banner.png" alt="Ace Medicare" />
                </div>
            </div>
        </div>
    </div>





{/*
    <div className="main-wrapper">
        <div class="breadcrumb-bar">
            <div class="container-fluid">
               <div class="row align-items-center">
                  <div class="col-md-12 col-12">
                     <nav aria-label="breadcrumb" class="page-breadcrumb">
                        <ol class="breadcrumb">
                           <li class=""><Link to="/">Home || &nbsp; </Link></li>
                           <li class="breadcrumb-item active" aria-current="page"> Login</li>
                        </ol>
                     </nav>
                  </div>
               </div>
            </div>
         </div>
        <div class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-8 offset-md-2">
                        <div class="account-content">
                        <div class="row align-items-center justify-content-center">
                            <div class="col-md-7 col-lg-6 login-left">
                                <img src="doccure_assets/img/login-banner.png" class="img-fluid" alt="Doccure Login" />
                            </div>
                            <AlertSuccess alert={successAlertMsg} />
                            <AlertWarning alert={warningAlertMsg} />
                            <AlertDanger alert={dangerAlertMsg} />
                            <div class="col-md-12 col-lg-6 login-right" style={{backgroundColor:"#ffe9d2"}}>
                                <div class="login-header">
                                    <h3>Login</h3>
                                </div>
                                <form action="javascript:void(0)">
                                    <div class="form-group form-focus">
                                    <input type="text" name="mobile" id="mobile" onChange={e=>setMobile(e.target.value)} class="form-control floating" />
                                    <label class="focus-label" for="mobile">Mobile</label>
                                    </div>
                                    <div class="form-group form-focus">
                                    <input type="password" name="password" id="password" onChange={e=>setPassword(e.target.value)} class="form-control floating" />
                                    <label class="focus-label" for="password">Password</label>
                                    </div>
                                    <div class="text-end">
                                    <a class="forgot-link" href="/forget-password">Forgot Password ?</a>
                                    </div>
                                    <button class="btn btn-primary w-100 btn-lg login-btn" onClick={submitLogin}  type="submit">Login</button>
                                    <div class="login-or">
                                    
                                    </div>
                                    <div class="text-center dont-have">Don’t have an account? <a href="/mobile-verification">Register</a></div>
                                </form>
                            </div>
                        </div>
                        </div>
                        <div className="register-inner-col">
                            <img src="doccure_assets/img/login-banner.png" alt="Ace Medicare" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    */}
    </>
  )
}
