import React, { useState } from 'react'

import Footer from './includes/Footer'
import Header from './includes/Header'

function RedCliffeIframe() {

  var userDetails = JSON.parse(localStorage.getItem('user_info'));
  const [user_name, set_user_name] = useState(userDetails.user_info.name) 
  const [user_mobile, set_user_mobile] = useState(userDetails.user_info.mobile) 
  const ifram_url = "https://iframepartner.redcliffelabs.com/?pid=3p_kGukDEAbBmCFcU8Q36&fullname="+user_name+"&mobile="+user_mobile;
  return (
    <>
    {/*
      <iframe src="https://iframepartner.redcliffelabs.com/?pid=3p_3Vhqa2gkiHRbSCVG94" allow="camera;microphone" style={{"width":"100%","height": "440px"}}></iframe>
     */} 
      <iframe src={ifram_url} style={{"width":"100%","height": "800px"}}></iframe>
    
    </>
  )
}

export default RedCliffeIframe
