import React from 'react'
import { useState, useRef, useEffect, memo  } from 'react';
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import Https from '../../Https';
import AlertDanger from '../ReturnResponse/AlertDanger';
import AlertSuccess from '../ReturnResponse/AlertSuccess';
import AlertWarning from '../ReturnResponse/AlertWarning';

export default function SearchProductList() {
    const params = useParams();
    const slug = params.slug;
    const[successAlertMsg, setSuccessAlertMsg] = useState();
    const[warningAlertMsg, setWarningAlertMsg] = useState();
    const[dangerAlertMsg, setDangerAlertMsg] = useState();
    var userDetails = JSON.parse(localStorage.getItem('user_info'));

    
    const [searchResultStatus, setSearchResultStatus] = useState(false);
    const [medicines, setMedicines] = useState([]);
    const [inputMedicineName, setInputMedicineName] = useState();
    

    const liveSearchPackage = (event) => {
        const searchData = event.target.value;
        if(searchData==null || searchData==''|| searchData==undefined)
        {
            setSearchResultStatus(false);
        }
        else
        {
            setSearchResultStatus(true);
        }
        setInputMedicineName(searchData);
        Https.get('our-products/'+searchData).then(response=>{
            setMedicines(response.data.data);
        });
     }

    const [products, setProducts] = useState([]);
    const [product, setProduct] = useState([]);
    //////////  use effect for fetch package details 
    useEffect(() =>{ 
        fetchProductsWithProductDetails();  
    },[]); 
    /////////   call api for get product with product details
    const fetchProductsWithProductDetails = () =>{
        Https.get('/products-with-name/'+slug).then(response=>{
            console.log('product response');
            setProducts(response.data.products);
            setProduct(response.data.product.original.data);
        });  
    }
    
    const addToCart = (event) => {
        const product_id = event.target.getAttribute('data-product_id');
        const headers = { 'Authorization': 'Bearer '+userDetails.token };
        const data = {
            'product_id': product_id,
            'user_id':userDetails.user_info.id,
        };
        Https.post('/add-product-in-session-cart/',data).then(response=>{
            setSuccessAlertMsg(response.data.message);
            setTimeout(() =>{
                setSuccessAlertMsg('');
            },5000);
        }); 
         
    }

  return (
    <>
    <div class="page-content header-bg pt-0">
        <div class="chat-list-new">
            <div class="container">
                <div class="chat-title">
                    <div class="chat-list-title"><i><img src="../mobile_assets/img/chat.svg" alt="" /></i> Product List</div>
                    <div class="user">{products.length} Products</div>
                </div>

                <form class="searchbar">
                    <div class="search_chat has-search">
                        <span class="fas fa-search form-control-feedback"></span>
                        <input class="form-control chat_input" id="search-chat" value={inputMedicineName ? inputMedicineName : product.title} onChange={liveSearchPackage} required="required"
                        placeholder="Search for Medicine" />
                        {
                        searchResultStatus ?
                        <div className='dropdown' style={{position:"absolute","background":"#fff",padding: "5px 5px 5px 5px",width:"490px","zIndex":"1"}}>
                        {medicines.map((medicine, index)=>(
                            <div className="dropdown-row" style={{padding: "5px 5px 5px 5px"}}>
                                <a href={`/search-product-list/${medicine.slug}`} >{medicine.title}</a>
                                
                            </div>
                        ))}
                        </div>
                        :
                        null
                        }
                    </div>
                </form>
                <AlertSuccess alert={successAlertMsg} />
                <AlertWarning alert={warningAlertMsg} />
                <AlertDanger alert={dangerAlertMsg} />
                <div class="list chat-list">
                    <ul>
                    {products.map((products, index)=>(
                        <li>
                            <a href={`/products/product-detail/${products.slug}`} class="item-link item-content swipeout-content link">
                                <div class="item-avatar">
                                    <img src={`https://acemedicare-admin.hospicares.in/products/product/${products.main_image}`} class="rounded-circle" width="55" alt={products.title} />
                                </div>
                                <div class="item-col">
                                    <div class="item-title-row d-flex justify-content-between">
                                        <div class="item-title">{products.title} </div>
                                        <div class="item-right">
                                            <div class="pat-info-right">
                                                <div class="view-btn" style={{color:"white",fontSize:"0px",border:'none',padding:"0px"}}>
                                                    <a href="javascript:void(0)" data-product_id={products.id} onClick={addToCart} class="btn">Add</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="item-text"><i class="fas fa-rupee-sign"></i> {products.sale_price}.00<span class="smile"></span></div>
                                </div>
                            </a>
                        </li>
                    ))}
                    </ul>
                    <div class="fixed-chat-col">
                        <a href="#"><img src="assets/img/chat-icon.svg" alt="" /></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}
