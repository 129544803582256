import React from 'react'
import { useState, useEffect,event } from 'react';
import Https from '../Https';
import { Link, useParams, useLocation } from "react-router-dom";
import Header from './includes/Header';
import Footer from './includes/Footer';



export default function TreatmentPackageTwo(props) {

   const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);


   //////////  set variable in state for get package & package image path
   const [treatPackages, setTreatPackages] = useState([]);
   const [packageImgPath, setPackageImgPath] = useState([]);
   const [packageName, setPackageName] = useState();
   //////////  variable for package live search
   const liveSearchPackage = (event) => {
      const searchData = event.target.value;
      setPackageName(searchData);
      Https.get('all-active-packages/'+packageName).then(response=>{
         setTreatPackages(response.data.data);
      });
   }
 
   //////////  use effect for fetch package image path
   useEffect(()=>{
      fetchPackageImagePath();
   },[]);
   //////////  call api for get image path
   const fetchPackageImagePath = () =>{
      Https.get('/package-image-path').then(response=>{
         setPackageImgPath(response.data);    
      });     
   } 
   ////////// use effect for fetch active package list
   useEffect(()=>{
      fetchAllPackages();
   },[]);
   /////////   call api for get active package list
   const fetchAllPackages = () =>{
      Https.get('all-active-packages').then(response=>{
         setTreatPackages(response.data.data);
         console.log(setTreatPackages); 
      });
   }
   ////////////   use params for master package id
   const {masterPackageId} = useParams();
   ///////////  use effect for fetch package by master package id
   useEffect(()=>{
      fetchPacakgeByMasterPackage();
   },[]); 
   //////////  call api for get active pacakge by master package id  
   const fetchPacakgeByMasterPackage = () =>{
      Https.get('/get-package-by-master-package-id/'+masterPackageId).then(response=>{
         console.log(response.data);
      });
   }
   
  return (
    <>
    <div className="main-wrapper">
        <section class="latest-blog">
            <div class="container">
               <div class="section-header-three text-center">
                  <h2>Treatment Packages</h2>
               </div>
               <div class="banner-wrapper">
                  <form action="javascript:void(0)">
                     <div class="form-group">
                     
                        <input type="text" class="form-control"
                              placeholder="Search package By Name" value={packageName} onChange={liveSearchPackage} />
                     </div>
                     <br />
                  </form>
               </div>
               <div class="page-content pt-0">
                  <div class="patients-list">
                     <div class="container">
                     {treatPackages.map((treatPackages, index)=>(
                        <div class="patient-widget">
                              <div class="pat-info-left">
                                 <div class="patient-img" style={{flex:"0 0 100px"}}>
                                    <Link to={`/package-details/${treatPackages.slug}`}>
                                          <img src={packageImgPath+'/'+treatPackages.main_img} class="img-fluid"alt="User Image" style={{borderRadius:"0px",height:"100px"}} />
                                    </Link>
                                 </div>
                                 <div class="pat-info-cont">
                                    <h4 class="pat-name"><Link to={`/package-details/${treatPackages.slug}`}>{treatPackages.name.substring(0, 150)} </Link></h4>
                                    
                                    <div class="patient-details-col">
                                          <span style={{width:"256px !important;"}}>{treatPackages.short_description.substring(0, 100)}.....</span>
                                    </div>
                                    <div class="status-col">
                                          <div class="status-btn">
                                             <Link class="btn success" to={`/package-details/${treatPackages.slug}`}>View Details</Link>
                                          </div>
                                    </div>
                                 </div>
                              </div>
                        </div>
                     ))}
                     </div>
                  </div>
               </div>
            </div>
         </section>
        {/* <div class="content">
			<div class="container-fluid">
				<div class="row">
                    {treatPackages.map((treatPackages, index)=>(
					<div class="col-md-12 col-lg-6 col-xl-6">
						<div class="card">
							<div class="card-body">
								<div class="doctor-widget">
									<div class="doc-info-left">
										<div class="doctor-img1">
                                            <Link to={`/package-details/${treatPackages.slug}`}>
                                                <img class="" src={packageImgPath+'/'+treatPackages.main_img} alt={treatPackages.name} style={{height: "150px", width: "200px"}} />
                                            </Link> 
										</div>
										<div class="doc-info-cont">
											<h5 class="doc-name mb-2"><Link to={`/package-details/${treatPackages.slug}`}>{treatPackages.name} </Link></h5>
											<div class="">
												<div class="clini-infos pt-3">
													<p class="doc-location mb-2">{treatPackages.short_description.substring(0, 150)}</p>
                                                    <Link to={`/package-details/${treatPackages.slug}`} style={{color:"red",float:"right"}}><small><strong>Book Appointment</strong></small> </Link>
                                            	</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
                    ))}
				</div>
			</div>
		 </div>    */}
   </div>
    </>
  )
}
