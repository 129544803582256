import React from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { useState, useEffect} from 'react'
// import Https from '../Https';
import Https from '../../Https';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default function LoginModel() {
    // function for load comonent from top
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    
    const navigate = useNavigate();
    const[mobile, setMobile] = useState([]);
    const[password, setPassword] = useState([]);

    ////////////////////////////////////////////////////////
    /////////  function & variable for login //////////
    ////////////////////////////////////////////////////////
    const getToken = () => {
        const tokenString = sessionStorage.getItem('token');
        const userToken = JSON.parse(tokenString);
        return userToken;
    }
    const getUser = () => {
        const userString = sessionStorage.getItem('user');
        const userDetail = JSON.parse(userString);
        return userDetail;
    }

    const [token, setToken] = useState(getToken());
    const [user, setUser] = useState(getUser());

    
    const saveToken = (user, token) => {
        sessionStorage.setItem('token', JSON.stringify(token));
        sessionStorage.setItem('user', JSON.stringify(user));
        setToken(token); setUser(user);
        // navigate('/dashboard');
        alert('Login Successfully.')
        window.location.reload();
    }

    const submitLogin = () =>{
        Https.post('/login', {mobile:mobile, password:password}).then((response) =>{
            console.log(response.data);
            // saveToken(response.data.user,response.data.access_token)
            if(response.status==200)
            {
                // navigate('dashboard');
                saveToken(response.data.user,response.data.access_token)
            }
            else
            {
                alert('Invalid Username or Password');
            }
            
        })
    }
   
  return (
    <>
        <div class="modal fade custom-modal" id="login_model">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Patient Login</h5>
                        <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                    <div class="col-md-12 col-lg-12 login-right">
                            <div class="login-header">
                                <h3>Login</h3>
                            </div>
                            <form action="javascript:void(0)">
                                <div class="form-group form-focus">
                                <input type="text" name="mobile" id="mobile" onChange={e=>setMobile(e.target.value)} class="form-control allow_only_number" maxlength="10" />
                                <label class="focus-label" for="mobile">Mobile</label>
                                </div>
                                <div class="form-group form-focus">
                                <input type="password" name="password" id="password" onChange={e=>setPassword(e.target.value)} class="form-control floating" />
                                <label class="focus-label" for="password">Password</label>
                                </div>
                                <div class="text-end">
                                <Link class="forgot-link" to="/forget-password">Forgot Password ?</Link>
                                </div>
                                <button class="btn btn-primary w-100 btn-lg login-btn" onClick={submitLogin}  type="submit">Login</button>
                                <div class="login-or">
                                <span class="or-line"></span>
                                <span class="span-or">or</span>
                                </div>
                                <div class="row form-row social-login">
                                {/* <div class="col-6">
                                    <a href="#" class="btn btn-facebook w-100"><i class="fab fa-facebook-f me-1"></i> Login</a>
                                </div>
                                <div class="col-6">
                                    <a href="#" class="btn btn-google w-100"><i class="fab fa-google me-1"></i> Login</a>
                                </div> */}
                                </div>
                                <div class="text-center dont-have">Don’t have an account? <Link to="/register">Register</Link></div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
    )
}