import React, { useState, useEffect } from 'react'
import { Link, useNavigate,useLocation } from 'react-router-dom'
import Https from '../../Https';
import UserAuth from '../../UserAuth'
import AppointmentModel from '../modules/AppointmentModel';
import SearchModel from '../modules/SearchModel';
import Loader from '../modules/Loader';
// import { SiteLogo } from './public/doccure_assets/img/logo.png'
export default function Header() {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    const navigate = useNavigate();
    // const user = sessionStorage.getItem('user');
    // const userDetail = JSON.parse(user); 

    // const token = sessionStorage.getItem('token');
    // const navigate = useNavigate();
    // const logout = () => {
    //     if(token !== undefined)
    //     {

    //         sessionStorage.clear();
    //         navigate('/login');
    //     }
    // }
    // const userDetails = localStorage.getItem('user_info');
    // const jsonUserDetails = JSON.parse(userDetails);
    // const userToken = jsonUserDetails.token;

    // var userid = jsonUserDetails.user_info.id;
    // const userMobile = jsonUserDetails.user_info.mobile;
    //////////  function & variable for master packages
    const [poplourPackageMasters, setPoplourPackageMasters] = useState([]);

    useEffect(()=>{
        fetchPoplourpackageMasters();
    },[]);

    const fetchPoplourpackageMasters = () =>{
        Https.get('/package-master').then(response=>{
            setPoplourPackageMasters(response.data.data);  
        });  
    }
    // script for mobile menu toggle for company
    const [companyStatus, setCompanyStatus] = useState('none');
    const company_menu = (e) =>{
        
        if(companyStatus=='block')
        {
            setCompanyStatus("none");
        }
        if(companyStatus=='none')
        {
            setCompanyStatus("block");
        }        
        setOurServiceStatus("none");
        setHomeCareServiceStatus("none");
        setAmbulanceStatus("none");
    }
    // script for mobile menu toggle for our services
    const [ourServiceStatus, setOurServiceStatus] = useState('none');
    const our_service = (e) =>{
        
        if(ourServiceStatus=='block')
        {
            setOurServiceStatus("none");
        }
        if(ourServiceStatus=='none')
        {
            setOurServiceStatus("block");
        }        
        setCompanyStatus("none");
    }
    // script for mobile menu toggle for ambulance
    const [ambulanceStatus, setAmbulanceStatus] = useState('none');
    const ambulance = (e) =>{
        
        if(ambulanceStatus=='block')
        {
            setAmbulanceStatus("none");
        }
        if(ambulanceStatus=='none')
        {
            setAmbulanceStatus("block");
        }        
        setCompanyStatus("none");
        setHomeCareServiceStatus("none");
    }
    // script for mobile menu toggle for ambulance
    const [homeCareServiceStatus, setHomeCareServiceStatus] = useState('none');
    const homeCareService = (e) =>{
        
        if(homeCareServiceStatus=='block')
        {
            setHomeCareServiceStatus("none");
        }
        if(homeCareServiceStatus=='none')
        {
            setHomeCareServiceStatus("block");
        }        
        setCompanyStatus("none");
        setAmbulanceStatus("none");
    }
    









    const userDetails = localStorage.getItem('user_info');
    const jsonUserDetails = JSON.parse(userDetails);
    const userToken = jsonUserDetails.token;
  
    var userid = jsonUserDetails.user_info.id;
    const userMobile = jsonUserDetails.user_info.mobile;
    // const wallet_amount = jsonUserDetails.user_info.wallet_amount;
    const [wallet_amount, set_wallet_amount] = useState();
   
    useEffect(() => {
      checkUser();
    }, []);
    const checkUser = () => {
      Https.get('get-patient-detail-by-id/'+userid).then((response) => {
        console.log('patient details');
        console.log(response)
        set_wallet_amount(response.data.data.wallet_amount)
      });
    }


    const logOut = () => {
        localStorage.clear();
        // navigate('/login');
        window.location.replace("/login");
    }







  return (
    <>
        <div class="side-menu" id="sidebar-menu">
            <div class="close-btn"><span class="material-icons">close</span></div>
            <a class="sidebar-logout" href="login.html"><span><img src="assets/img/open-account-logout.svg"
                        alt="" /></span>Logout</a>
        </div>
        <div class="home">
            <div class="navbar two-action no-hairline fixed-top">
                <div class="navbar-inner d-flex align-items-center">
                    <div class="left" id="mobile_btn">
                        <a href="#" class="link icon-only">
                            <i class="custom-hamburger">
                                <span><b></b></span>
                            </i>
                        </a>
                    </div>
                    <div className="main-menu-wrapper">
                        <div className="menu-header" style={{"background": "rgb(30 92 146)"}}>
                            <a href="/home" className="menu-logo">
                                <img src={window.location.origin + '/doccure_assets/img/logo_second.png'} className="img-fluid" alt="Ace Medicare" style={{maxWidth: "155px"}} />
                            </a>
                            <a id="menu_close" className="menu-close">
                            <i className="fas fa-times"></i>
                            </a>
                        </div>
                        <ul className="main-nav">
                            <li className="has-menu">
                                <Link to="/home" style={{"color":"white"}}>Home</Link>
                            </li>
                            {/* <li className="has-submenu">
                                <a href="javascript:void(0)" onClick={company_menu} style={{"color":"white"}} >Our Company <i className="fas fa-chevron-left"></i></a>
                                <ul className="submenu" style={{display:`${companyStatus}`}}>
                                <li><Link to="/about-us">About Ace Medicare</Link></li>   
                                <li><Link to="/media-coverage">Media Coverage</Link></li>
                                <li><Link to="/how-we-work">How We Work</Link></li>
                                </ul>
                            </li> 
                            <li><a href="/visit-our/hospital">Hospital Surgery</a></li>
                            <li><a href="/visit-our/labDiagnosis">Lab Test</a></li>*/}
                            <li><a href="/dentistry">Dentistry</a></li>
                            <li><a href="/transition-care-center">Transition care center</a></li>
                            <li><a href="doctors-at-home">Doctors At Home</a></li>
                            <li><a href="nurse-at-home">Nurse At Home</a></li>
                            <li><a href="physiotherapist-at-home">Physiotherapist At Home</a></li>
                            <li><a href="icuSetup-at-home">ICU Setup At Home</a></li>
                            <li><a href="/air-ambulance">Air Ambulance</a></li>
                            <li><a href="/ambulance-with-icu">Ambulance with ICU Setup</a></li>
                            <li><a href="/ambulance-with-doctor">Ambulance with Doctor</a></li>
                            <li><a href="/ambulance-with-nurse">Ambulance with Nurse</a></li>
                            <li><a href="/ambulance-for-deadBody">Ambulance for Dead Body</a></li>
                            <li className="has-submenu">
                                <a href="/treatment-package" style={{"color":"white"}}>Treatment Packages 
                                </a> 
                                <ul className="submenu">
                                {poplourPackageMasters.map((poplourPackageMaster,index)=>{
                                    if(poplourPackageMaster.packages.length > 0)
                                    {
                                        return <li class="has-submenu">
                                                    <a href={"/group-treatment-package/"+poplourPackageMaster.slug}>{poplourPackageMaster.name} </a>
                                                    <ul class="submenu">
                                                        {poplourPackageMaster.packages.map((treatPackages, index)=>(
                                                        <li><a href={"/package-details/"+treatPackages.slug}>{treatPackages.name.substring(0, 150)} </a></li>
                                                        ))}
                                                    </ul>   
                                                </li>
                                    }
                                        return <li>
                                            <a href={"/group-treatment-package/"+poplourPackageMaster.slug}>{poplourPackageMaster.name}</a>
                                        </li>
                                    })}
                                </ul>
                            </li>
                            <li className="has-menu">
                                <a href="/international-patient" style={{"color":"white"}}>International Patient</a>
                            </li>
                            <li className="has-menu">
                                <a href="/contact-us" style={{"color":"white"}}>Contact Us</a>
                            </li>
                        </ul>         
                    </div>
                    <div class="sliding custom-title"><a href='/home'>Ace Medicare</a></div>
                    <div class="right d-flex">
                        <a href="javascript:void(0)" class="link icon-only" style={{margin: "13px 20px 0px 0px"}}>{wallet_amount}</a>
                        <a href="#" class="link icon-only">
                        <span class="material-icons">account_balance_wallet</span></a>
                        {/*<a href="/pharmacy/my-cart" class="link icon-only"><i class="material-icons">shopping_cart</i></a>*/}
                        <a href="#" data-bs-toggle="dropdown" aria-expanded="true" class="link"><i
                                class="material-icons">more_vert</i></a>
                        <div class="dropdown-menu dropdown-menu-end header_drop_icon">
                            <a href="/manage-profile" class="dropdown-item">My Profile</a>
                            <a href="/change-password" class="dropdown-item">Change Password</a>
                            <a href="/service/service-booking" class="dropdown-item">Make Payment</a>
                            <a href="/my-appointment" class="dropdown-item">My Appointments</a>
                            <a href="/my-transactions" class="dropdown-item">My Transactions</a>
                            <a href="/my-history" class="dropdown-item">My History</a>
                            <a href="javascript:void(0)" onClick={logOut} class="dropdown-item">Log Out</a>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
        <SearchModel />
        <AppointmentModel />
        
        {/* open fixed sidebar */}
        {/*<div id='sticky'>
            <a href="#getIntouch" style={{backgroundColor:"rgb(255, 211, 7)", borderColor:"rgb(255, 211, 7)", color:"rgb(0 0 0)"}} class="btn btn-rounded btn-outline-secondary" tabindex="0" data-bs-toggle="modal" data-bs-target="#appointment_model"> 
                <ul>
                    <li className='effect'>F</li>
                    <li className='effect'>R</li>
                    <li className='effect'>E</li>
                    <li className='effect'>E</li>
                    <li className='effect'>A</li>
                    <li className='effect'>P</li>
                    <li className='effect'>P</li>
                    <li className='effect'>O</li>
                    <li className='effect'>I</li>
                    <li className='effect'>N</li>
                    <li className='effect'>T</li>
                    <li className='effect'>M</li>
                    <li className='effect'>E</li>
                    <li className='effect'>N</li>
                    <li className='effect'>T</li>
                </ul>
            </a>
        </div>*/}
        {/* close fixed sidebar */}
    </>
  )
}
