import React from 'react'
import { useState, useEffect,useRef } from 'react';
import { Link,useNavigate,useLocation,useParams } from 'react-router-dom';
import Https from '../../Https';
import AlertSuccess from '../ReturnResponse/AlertSuccess';
import AlertWarning from '../ReturnResponse/AlertWarning';
import AlertDanger from '../ReturnResponse/AlertDanger';

function MyAppointments() {
    const { pathname } = useLocation();
    useEffect(() => {
	  window.scrollTo(0, 0);
	}, [pathname]);
    const {appt_no} = useParams();
    
    const navigate = useNavigate();
    const[successAlertMsg, setSuccessAlertMsg] = useState();
    const[warningAlertMsg, setWarningAlertMsg] = useState();
    const[dangerAlertMsg, setDangerAlertMsg] = useState();

    const userDetails = localStorage.getItem('user_info');
    const jsonUserDetails = JSON.parse(userDetails);

    const [appointments, setAppointments] = useState([]);
    ////////// use effect for fetch active department list
    useEffect(()=>{
        fetchAllAppointment();
    },[]);
    /////////   call api for get active department list                
    const fetchAllAppointment = () =>{
        Https.post('/get_appointments_by_user_id',{userId:jsonUserDetails.user_info.id}).then((response) =>{
            
            setAppointments(response.data);
        });  
    }    

    const canclled_appointment = (event) => {
        var appointment_id = event.target.getAttribute('data-appointment_id');
        Https.post('/cancelled_user_appointment',{userId:jsonUserDetails.user_info.id,'appointment_id':appointment_id}).then((response) =>{
            if(response.data.code==200)
            {
                setSuccessAlertMsg('Appointment cancelled Successfully.');
                setTimeout(() =>{
                    setWarningAlertMsg('');
                    window.location.reload();
                },5000);
                
            }
            else
            {
                setWarningAlertMsg('Appointment Not Cancelled Yet.');
                setTimeout(() =>{
                    setWarningAlertMsg('');
                    window.location.reload();
                },5000);
                
            }
            
            
        });  
    }

    
  return (
    <>
    <div className="main-wrapper">
        <div className="tab-pane" id="prescriptions">
            <div className="panel panel-default">
                <div id="collapseTwo" className="panel-collapse collapse">
                    <div className="panel-body">
                        <AlertSuccess alert={successAlertMsg} />
                        <AlertWarning alert={warningAlertMsg} />
                        <AlertDanger alert={dangerAlertMsg} />
                        <div className="patient-appointments-col">
                            {appointments.map((appointment, index)=>(
                            <div className="patient-widget">
                                <div className="patient-top-details">
                                    <div>
                                        <span className="invoice-id">Booking Date - {appointment.created_at}</span>
                                    </div>
                                    <div>
                                        <span className="date-col">{appointment.department_name}</span>
                                    </div>
                                </div>
                                <div className="invoice-widget">
                                    <div className="pat-info-left">
                                        <div className="patient-img">
                                            <a href="#">
                                                <img src={`https://acemedicare-admin.hospicares.in/adminpanel/departments/ent.png`}
                                                    className="img-fluid" alt="User Image" />
                                            </a>
                                        </div>
                                        <div className="pat-info-cont">
                                            <h4 className="pat-name"><a href="#">Department : {appointment.department_name}</a></h4>
                                            <div className="patient-details-col">
                                                <span className="">Appt No - {appointment.appointment_no}</span>
                                            </div>
                                            <div className="patient-details-col">
                                                <span className="">Appt Date, Time - {appointment.appointment_date} , {appointment.appointment_time}</span>
                                            </div>
                                            <div className="hour-col">
                                                <div>
                                                    <span className="hours"><i class="fas fa-rupee-sign"></i> {appointment.appt_fee}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="status-col">
                                    <div className="status-btn">
                                        {appointment.appointment_status=='active' ?
                                        <a href='javascript:void(0)' data-metting_url={appointment.zoom_join_url} className="btn success check_link" onClick="join_metting()">
                                            <i><img src="assets/img/icon-awesome-eye.svg" alt=""/></i>Call
                                        </a>
                                        :''
                                        }
                                        
                                        
                                    </div>
                                    <div className="status-btn">
                                        {appointment.cancelation_status=='active' ?
                                        <a href='javascript:void(0)' className="btn view-eye" onClick={canclled_appointment} data-appointment_id={appointment.id}>
                                            <i><img src="assets/img/icon-awesome-eye.svg" alt=""/></i>Cancel
                                        </a>
                                        :''
                                        }
                                    </div>
                                    <div className="status-btn">
                                        {appointment.booking_status=='3' && appointment.follow_up_doctor_prescription==null?
                                        <a href={`/appointment/followup/${appointment.appointment_no}`} className="btn view-eye" onClick={canclled_appointment} data-appointment_id={appointment.id}>
                                            <i><img src="assets/img/icon-awesome-eye.svg" alt=""/></i>Follow Up
                                        </a>
                                        :''
                                        }
                                    </div>
                                    <div className="status-btn" style={{float:"right"}}>
                                        <a href={`appointment-details/${appointment.appointment_no}`} className="btn view-eye"><i><img
                                        src="assets/img/icon-awesome-eye.svg"
                                        alt="" /></i>View </a>
                                    </div>
                                </div>
                            </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default MyAppointments