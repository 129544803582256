import React from "react";
import ServiceSideBar from "../modules/ServiceSideBar";

export default function NurseatHome(){
  return(
    <>
      <div class="content">
            <div class="container-fluid">
                <div class="row">
                  <div class="col-sm-9">
                    <div class="card">
                      <div class="card-body">
                        <h3>What Exactly Is Home Nursing Care? </h3><br></br>
                        <p className=""> Home Nursing Service includes a wide range of healthcare services that can be easily administered in the comfort of your own home. Nurses for home care are typically less expensive than hospitals and nursing homes, while providing medical care that is just as effective as that provided in a hospital or nursing home.</p>
                        <p className=""> An in-home nursing service provides personalised nursing care at home, similar to what is provided in a hospital, while being more compassionate to the patient, integrating into the patient's family, and developing an emotional bond with the patient and their family.</p>
                        <p className=""> Home care nursing services are provided by registered nurses, physiotherapists, and occupational therapists, among others. As a result, you do not need to be concerned about the quality of service provided.by the home health nurse </p><br></br>

                        <h4> NURSING CARE FOR THE SHORT TERM</h4><br></br>
                        <p className="">Short-term nurses provide nursing care for 1-4 hours or for a set period of time. Short-term nursing care is medical aftercare that is provided temporarily following surgery, injury, or another medical condition that is expected to improve.</p>
                        <p className=""><strong>Specialties:</strong> Vaccination at home, injection at home, ECG at home, dressing or wound care at home are all specialties.</p><br></br>

                        <h4> NURSING CARE FOR THE LONG TERM </h4><br></br>
                        <p className="">Long-term nurses provide nursing care for 12 to 24 hours per day. Long-term nursing care is a comprehensive range of medical, physical, and surgical care provided to people who are chronically ill or disabled in order to meet their physical, social, and emotional needs.</p>
                        <p className=""><strong>Specialties:</strong> Home Ventilator Care, Home Tracheostomy Care, Bedsore Care, Catheter Care, Infusion Care, Ryle's Tube Care, IV Line Care.</p><br></br>

                        <h4> NURSING ATTENDANTS AT HOME </h4><br></br>
                        <p className=""> Nursing attendants care for patients at home.taking care of patients' daily activities They offer emotional support. They are not, however, permitted to perform any medical procedures.</p>
                        <p className=""><strong> Specialties: </strong>  include caregivers, support care, elderly patient care, caretaker, and assistance with daily activities.</p><br></br>
                         <h3> How Can We Assist?</h3><br></br>
                         <p className=""> Our in-home nurses excel at services such as: </p>
                        <ul>
                          <li>Post-operative Care</li>
                            <li>Senior care</li>
                            <li>Long-term care</li>
                            <li>Care for Tracheotomy Urinary Catheterization</li>
                            <li>Wound treatment</li>
                            <li>IV infusions and injections</li>
                            <li>Diabetes treatment</li>
                        </ul>
                        <p className="">Furthermore, they are always overseen by a senior doctor. Get better faster by hiring a Portea in-home nurse for 12/24 hours.</p><br></br>

                        <h3>What Are the Advantages of In-Home Nursing Care?</h3><br></br>
                        <p className=""> There are numerous advantages to receiving home health care. Apart from being more convenient than being admitted to a hospital or nursing home, nursing care at home also helps a patient recover faster, as it has been discovered that people recover faster from illnesses or ailments when they are surrounded by their loved ones.</p>
                        <ul>
                          <li>It also helps with diet and nutrition. Nurses who provide home health care can assist the patient while you are away.</li>
                          <li>It provides skilled nursing care in the privacy of your own home.</li>
                          <li>Nursing care at home also aids in the management of chronic health conditions, reducing the need for unnecessary hospitalisation.</li>
                          <li>Home care nursing services can also help with recovery care after a hospital stay for illness or injury.</li>
                          <li>It provides one-on-one attention and support.</li>
                          <li>Clients fare better in terms of health.</li>
                          <li>It provides medication administration.</li>
                        </ul><br></br>

                        <h3>Getting Ready for Home Nursing Service</h3><br></br>
                        <p className=""> To make the most of the at-home nursing service, proper prior preparation is essential. Before you can use nursing services, you must first complete the following tasks:</p>
                        <ul>
                          <li>Make a list of personal emergency contacts.</li>
                          <li>Have your prescription and other reports ready, as well as your doctor's contact information.</li>
                          <li>Make a list of the tasks that the online nurse service is expected to perform.</li>
                          <li>Explain to the nurse any specific instructions you want her to know ur.</li>
                        </ul><br></br>

                        <h3>Nearby Home Nursing Care</h3><br></br>
                        <p className="">You no longer need to worry about attending a clinic or hospital and aggravating the injury now that nursing services are available at home. Simply Google nursing services near me to schedule an appointment with one of Portea's skilled and experienced in-home nurses. You will be assisted by the best-trained nurses in resuming your usual life as soon as possible.</p><br></br>

                        <h3>When are you going to need us?</h3><br></br>
                        <p className="">The primary purpose of Home Nursing is to treat a sickness or accident at home. Wound care for pressure sores or surgical wounds, patient and caregiver education, intravenous or nutrition therapy, injections, rehabilitation therapies, and monitoring serious sickness and unstable health status are typical home nursing services.</p>
                      </div>
                      <div class="container-fluid">
                        <div class="row">
                            <div class="col-sm-6">
                              <h4>Vaccination:</h4><br></br>
                              <p class="text-justify">Vaccinate yourself at home to protect yourself from infectious illnesses. We provide home care immunisation for H1N1, Typhoid, Pneumonia, Hepatitis, and other diseases by certified nurses.</p><br></br>
                              <h4>Dressing the Wound:</h4><br></br>
                              <p class="">Our nurses are trained to handle a variety of wounds, including post-operative surgical wounds, infected wounds, and pressure sores, and will give proper wound care for a faster recovery. </p><br></br>
                              <h4> Post-Operative Care:</h4><br></br>
                              <p class="">Post-operative care is essential and covers everything from pain management and eating to respiratory and hydration control. Get well faster with the support of our nurses, who will assist you with all of this in the comfort of your own home.</p><br></br>
                            </div>
                            <div class="col-sm-6">
                              <h4>Administration of Oxygen:</h4><br></br>
                              <p className=""> Acute and chronic illnesses such as trauma, haemorrhage, shock, dyspnea, pulmonary disease, and others necessitate the delivery of oxygen. Don't be alarmed if you need one.</p><br></br>
                              <h4> Urinary Catheterization:</h4><br></br>
                              <p className=""> Our nurses are well-trained and can assist you with Urine catheterization care at home, whether it be catheter placement, catheter removal, or bladder cleansing of Urinary Catheterization.</p><br></br>
                              <h4> Injection:</h4><br></br>
                              <p className=""> Save yourself the inconvenience of travelling and staying in the hospital for a little procedure such as injectable administration or IV infusion. Simply schedule a home nurse with us.</p><br></br>
                            </div>
                        </div>
                      </div>
                      <div class="card-body">
                            <h3>Frequently Asked Questions</h3><br></br>
                            <h4>Ques:  What would a Portea nurse do?</h4><br></br>
                            <p className="">Ans: A skilled nurse at Ace Medicare can help you with post-surgical care, Tracheostomy, Urine catheterization, Suture removal, Wound treatment, Injections, Oxygen delivery, IV infusion, and other services.</p><br></br>
                            <h4>Ques: How frequently will a nurse visit me?</h4><br></br>
                            <p className="">Ans: Nursing visits vary in frequency based on the patient's requirements and treatment plan.</p><br></br>
                            <h4>Ques: Can I get nursing care for 12 or 24 hours a day?</h4><br></br>
                            <p className="">Ans: Yes, for patients in need, we provide 12-hour and 24-hour nursing services at home.</p><br></br>
                            <h4>Ques: How qualified are Portea nurses?</h4><br></br>
                            <p className="">Ans: All Portea nurses have received professional training and certification. In addition, the majority of our nurses have ICU expertise.</p><br></br>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
      </div>
    </>
  );
}