import logo from './logo.svg';
import './App.css';


import ScrollToTop from './components/ScrollToTop';
import Header from './components/includes/Header';
import Home from './components/Home';
import SearchPackageResult from './components/SearchPackageResult';
import HospitalPackageDetail from './components/HospitalPackageDetail';
import BookPackage from './components/BookPackage';
import VisitDoctor from './components/VisitDoctor';
import OurVendors from './components/OurVendors';
import Dentistry from './components/Dentistry';
import HomeIcuSetup from './components/HomeIcuSetup';
import PhysiotherapistInHome from './components/PhysiotherapistInHome';
import About from './components/About';
import Contact from './components/Contact';
// import Departments from './components/Departments';
import Services from './components/Services';
import Login from './components/Login';
import Register from './components/Register';
import Dashboard from './components/user/Dashboard';
import ProfileSetting from './components/user/ProfileSetting';
import Booking from './components/user/Booking';
import OurTeam from './components/OurTeam';
import MediaCoverage from './components/MediaCoverage';
import HowWeWork from './components/HowWeWork';
import TreatmentPackage from './components/TreatmentPackage';
import TreatmentPackageOne from './components/TreatmentPackageOne';
import TreatmentPackageTwo from './components/TreatmentPackageTwo';
import InternationalPatient from './components/InternationalPatient';
import PackageMasterPackage from './components/PackageMasterPackage';
import PackageDetails from './components/PackageDetails';
import Footer from './components/includes/Footer';
import TermCondition from './components/TermCondition';
import PrivacyPolicy from './components/PrivacyPolicy';
import RefundPolicy from './components/RefundPolicy';
import FullBodyCheckup from './components/FullBodyCheckup';
import HomeCareService from './components/HomeCareService';
import Ambulance from './components/Ambulance';
import Hospital from './components/Hospital';
import HospitalDetail from './components/HospitalDetail';
import VendorDetail from './components/VendorDetail';
import Pharmacy from './components/Pharmacy';
import LabTest from './components/LabTest';
// import ForgetPassword from './components/ForgetPassword';
import ForgetPassword from './components/auth/ForgetPassword';
import AmbulanceEnquiry from './components/AmbulanceEnquiry';
import HomeCareEnquiry from './components/HomeCareEnquiry';
import { BrowserRouter as Router, Routes, Route, useNavigate, useLocation}
    from 'react-router-dom';

import Successfully from './components/Successfully';
import PackageRequestDetail from './components/user/PackageRequestDetail';
import OurPartners from './components/modules/OurPartners';
import TransitionCareCenter from './components/TransitionCareCenter';
import MobileVarification from './components/auth/MobileVarification';
import OtpVarification from './components/auth/OtpVarification';
import UserRegistration from './components/auth/UserRegistration';
import { useState, useEffect } from 'react';
import Https from './Https';
import ChangePassword from './components/user/ChangePassword';
import RedCliffeIframe from './components/RedCliffeIframe';
import StepFirst from './components/appointment/StepFirst';
import StepSecond from './components/appointment/StepSecond';
import StepThird from './components/appointment/StepThird';
import SuccessAppt from './components/appointment/SuccessAppt';
import MyAppointments from './components/appointment/MyAppointments';
import VideoMeeting from './components/appointment/VideoMeeting';
import DoctorOnCall from './components/appointment/DoctorOnCall';
import PharmacyIndex from './components/pharmacy/Index';
import SearchProductList from './components/pharmacy/SearchProductList';
import MyCart from './components/pharmacy/MyCart';
import ProductDetail from './components/pharmacy/ProductDetail';
import PlaceOrder from './components/pharmacy/PlaceOrder';
import OrderSummary from './components/pharmacy/OrderSummary';
import MyHistory from './components/user/MyHistory';
import PhonePayLogin from './components/PhonePayLogin';
import AppointmentDetails from './components/appointment/AppointmentDetails';
import AppointmentFollowUp from './components/appointment/AppointmentFollowUp';
import ServiceBooking from './components/Services/ServiceBooking';
import MyTransactions from './components/appointment/MyTransactions';

function App() {
  
  // const { pathname } = useLocation();
  //   useEffect(() => {
	//   window.scrollTo(0, 0);
	// }, [pathname]);

  var payload = {
    authEndpoint : 'http://localhost:4000',
    meetingNumber:83957417429,
    role:0,
    sdkKey:"sTprlEy9SteyTKkn6QAx7A",
    sdkSecret:"8pKErIh9l8UahqoY81E2FMKcyNVX5Nwn",
    passWord:"X3sqV8",
    // password:'123456',
    userName:"testing",
    userEmail:"testing@gmail.com",
    leaveUrl:"http://localhost:3000/",
  }

  const userDetails = localStorage.getItem('user_info');
  console.log('this is user details in app');
  console.log(userDetails);
  
  var loginStatus = "invalid";

  useEffect(() => {
      checkUser();
      }, []);

  if(userDetails===null){
      var checkUser = () => {
        loginStatus = "invalid";
       };
  }
  else
  {
      const jsonUserDetails = JSON.parse(userDetails);
      const userToken = jsonUserDetails.token;
  
      var userid = jsonUserDetails.user_info.id;
      const userMobile = jsonUserDetails.user_info.mobile;
  
      var checkUser = () => {
      Https.get('user-data-with-token',{ headers: {"Authorization" : `Bearer ${userToken}`} }).then((response) => {
          if(userid==response.data.id)
          {
            console.log('this is user token details in app');
            console.log(response);
              // navigate('/home');
              loginStatus = "valid";
          }
          
          else
          {
              // navigate('/login');
              // loginStatus = "invalid";
          }
          
      });
      }
      console.log('this is login status')
      console.log(userDetails);
  }
  const zoom_status = 0;


  if(userDetails==null || userDetails=='' || userDetails==undefined)
  {
    return (
      <div>
        <Router>
          <Routes>
            <Route path='/' element={<Login />} />
            <Route exact path='/mobile-verification' element={<MobileVarification />} />
            <Route exact path='/otp-varification' element={<OtpVarification />} />
            <Route exact path='/user-registration' element={<UserRegistration />} />
            <Route path='/login' element={<Login />} />
            <Route path='/PhonePayLogin' element={<PhonePayLogin />} />
            <Route path='/forget-password' element={<ForgetPassword />} />
          </Routes>
        </Router>
      </div>
    );
  }
  else
  {
    return (
      <div>
        <Router> 
          <Header />
            <Routes>
              <Route exact path='/' element={<Home />} />
              <Route exact path='/home' element={<Home />} />
              <Route exact path='/lab-tests' element={<RedCliffeIframe />} />
              {/*    package route goes here  */}
              <Route path='/treatment-package' element={<TreatmentPackage />} />
              <Route exact path='/search-package-results/:citys_id/:package_id' element={<SearchPackageResult />} />
              <Route exact path='/:role_name-package-detail/:vendorSlug/:packageSlug' element={<HospitalPackageDetail />} />
              {/*    our services route goes here  */}
              <Route path='/dentistry' element={<Dentistry/>} />
              <Route path='/transition-care-center' element={<TransitionCareCenter/>} />
              <Route path=':type-at-home' element={<HomeCareEnquiry />} />
              <Route path=':type-ambulance' element={<AmbulanceEnquiry />} />
              <Route path='ambulance-with-:type' element={<AmbulanceEnquiry />} />
              <Route path='ambulance-for-:type' element={<AmbulanceEnquiry />} />
              <Route path='/international-patient' element={<InternationalPatient />} />
              {/*   user transaction route goes here  */}
              <Route path='/manage-profile' element={<ProfileSetting />} />
              <Route path='/change-password' element={<ChangePassword />} />
              {/*   user appointment route goes here  */}
              <Route path='/appointment/step-first' element={<StepFirst />} />
              <Route path='/appointment/step-second' element={<StepSecond />} />
              <Route path='/appointment/step-third' element={<StepThird />} />
              <Route path='/appointment/success-response/:appt_no' element={<SuccessAppt />} />
              <Route path='/appointment/doctor-on-call/:appt_no' element={<DoctorOnCall />} />
              
              <Route path='/my-appointment' element={<MyAppointments />} />
              <Route path='/my-transactions' element={<MyTransactions />} />
              <Route path='/service/service-booking' element={<ServiceBooking />} />
              <Route path='/appointment-details/:appt_no' element={<AppointmentDetails />} />
              <Route path='/appointment/followup/:appt_no' element={<AppointmentFollowUp />} />
              <Route path='/my-history' element={<MyHistory />} />
              <Route path='/appointment/video-meeting' element={<VideoMeeting payload={payload} />} />
              {/*
              <Route path='/appointment/old-video-meeting' element={<Old_VideoMeeting payload={payload} />} />
              */}
              <Route path='/pharmacy' element={<PharmacyIndex />} />
              <Route path='/search-product-list/:slug' element={<SearchProductList />} />
              <Route path='/products/product-detail/:slug' element={<ProductDetail />} />
              <Route path='/pharmacy/my-cart' element={<MyCart />} />
              <Route path='/pharmacy/place-order' element={<PlaceOrder />} />
              <Route path='/pharmacy/order-summary' element={<OrderSummary />} />
            
            </Routes>
          <Footer />
          {/*
          {(userAuthentication=='ValidUser' ?

          :
        
          )}
          */}
      
        </Router>
      </div>
    );
  }
}

export default App;
