import { useState, useEffect } from "react";
import Https from "../../Https";
import { useParams } from "react-router-dom";

export default function ProductDetail() {
    
    const params = useParams();
    const slug = params.slug;
    //////////  use effect for fetch package details 
    const [product, setProduct] = useState([]);
    useEffect(() =>{ 
        fetchProductsWithProductDetails();  
    },[]); 
    /////////   call api for get product with product details
    const fetchProductsWithProductDetails = () =>{
        Https.get('/products-with-name/'+slug).then(response=>{
            setProduct(response.data.product.original.data);
        });  
    }
    
  return (
    <>
    <div class="page-content header-bg pt-0">
        <div class="chat-list-new">
            <div class="container">
                <div class="tab-content">
                    <div class="tab-pane active" id="appoinments">
                        <div class="panel panel-default">
                            <div id="collapseOne" class="panel-collapse collapse in">
                                <div class="panel-body">
                                    <div class="patient-appointments-col">
                                        <div class="patient-widget">
                                            <div class="patient-top-details">
                                                <div>
                                                    <span class="invoice-id">Product Details</span>
                                                </div>
                                                <div>
                                                    <span class="date-col">
                                                    <strong></strong>
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="invoice-widget">
                                                <div class="pat-info-left">
                                                    <div class="patient-img">
                                                        <a href="#">
                                                            <img src={`https://acemedicare-admin.hospicares.in/products/product/${product.main_image}`} class="rounded-circle" width="55" alt={product.title} />
                                                        </a>
                                                    </div>
                                                    <div class="pat-info-cont">
                                                        <h4 class="pat-name"><a href="#">{product.title}</a></h4>
                                                        <div class="hour-col">
                                                            <div class="item-text"><i class="fas fa-rupee-sign"></i> {product.sale_price}.00<span class="smile"></span></div>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                            {/*
                                            <div class="status-col">
                                                <div class="status-btn">
                                                    <a href="#" class="btn success"><i><img
                                                                src="assets/img/icon-checkmark.svg"
                                                                alt="" /></i>Confirm</a>
                                                </div>
                                                <div class="status-btn">
                                                    <a href="#" class="btn view-eye"><i><img
                                                                src="assets/img/icon-awesome-eye.svg"
                                                                alt="" /></i>View</a>
                                                </div>
                                                <div class="status-btn">
                                                    <a href="#" class="btn print"><i><img
                                                                src="assets/img/icon-metro-printer.svg"
                                                                alt="" /></i>Print</a>
                                                </div>
                                            </div>
                                            */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br>
            </div>
        </div>
    </div>
    </>
  )
}
