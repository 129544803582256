import React from 'react'

export default function OurServices(props) {
    var backgroundColor = props.data;
  return (
    <>
        <section class="section section-features" style={{"backgroundColor":backgroundColor}}>
            <div class="container-fluid">
                <div class="inlinelibox">
                <h5>Our Services</h5>
                    <div class="inlineli-6-3col">
                        <ul>
                            <li>
                                <a href="#getAnAmbulance" tabindex="0" data-bs-toggle="modal" data-bs-target="#getAnAmbulance_model">
                                    <div class="feature-item text-center">
                                        <img src="doccure_assets/img/ambulance.jpg" class="img-fluid" alt="Get an Ambulance" />
                                        <p>Ambulance</p>
                                    </div>
                                </a>
                            </li>
                            <li>
                                {/*<a href="#doctorOnCall" onClick={window['call_it']}>*/}
                                <a href="/appointment/step-first">
                                    <div class="feature-item text-center">
                                        <img src="doccure_assets/img/doctor_on_call.png" class="img-fluid" alt="Doctor on Call" />
                                        <p>Doc On Call</p>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="/hospitalization" tabindex="0" data-bs-toggle="modal" data-bs-target="#appointment_model">
                                    <div class="feature-item text-center">
                                        <img src="doccure_assets/img/hospitalization.png" class="img-fluid" alt="Hospitalization" />
                                        <p>Hospitalization</p>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="/lab-tests">
                                    <div class="feature-item text-center">
                                        <img src="doccure_assets/img/lab_test.png" class="img-fluid" alt="Lab Test" />
                                        <p> Lab Tests</p>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0)" tabindex="0" data-bs-toggle="modal" data-bs-target="#pharmacy_model">
                                    <div class="feature-item text-center">
                                        <img src="doccure_assets/img/pharmacy.png" class="img-fluid" alt="Pharmacy" />
                                        <p>Pharmacy</p>
                                    </div>
                                </a>
                            </li>
                            
                            <li>
                                <a href="#homeCare" data-bs-toggle="modal" data-bs-target="#homeCare_model">
                                    <div class="feature-item text-center">
                                        <img src="doccure_assets/img/homeCare.png" class="img-fluid" alt="Home Care Services" />
                                        <p>Home Care</p>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}
