import React, { useState, useEffect } from 'react';
import { Link,useNavigate } from 'react-router-dom';
import Https from '../../Https';
import AlertSuccess from '../ReturnResponse/AlertSuccess';
import AlertWarning from '../ReturnResponse/AlertWarning';
import AlertDanger from '../ReturnResponse/AlertDanger';

function OtpVarification() {

    const navigate = useNavigate();

    const[successAlertMsg, setSuccessAlertMsg] = useState();
    const[warningAlertMsg, setWarningAlertMsg] = useState();
    const[dangerAlertMsg, setDangerAlertMsg] = useState();
    const otp_uid = localStorage.getItem('otp_uid');  
    
    const [otpno, setOtpno] = useState();

    const handleChange = (e) => {
        const otp_value = e.target.value.replace(/\D/g, "");
        console.log(otp_value.length);
        if(otp_value.length>8)
        {
            return false;
        }
        else
        {
            setOtpno(otp_value);
        } 
    }

    const checkOtp = () => {
        Https.post('verify-user-mobile-otp',{otpno:otpno,otp_uid:otp_uid}).then((response) => {
            
            if(response.data.response.status===200)
            {
                const varified_mobile = response.data.response.data.mobile;
                localStorage.setItem('varified_mobile', varified_mobile);
                navigate('/user-registration');
            }
            else
            {
                setWarningAlertMsg(response.data.response.message);
                setTimeout(() =>{
                    setWarningAlertMsg('');
                },5000);
            }
        })
    }


    const resendOTP = () => {
        Https.post('resend-otp',{otp_uid:otp_uid}).then((response) => {
            console.log(response);
            if(response.data.response.status===200)
            {
                document.getElementById('resend_otp').style.display = 'none';
                setSuccessAlertMsg(response.data.response.message);
                setTimeout(() =>{
                    setSuccessAlertMsg('');
                },5000);
            }
            else
            {
                setWarningAlertMsg(response.data.response.message);
                setTimeout(() =>{
                    setWarningAlertMsg('');
                },5000);
            }
        })
    }

  return (
    <>
    <div className="main-wrapper">
        <div className="page-content change-password-col register-col">
            <div className="list no-hairlines custom-form">
                <div className="back-btn"><a href="index.html" className="back link">
                        <img src="../../assets/img/left-arrow-big-black.svg" alt="" /></a>
                </div>
                <div className="register-icon">
                    <img src="assets/img/register-top-img.png" alt="" />
                </div>
                <div className="logo">
                    <a href="javascript:void(0)">
                        <img src="doccure_assets/img/logo.png" alt="doccure" />
                    </a>
                </div>
                <div className="register-inner-col">
                    <div className="top-title">
                        <div>
                            <h3>Enter Your OTP</h3>
                        </div>
                    </div>
                    <AlertSuccess alert={successAlertMsg} />
                    <AlertWarning alert={warningAlertMsg} />
                    <AlertDanger alert={dangerAlertMsg} />
                    <ul className="change-list">
                        <li className="item-content item-input">
                            <div className="item-col">
                                <div className="item-input-wrap">
                                    <input type="number" className='input_number allow_only_number' value={otpno} placeholder="Enter OTP" onChange={handleChange}  />
                                </div>
                            </div>
                        </li>
                        <li className="item-content item-input">
                            <div className="item-input-wrap submit-btn-col">
                                <button type="button" onClick={checkOtp} className="btn btn-submit">Verify OTP</button>
                            </div>
                        </li>
                    </ul>
                    <span className="login-back"><a href="javascript:void(0)" id="resend_otp" onClick={resendOTP}><strong style={{float:"right"}}>Resend OTP</strong></a></span>
                    <span className="login-back">Don't have an login ? <a href="/login">Login Now!</a></span>
                </div>
                <div className="register-inner-col">
                    <img src="doccure_assets/img/login-banner.png" alt="Ace Medicare" />
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default OtpVarification