import React from 'react'
import { useState, useEffect } from 'react';
import { Link,useNavigate,useLocation } from 'react-router-dom';
import Https from '../../Https';
import AlertSuccess from '../ReturnResponse/AlertSuccess';
import AlertWarning from '../ReturnResponse/AlertWarning';
import AlertDanger from '../ReturnResponse/AlertDanger';

function StepFirst() {
    const { pathname } = useLocation();
    useEffect(() => {
	  window.scrollTo(0, 0);
	}, [pathname]);

    const navigate = useNavigate();
    const[successAlertMsg, setSuccessAlertMsg] = useState();
    const[warningAlertMsg, setWarningAlertMsg] = useState();
    const[dangerAlertMsg, setDangerAlertMsg] = useState();

    
    const [setpFirststatus, setStepFirststatus] = useState(true);
    const [setpSecondstatus, setSetpSecondstatus] = useState(false);
    // user local storage data
    const userDetails = localStorage.getItem('user_info');
    const jsonUserDetails = JSON.parse(userDetails);
    // user local store step first data
    // const appointment_step_first = localStorage.getItem('appointment_step_first');
    
    const appointment_step_first_decode = JSON.parse(localStorage.getItem('appointment_step_first'));
    console.log('this is first appt');
    console.log(appointment_step_first_decode);
    if(appointment_step_first_decode===undefined || appointment_step_first_decode===null ||appointment_step_first_decode==='')
    {
        var local_gender = 'Male'; var local_age = '';  var local_height = '4ft 7in';  var local_weight = '';  var local_dibetic = 'No';  var local_bloodPressure = 'No';  var local_smoke = 'No';  var local_alcohol = 'No';
    }
    else
    {
        var local_gender = appointment_step_first_decode.gender;
        var local_age = appointment_step_first_decode.age;
        var local_height = appointment_step_first_decode.height;
        var local_weight = appointment_step_first_decode.weight;
        var local_dibetic = appointment_step_first_decode.dibetic;
        var local_bloodPressure = appointment_step_first_decode.bloodPressure;
        var local_smoke = appointment_step_first_decode.smoke;
        var local_alcohol = appointment_step_first_decode.alcohol;
    }
    const [firstName, setFirstName] = useState(jsonUserDetails.user_info.name);
    const [last_name, setLastName] = useState(jsonUserDetails.user_info.last_name);
    const [mobile, setMobile] = useState(jsonUserDetails.user_info.mobile);
    const [email, setEmail] = useState(jsonUserDetails.user_info.email);
    const [gender, setGender] = useState(local_gender);
    const [age, setAge] = useState(local_age);
    const [height, setHeight] = useState(local_height);
    const [weight, setWeight] = useState(local_weight);
    const [heightWeight, setHeightWeight] = useState("");
    const [dibetic, setDibetic] = useState(local_dibetic);
    const [bloodPressure, setBloodPressure] = useState(local_bloodPressure);
    const [bloodSugure, setBloodSugure] = useState("No");
    const [smoke, setSmoke] = useState(local_smoke);
    const [alcohol, setAlcohol] = useState(local_alcohol);
    

   const submitFirstStep = () => {
        const appointment_step_first = {
            firstName: firstName,
            last_name: last_name,
            mobile: mobile,
            email: email,
            gender: gender,
            age: age,
            height:height,
            weight:weight,
            heightWeight: heightWeight,
            dibetic: dibetic,
            bloodPressure: bloodPressure,
            bloodSugure: bloodSugure,
            smoke: smoke,
            alcohol:alcohol,
        };
        localStorage.setItem('appointment_step_first', JSON.stringify(appointment_step_first));
        navigate('/appointment/step-second');
        console.log(appointment_step_first);
   }


    const [user_ages, set_user_ages] = useState([]);
    ////////// use effect for fetch active department list
    useEffect(() => {
        fetch_user_age();
    }, []);
    /////////   call api for get active department list
    const fetch_user_age = () => {
        Https.get("user_age").then((response) => {
            set_user_ages(response.data);
        });
    };


    const [user_weight, set_user_weight] = useState([]);
    ////////// use effect for fetch active department list
    useEffect(() => {
        fetch_user_weight();
    }, []);
    /////////   call api for get active department list
    const fetch_user_weight = () => {
        Https.get("user_weight").then((response) => {
            set_user_weight(response.data);
        });
    };
    



  return (
    <>
    <div className="main-wrapper">
        <div className="page-content change-password-col register-col">
            <div className="list no-hairlines custom-form">
                <div className="back-btn"><a href="#" className="back link">
                        <img src="../../assets/img/left-arrow-big-black.svg" alt="" /></a>
                </div>
                
                <AlertSuccess alert={successAlertMsg} />
                <AlertWarning alert={warningAlertMsg} />
                <AlertDanger alert={dangerAlertMsg} />

                
                <div className="page-content profile-settings">

                    <div className="container">
                        <div className="tab-content">
                            <div className="tab-pane active" id="payment">
                                <div className="panel panel-default">
                                    <div id="collapseThree" className="panel-collapse collapse">
                                        <div className="panel-body">
                                            <div className="widget-title">
                                                <h5>Make Appointment</h5>
                                            </div>
                                            <form action="javascript:void(0)" onSubmit={submitFirstStep}>
                                            <div className="setting-widget">
                                                <div className="list no-hairlines-md pricing-col no-border">                                                
                                                        <ul>
                                                            
                                                            <li className="item-content item-input">
                                                                <div className="item-col">
                                                                    <div className="item-title item-label">Name
                                                                        <span>*</span></div>
                                                                    <div className="item-input-wrap">
                                                                        <input type="text" className="form-control" placeholder="Patient Name" required name="firstName" onChange={e => setFirstName(e.target.value)} value={firstName} />
                                                                        <span className="input-clear-button"></span>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li className="item-content item-input">
                                                                <div className="item-col">
                                                                    <div className="item-title item-label">Mobile
                                                                        <span>*</span></div>
                                                                    <div className="item-input-wrap">
                                                                        <input type="text" required placeholder="Enter 10 Digit Mobile Number" className="form-control allow_only_number" maxlength="10" name="mobile" onChange={e => setMobile(e.target.value)} value={mobile}/>
                                                                        <span className="input-clear-button"></span>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li className="item-content item-input col-50 gender">
                                                                <div className="item-col">
                                                                    <div className="item-title item-label">Age
                                                                        <span>*</span></div>
                                                                    <div className="item-input-wrap">
                                                                        <select
                                                                        className="form-control"
                                                                        name="age"
                                                                        id="age"
                                                                        required="required"
                                                                        onChange={e => setAge(e.target.value)}
                                                                    >
                                                                        <option value="">
                                                                        Select Age</option>
                                                                        {user_ages.map(
                                                                        (user_age, index) => (
                                                                            <option value={user_age} selected={user_age == local_age}>{user_age} Year</option>
                                                                        )
                                                                        )}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li className="item-content item-input col-50 dob">
                                                                <div className="item-col">
                                                                    <div className="item-title item-label">Gender
                                                                        <span>*</span></div>
                                                                    <div className="item-input-wrap">
                                                                        <select className="form-control" name="gender" id="gender" required="required" onChange={e=>setGender(e.target.value)}>
                                                                            <option value="Male" selected={local_gender == 'Male'}>Male</option>
                                                                            <option value="Female" selected={local_gender == 'Female'}>Female</option>
                                                                            <option value="Other" selected={local_gender == 'Other'}>Other</option>
                                                                        </select>
                                                                        <span className="input-clear-button"></span>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li className="item-content item-input col-50">
                                                                <div className="item-col">
                                                                    <div className="item-title item-label">Height
                                                                        <span>*</span></div>
                                                                <div className="item-input-wrap">
                                                                    <select  onChange={e => setHeight(e.target.value)} name="height" id="m_height" className="form-control">
                                                                        <option value="4ft 7in" selected={local_height == '4ft 7in'}>4ft 7in</option>
                                                                        <option value="4ft 8in" selected={local_height == '4ft 8in'}>4ft 8in</option>
                                                                        <option value="4ft 9in" selected={local_height == '4ft 9in'}>4ft 9in</option>
                                                                        <option value="4ft 10in" selected={local_height == '4ft 10in'}>4ft 10in</option>
                                                                        <option value="4ft 11in" selected={local_height == '4ft 11in'}>4ft 11in</option>
                                                                        <option value="5ft 0in" selected={local_height == '5ft 0in'}>5ft 0in</option>
                                                                        <option value="5ft 1in" selected={local_height == '5ft 1in'}>5ft 1in</option>
                                                                        <option value="5ft 2in" selected={local_height == '5ft 2in'}>5ft 2in</option>
                                                                        <option value="5ft 3in" selected={local_height == '5ft 3in'}>5ft 3in</option>
                                                                        <option value="5ft 4in" selected={local_height == '5ft 4in'}>5ft 4in</option>
                                                                        <option value="5ft 5in" selected={local_height == '5ft 5in'}>5ft 5in</option>
                                                                        <option value="5ft 6in" selected={local_height == '5ft 6in'}>5ft 6in</option>
                                                                        <option value="5ft 7in" selected={local_height == '5ft 7in'}>5ft 7in</option>
                                                                        <option value="5ft 8in" selected={local_height == '5ft 8in'}>5ft 8in</option>
                                                                        <option value="5ft 9in" selected={local_height == '5ft 9in'}>5ft 9in</option>
                                                                        <option value="5ft 10in" selected={local_height == '5ft 10in'}>5ft 10in</option>
                                                                        <option value="5ft 11in" selected={local_height == '5ft 11in'}>5ft 11in</option>
                                                                        <option value="6ft 0in" selected={local_height == '6ft 0in'}>6ft 0in</option>
                                                                        <option value="6ft 1in" selected={local_height == '6ft 1in'}>6ft 1in</option>
                                                                        <option value="6ft 2in" selected={local_height == '6ft 2in'}>6ft 2in</option>
                                                                        <option value="6ft 3in" selected={local_height == '6ft 3in'}>6ft 3in</option>
                                                                        <option value="6ft 4in" selected={local_height == '6ft 4in'}>6ft 4in</option>
                                                                        <option value="6ft 5in" selected={local_height == '6ft 5in'}>6ft 5in</option>
                                                                        <option value="6ft 6in" selected={local_height == '6ft 6in'}>6ft 6in</option>
                                                                        <option value="6ft 7in" selected={local_height == '6ft 7in'}>6ft 7in</option>
                                                                        <option value="6ft 8in" selected={local_height == '6ft 8in'}>6ft 8in</option>
                                                                        <option value="6ft 9in" selected={local_height == '6ft 9in'}>6ft 9in</option>
                                                                        <option value="6ft 10in" selected={local_height == '6ft 10in'}>6ft 10in</option>
                                                                        <option value="6ft 11in" selected={local_height == '6ft 11in'}>6ft 11in</option>
                                                                        <option value="7ft 0in" selected={local_height == '7ft 0in'}>7ft 0in</option>
                                                                    </select>
                                                                        <span className="input-clear-button"></span>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li className="item-content item-input col-50 dob">
                                                                <div className="item-col">
                                                                    <div className="item-title item-label">Weight
                                                                        <span>*</span></div>
                                                                    <div className="item-input-wrap">
                                                                            
                                                                        <select
                                                                        className="form-control"
                                                                        name="age"
                                                                        id="age"
                                                                        required="required"
                                                                        onChange={e => setWeight(e.target.value)}
                                                                    >
                                                                        <option value="">
                                                                        Select Weight</option>
                                                                        {user_weight.map(
                                                                        (user_weight, index) => (
                                                                            <option value={user_weight} selected={user_weight == local_weight}>{user_weight} KG</option>
                                                                        )
                                                                        )}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li className="item-content item-input">
                                                                <div className="item-col">
                                                                    <div className="item-title item-label">Are You Diabetic?
                                                                        <span>*</span></div>
                                                                    <div className="item-input-wrap">
                                                                        <select className="form-control" name="dibetic" id="dibetic" required="required" onChange={e=>setDibetic(e.target.value)}>
                                                                            <option value="No" selected={local_dibetic == 'No'}>No</option>
                                                                            <option value="Yes" selected={local_dibetic == 'Yes'}>Yes</option>
                                                                        </select>
                                                                        <span className="input-clear-button"></span>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li className="item-content item-input">
                                                                <div className="item-col">
                                                                    <div className="item-title item-label">Have Blood Pressure?
                                                                        <span>*</span></div>
                                                                    <div className="item-input-wrap">
                                                                        <select className="form-control" name="bloodPressure" id="bloodPressure" required="required" onChange={e=>setBloodPressure(e.target.value)}>
                                                                            <option value="No" selected={local_bloodPressure == 'No'}>No</option>
                                                                            <option value="Yes" selected={local_bloodPressure == 'Yes'}>Yes</option>
                                                                        </select>
                                                                        <span className="input-clear-button"></span>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li className="item-content item-input">
                                                                <div className="item-col">
                                                                    <div className="item-title item-label">Do You Smoke?
                                                                        <span>*</span></div>
                                                                    <div className="item-input-wrap">
                                                                        <select className="form-control" name="smoke" id="smoke" required="required" onChange={e=>setSmoke(e.target.value)}>
                                                                            <option value="No" selected={local_smoke == 'No'}>No</option>
                                                                            <option value="Yes" selected={local_smoke == 'Yes'}>Yes</option>
                                                                            <option value="Occasionally" selected={local_smoke == 'Occasionally'}>Occasionally</option>
                                                                        </select>
                                                                        <span className="input-clear-button"></span>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li className="item-content item-input">
                                                                <div className="item-col">
                                                                    <div className="item-title item-label">Do You Drink Alcohol?
                                                                        <span>*</span></div>
                                                                    <div className="item-input-wrap">
                                                                        <select className="form-control" name="gender" id="gender" required="required" onChange={e=>setAlcohol(e.target.value)}>
                                                                            <option value="No" selected={local_alcohol == 'No'}>No</option>
                                                                            <option value="Yes" selected={local_alcohol == 'Yes'}>Yes</option>
                                                                            <option value="Occasionally" selected={local_alcohol == 'Occasionally'}>Occasionally</option>
                                                                        </select>
                                                                        <span className="input-clear-button"></span>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                           
                                                        </ul>                                                 
                                                </div>
                                            </div>
                                            <ul>
                                                <li className="bottom-button">
                                                    <button type='submit' className="btn">Confirm and Next</button>
                                                </li>
                                            </ul>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* 

                <div className="register-inner-col">
                    <h3>Make Appointment</h3>
                    <div className="col-12 col-md-6 border-right">
                        <form action="javascript:void(0)" onSubmit={submitFirstStep}>
                            <div className="step_first">
                                <label> Name</label>
                                <div className="form-group">
                                    
                                    <input type="text" className="form-control" placeholder="Patient Name" required name="firstName" onChange={e => setFirstName(e.target.value)} value={firstName} />
                                </div>
                                <label> Mobile</label>
                                <div className="form-group">
                                    <input type="text" required placeholder="Enter 10 Digit Mobile Number" className="form-control allow_only_number" maxlength="10" name="mobile" onChange={e => setMobile(e.target.value)} value={mobile}/>
                                </div>
                                <label>Age</label>
                                <div className="form-group">
                                    <input type="text" required placeholder="Enter Age" className="form-control" name="age" onChange={e => setAge(e.target.value)} value={age}/>
                                </div>
                                <label>Gender</label>
                                <div className="form-group">
                                    <select className="form-control" name="gender" id="gender" required="required" onChange={e=>setGender(e.target.value)}>
                                        <option value=""> Select Gender </option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                        <option value="Other">Other</option>
                                    </select>
                                </div>
                                <label>Height & Weight</label>
                                <div className="form-group">
                                    <div className='col-md-6'>
                                        <input type="text" required placeholder="Enter Height & Weight" className="form-control" name="dob" onChange={e => setHeightWeight(e.target.value)} value={heightWeight}/>
                                    </div>
                                </div>
                                <label>
                                    Are You Diabetic? &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    
                                </label>
                                    <div className="form-group">
                                        Yes &nbsp;<input type="radio" name="dibetic" onChange={e => setDibetic(e.target.value)} value="Yes"/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        No &nbsp;<input type="radio" name="dibetic" checked onChange={e => setDibetic(e.target.value)} value="No"/>
                                    </div>
                                <label>Have Blood Pressure? </label>
                                <div className="form-group">
                                    Yes &nbsp;<input type="radio" name="bloodPressure" onChange={e => setBloodPressure(e.target.value)} value="Yes"/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                    No &nbsp;<input type="radio" name="bloodPressure" checked onChange={e => setBloodPressure(e.target.value)} value="No"/>
                                </div>
                                <label>Have Blood Pressure? &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </label>
                                <div className="form-group">
                                    Yes &nbsp;<input type="radio" name="bloodSugure" onChange={e => setBloodSugure(e.target.value)} value="Yes"/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    No &nbsp;<input type="radio" name="bloodSugure" checked onChange={e => setBloodSugure(e.target.value)} value="No"/>
                                </div>
                                <label>Do You Smoke? &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </label>
                                <div className="form-group">
                                    Yes &nbsp;<input type="radio" name="smoke" onChange={e => setSmoke(e.target.value)} value="Yes"/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    No &nbsp;<input type="radio" name="smoke" checked onChange={e => setSmoke(e.target.value)} value="No"/>
                                </div>
                                <div className="form-group text-center ">
                                    <button type="submit" className="btn book-btn" style={{float:"right"}}>&nbsp;&nbsp;Next&nbsp;&nbsp;</button>
                                </div>
                                <br/>
                            </div>
                           
                        </form>
                    </div>
                </div>
                */}
            </div>
        </div>
    </div>
    </>
  )
}

export default StepFirst