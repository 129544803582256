import React from "react";
import ServiceSideBar from "../modules/ServiceSideBar";

export default function AmbulancewithDeadbody(){
  return(
    <>
      <div class="content">
            <div class="container-fluid">
                <div class="row">
                  <div class="col-sm-9">
                    <div class="card">
                      <div class="card-body">
                        <h3>Describe an Ambulance With Dead Body </h3><br></br>
                        <p className="">Road Transport Services for Dead Bodies, The deceased person's body will be released for disposal if the cause of death has been established. Usually, a funeral house is involved; they have a hearse and, less officially, full-size or tiny ambulance vehicles.</p><br></br>

                        <h3>History</h3><br></br>
                        <p className="">In the historic Parmanand Katara v. Union of India case from 1989, the Apex Court of India declared that a person's right to life, fair treatment, and dignity extends not only to them while they are alive but also to their dead corpse. These rights are a result of Article 21 of the Indian Constitution.</p><br></br>

                        <h3>How is the deceased made ready for travel? </h3><br></br>
                        <p className="">The dead are either embalmed or kept in a refrigerator before being transported on dry ice. The deceased is entombed in a coffin or transportable container that has been authorised. The deceased person's body must be transported with all necessary documents.</p><br></br>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
      </div>
    </>
  );
}