import React from "react";
import ServiceSideBar from "../modules/ServiceSideBar";

export default function AmbulancewithICUsetup(){
  return(
    <>
      <div class="content">
            <div class="container-fluid">
                <div class="row">
                  <div class="col-sm-9">
                    <div class="card">
                      <div class="card-body">
                        <h3>Merchandise Description </h3><br></br>
                        <p className=""> The upgraded life support ambulances are equipped with life-saving devices including cardiac monitors, ventilators, suction machines, infusion pumps, and other devices, as well as all emergency life-saving medications, creating an environment similar to that of an intensive care unit (ICU). The medical patients who are experiencing medical trauma will be transported in these ambulances. </p><br></br>

                        <h3>Our Identity</h3><br></br>
                        <p className="">ResQ101: Our clients' perceptions of the kind of business we are affect how well we serve them. Regulating what our patients' and users' perceptions of exceptional quality and service are a difficulty. Since EMS provides both healthcare and logistical services, it is obvious that quick service for both urgent and non-urgent patients is the foundation of service quality. Clinical results and customer satisfaction suffer from a delayed response if an EMS provider is unable to deliver a healthcare asset at a vital location within a crucial window of time. When aiming to attain EMS service excellence, a number of other aspects are important. The most evident is the connection between the medic and the patient since even the greatest treatment notably at those periods in one's life cycle when they or a family member is unable to care for themselves due to illness or accident. Our company demands that all of its equipment be spotless, cutting-edge, and secure in order to cultivate loyalty from its clientele since we discovered that patients found the experience to be dependable and soothing. For our clients to believe that the service they hired is dedicated to quality and service excellence, in addition to the equipment, the professional image that the medic and management team portray when engaging with our clients is essential. Every day, each business unit's specified quality indicators are measured with the intention of changing activities, as necessary, to fulfil if our goals aren't met, our aims. Our computer-aided dispatch will soon be connected to a quality matrix dashboard that evaluates operations not by the day but by the minute, allowing management to make adjustments sooner rather than later, maybe before it's too late. We are sure that over the next months, we will effectively raise the standard in terms of quality and EMS service excellence. I'm hoping that we can raise the bar even more the next year.</p><br></br>

                        <h3>Advanced Life Support Medical Technology</h3><br></br>
                        <p className="">The following materials must be included within Advanced Life Support Ambulances (Class C), as specified in Royal Decree 836/2012:</p><br></br>

                        <h4>EQUIPMENT FOR TRANSFER OF PATIENTS </h4>
                          <ul>
                            <li>Central stretcher</li>
                            <li>Stretcher with an anti-trauma scoop</li>
                            <li>To transfer a patient who is sitting, use a vacuum mattress</li>
                            <li>Sheet for transfer</li>
                            <li>Restrictive straps and a full-length spine board with a head immobiliser</li>
                          </ul><br></br>

                        <h4>IMMOBILISATION TECHNOLOGY</h4>
                          <ul>
                            <li>Traction mechanism</li>
                            <li>Setting up immobilisation for fractures</li>
                            <li>Devices to immobilise the cervical spine</li>
                            <li>Keeping the upper spine immobile</li>
                          </ul><br></br>

                        <h4>VENTILATION SYSTEMS</h4>
                          <ul>
                            <li>Constant oxygen supply</li>
                            <li>Portable source of oxygen</li>
                            <li>Masks, cannulas, and oxygen cylinders for all ages, as well as a resuscitator with oxygen supply.</li>
                            <li>Apparatus for non-manual aspiration</li>
                            <li>Movable aspiration apparatus</li>
                          </ul><br></br>

                        <h4>DIAGNOSTIC TECHNIQUES</h4>
                          <ul>
                            <li>Monitor for manual blood pressure</li>
                            <li>Automatically taking your blood pressure</li>
                            <li>Oximeter \sStethoscope</li>
                            <li>Thermometer</li>
                            <li>Medical penlight and glucometer</li>
                          </ul><br></br>

                        <h4>INFUSION TECHNIQUES</h4>
                          <ul>
                            <li>Solutions for infusion</li>
                            <li>Equipment for injection and infusion</li>
                            <li>Intended to enable the delivery of hot liquids</li>
                            <li>Fusion assistance</li>
                            <li>Inflated infusion system</li>
                          </ul><br></br>

                        <p className="">A defibrillator and a heart monitor are tools for managing life-threatening situations.</p><br></br>

                        <h4>MEDICATION</h4>
                          <ul>
                            <li>Analgesics</li>
                            <li>Spasmolytics</li>
                            <li>Vasoactive drugs</li>
                            <li>Antihistamines</li>
                            <li>Iris drops</li>
                            <li>Digestive drugs</li>
                            <li>Serums</li>
                            <li>Others</li>
                          </ul><br></br>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
      </div>
    </>
  );
}