import axios from "axios";

// const userDetails = localStorage.getItem('user_info');
// const jsonUserDetails = JSON.parse(userDetails);
// const userToken = jsonUserDetails.token;
// alert(userToken);
export default axios.create({
    baseURL:"https://acemedicare.in/api/", 
    // baseURL:"https://acemedicare-admin.hospicares.in/api/", 
    // baseURL:"https://acemedicare.in/admin-login/api", 
    // baseURL:"http://127.0.0.1:8000/api",
    // baseURL:"http://127.0.0.1:8081/api", 
    headers:{
        "Content-type":"application/json",
        "X-CSRF-TOKEN": document.head.querySelector('meta[name="csrf-token"]').content,
        // "Authorization" : "Bearer ${userToken}"
    }
});