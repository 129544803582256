import React from 'react'

function AlertDanger(props) {
  return (
    <>
        {props.alert==null || props.alert=='' ? '':
        <div className="alert alert-danger">
            <strong>{props.alert}</strong>
        </div>
        }
    </>
  )
}

export default AlertDanger