import React, { useEffect, useState } from "react";
import Https from "../Https";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import ReactHtmlParser from 'react-html-parser';
import LoginModel from "./modules/LoginModel";
import AppointmentModel from "./modules/AppointmentModel";
import Header from "./includes/Header";
import Footer from "./includes/Footer";


export default function HospitalPackageDetail(props)
{
    // function for load comonent from top
	const { pathname } = useLocation();
	useEffect(() => {
	  window.scrollTo(0, 0);
	}, [pathname]);
    
    const[vendorDtl, setVendorDtl] = useState([]);
    const[packageServiceImgPath, setPackageServiceImgPath] = useState([]);
    const {vendorSlug} = useParams();
    const {packageSlug} = useParams();
    const {role_name} = useParams();
    const navigate = useNavigate();
    //////////  use effect for fetch package details 
    useEffect(() =>{ 
        fetchVendorDetails();  
    },[]); 
    /////////   call api for get vendor detail
    const fetchVendorDetails = () =>{
        Https.get('/vendor-detail/'+role_name+'/'+vendorSlug).then(response=>{
            setVendorDtl(response.data);
        });  
    }
    // variable for hospital image pat
    const [hosImgPath, sethosImgPath] = useState([]);
    // define function for fetch hospital api
    useEffect(()=>{
        fetchHosImgPath();
    },[]);
    // function for api get hospital image path
    const fetchHosImgPath = () =>{
        Https.get('vendor-image-path').then(response=>{
            sethosImgPath(response.data);
        });
    }

    //////////// script & function for vendor selected packages
    //  make variable for store api data
    const [vendorSelectedPackages, setVendorSelectedPackages] = useState([]);
    // blank function for call vendor selected packages
    useEffect(()=>{
        fetchVendorSelectedPackages();
    },[]);
    // call api for get vendor selected packages
    const fetchVendorSelectedPackages = () =>{
        // Https.get('/vendor-detail/'+role_name+'/'+vendorSlug).then(response=>{
        Https.get('vendor-selected-package/'+role_name+'/'+vendorSlug+'/'+packageSlug).then(response=>{
            setVendorSelectedPackages(response.data);
        });
    }
    //////////  script & function for package details
    // variable for get package details using usestate
    const[packageDetails, setpackageDetails] = useState([]);
    const[packageService, setpackageService] = useState([]);
    //  use effect for fetch package details 
    useEffect(() =>{ 
        fetchPackageDetails();  
    },[]); 
    //   call api for get packagedetails
    const fetchPackageDetails = () =>{
        Https.get('/package-details/'+packageSlug).then(response=>{
            setpackageDetails(response.data.data);
            setpackageService(response.data.data.package_service);
        });  
    }
    //////////  script & function for package image path
    // variable for get package image path using usestate
    const[packageImgPath, setPackageImgPath] = useState([]);
    //  use effect for fetch package image path
    useEffect(()=>{
        fetchPackageImagePath();
    },[]);
    //  call api for get image path
    const fetchPackageImagePath = () =>{
        Https.get('/package-image-path').then(response=>{
            setPackageImgPath(response.data);  
        });     
    } 
    // //////////  use effect for fetch package image path
    useEffect(()=>{
        fetchPackageServiceImagePath();
    },[]);
    // //////////  call api for get image path
    const fetchPackageServiceImagePath = () =>{
        Https.get('/package-service-image-path').then(response=>{
            setPackageServiceImgPath(response.data);
        });     
    } 
    //////////  get local session storage data for check user login or not
    const getToken = () => {
        const tokenString = sessionStorage.getItem('token');
        const userToken = JSON.parse(tokenString);
        return userToken;
    }
    const getUser = () => {
        const userString = sessionStorage.getItem('user');
        const userDetail = JSON.parse(userString);
        return userDetail;
    }
    const [token, setToken] = useState(getToken());
    const [user, setUser] = useState(getUser());
    const [firstName, setFirstName] = useState("");
   const [last_name, setLastName] = useState("");
   const [mobile, setMobile] = useState("");
   const [email, setEmail] = useState("");
   const [city_id, setCity_id] = useState();
   const [messages, setMessages] = useState("");
   const packageid = packageDetails.id;
   const enquiryType = 'Package';

   const submitInquiryforContactOther = () => {
      if(firstName=='' || mobile=='')
      {
         alert('Name or Mobile Number field is required');
      }
      else
      {
         Https.post('store-contact-inquiry',{first_name: firstName,last_name:last_name, mobile: mobile, email:email,city_id:city_id, messages:messages, packageid: packageid, enquiryType:enquiryType}).then(response => {
            console.log(response.status);
            if(response.status==200)
            {
               setFirstName("");
               setLastName("");
               setMobile("");
               setEmail("")
               setMessages("");
               alert('Your request has been registered successfully. One of our agent will get in touch soon. Thank You!');
               navigate('/search-package-results/'+city_id+'/'+packageid);
               
            }
            else
            {
                setFirstName("");
                setLastName("");
                setMobile("");
                setEmail("")
                setMessages("");
            }
         })
      }
    }
    const [cities, setCities] = useState([]);
    const [treatPackages, setTreatPackages] = useState([]);
     //////////  use effect for fetch state list
     useEffect(() =>{
        fetAllCities();
    },[]);
    //////////  call api for get all states
    const fetAllCities = () =>{
        Https.get('get-hospital-cities').then(response=>{
            setCities(response.data.data);
        })
    }
    useEffect(()=>{
        fetchAllPackages();
    },[]);
    /////////   call api for get active package list                
    const fetchAllPackages = () =>{
        Https.get('all-active-packages').then(response=>{
        setTreatPackages(response.data.data);
        });  
    }

    const [hospitalId, setHospitalId] = useState();
    const [hospitalName, setHospitalName] = useState();
    const getHospital = (event) =>{
        const hospiName = event.target.attributes.getNamedItem('data-hospitalName').value;
        const hospiId = event.target.attributes.getNamedItem('data-hospitalId').value;
        setHospitalId(hospiId);
        setHospitalName(hospiName);
    }
    // const [firstName, setFirstName] = useState("");
    // const [last_name, setLastName] = useState("");
    // const [mobile, setMobile] = useState("");
    // const [email, setEmail] = useState("");
    const [problems, setProblems] = useState("");
    const [cityInput, setCityInput] = useState("");
    const [packageInput, setPackageInput] = useState("");
    // const enquiryType = 'Hospital_Package';


   const submitInquiryforContactOtherTest = () => {
    
    }
    const testFunction = () =>{
        if(firstName=='' || mobile=='' || cityInput=='' || packageInput=='')
        {
        alert('All field is required');
        }
        else
        {
        Https.post('store-contact-inquiry',{first_name: firstName,last_name:last_name, mobile: mobile, email:email, problems: problems, enquiryType:enquiryType,city_id:cityInput,package_id:packageInput,hospitalId:hospitalId,hospitalName:hospitalName}).then(response => {
        console.log(response.status);
        if(response.status==200)
        {
            setFirstName("");
            setLastName("");
            setMobile("");
            setEmail("")
            setProblems("");
            alert('Your request has been registered successfully. One of our agent will get in touch soon. Thank You!');
            window.location.reload();

        }
        else
        {
            setFirstName("");
            setLastName("");
            setMobile("");
            setEmail("")
            setProblems("");
        }
        })
        }
    }
    const selectCity = (e) =>{
        const selectedCityID = e.target.value;
        setCityInput(selectedCityID);
        Https.get('find-package-by-city-id/'+selectedCityID).then(response =>{
        //    console.log(response.data.data);
           setTreatPackages(response.data.data);
        });
    }

    const {citys_id} = useParams();
    const {package_id} = useParams();

    
    return(  
        <>
        {/* <div className="main-wrapper"> */}
            <section class="home-search-section">
                <div class="container">
                    <div class="wrapper-content">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="doctor-search">
                                <div class="banner-header">
                                    <h2>{packageDetails.name} <br/> <span class="highlighter">on AceMedicare</span></h2>
                                    <p align="justify">{packageDetails.short_description}</p>
                                    
                                    <a href="tel:8603002000" style={{backgroundColor:"#1e5c92", borderColor:"#1e5c92",color:"white"}} class="btn btn-rounded btn-outline-secondary" tabindex="0"> <i class="fa fa-phone" aria-hidden="true"></i> Call Us : 860 300 2000</a>&nbsp;&nbsp;&nbsp;
                                    <p></p>
                                    <a href="#getIntouch" style={{backgroundColor:"rgb(255, 211, 7)", borderColor:"rgb(255, 211, 7)", color:"rgb(0 0 0)"}} class="btn btn-rounded btn-outline-secondary" tabindex="0" data-bs-toggle="modal" data-bs-target="#appointment_model"> <i class="fa fa-calendar" aria-hidden="true" style={{color:"rgb(0 0 0)"}}></i> Book Free Appointment</a>
                                    <p></p>
                                    <h5 class="mb-3">5% to 10% instant discount on all Surgeries*</h5>
                                    <h5 class="mb-3">No cost EMI solutions in all Hospitals*</h5>
                                    <h5 class="mb-3">Consumable payments for all Insurance Patients will be waived off*</h5>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <img src="../../doccure_assets/img/gulsanji_4.png" class="img-fluid dr-img" alt=""/>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
            <div class="content">
                <div class="container">
                    <div class="card">
                        <div class="card-body">
                            <div class="doctor-widget">
                                <div class="doc-info-left">
                                    <div class="">
                                        <img src={hosImgPath+'/'+vendorDtl.vendor_img} class="img-fluid" alt={vendorDtl.shop_name} style={{height: "150px", width:"300px"}}/>
                                        {/* <img src="../doccure_assets/img/doctors/doctor-thumb-02.jpg" class="img-fluid"
                                            alt="User Image" /> */}
                                    </div>
                                    <div class="">
                                        <h4 class="doc-name">{vendorDtl.shop_name}</h4>
                                        <p class="doc-speciality">{vendorDtl.shop_specialization}</p>
                                        <p align="justify">{vendorDtl.about_shop}</p>
                                        <div class="clinic-details">
                                            <p class="doc-location"><i class="fas fa-map-marker-alt"></i> {vendorDtl.shop_address} - {vendorDtl.pin_code}</p>
                                          
                                        </div>
                                    </div>
                                </div>
                                <div class="doc-info-right">
                                    <div class="clini-infos">
                                        <ul>
                                            <li><i class="fa fa-mobile"></i>+91- {vendorDtl.shop_mobile}</li>
                                            <li><i class="fa fa-envelope"></i> {vendorDtl.shop_email}</li>
                                        </ul>
                                    </div>
                                    {/* <div class="doctor-action">
                                        <a href="javascript:void(0)" class="btn btn-white fav-btn">
                                            <i class="far fa-bookmark"></i>
                                        </a>
                                        <a href="javascript:void(0)" class="btn btn-white msg-btn">
                                            <i class="far fa-comment-alt"></i>
                                        </a>
                                        <a href="javascript:void(0)" class="btn btn-white call-btn" data-bs-toggle="modal"
                                            data-bs-target="#voice_call">
                                            <i class="fas fa-phone"></i>
                                        </a>
                                        <a href="javascript:void(0)" class="btn btn-white call-btn" data-bs-toggle="modal"
                                            data-bs-target="#video_call">
                                            <i class="fas fa-video"></i>
                                        </a>
                                    </div> */}
                                    <div class="clinic-booking">
                                        <Link class="view-pro-btn" target="_blank" to={`/${vendorDtl.role_name}-detail/${vendorDtl.shop_slug}`}>View Profile</Link>
                                        <Link class="view-pro-btn" to="#" onClick={(event)=>getHospital(event)}data-bs-toggle="modal" data-hospitalName={vendorDtl.shop_name} data-hospitalId={vendorDtl.id} data-bs-target="#hospital_appointment_model">
                                            Get Quote </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 col-lg-12 col-xl-12">
                            <div class="card">
                                <div class="card-body product-description" style={{"backgroundColor": "#f8fcff"}}>
                                    <div class="doctor-widget">
                                        <div class="doc-info-left">
                                            <div class="doctor-img1">
                                                <img src={packageImgPath+'/'+packageDetails.main_img} class="img-fluid"
                                                    alt={packageDetails.name} />
                                            </div>
                                            <div class="">
                                                <h4 class="doc-name mb-2" style={{color:"#28daf4"}}>{packageDetails.name}</h4>
                                                <p><span class="text-muted">Related By </span> {packageDetails.package_master_name}</p>
                                                <p align="justify">{packageDetails.short_description}</p>
                                                <p>
                                                    <div class="card-body">
                                                        <ul class="tags">
                                                            {packageService.map((packageService,index)=>(
                                                            <li><a href={`#package_service_${packageService.id}`} class="tag" style={{"backgroundColor":"#1e5c92","color":"#fff"}}>{packageService.service_name}</a></li>
                                                            
                                                            ))}
                                                        </ul>
                                                    </div>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 col-lg-12 col-xl-12">
                            <div class="card">
                                <div class="card-body pt-0">
                                    <h3 class="pt-4">Package Details</h3>
                                    <hr />
                                    <div class="card">
                                        {packageService.map((packageService,index)=>(
                                        <div class="card-body" id={`package_service_${packageService.id}`}>
                                            <div class="doctor-widget">
                                                <div class="doc-info-left">
                                                    <div class="" style={{width:"250px"}}>
                                                        <a href="javascript:void(0)">
                                                            <img alt={packageDetails.name} src={packageServiceImgPath+'/'+packageService.service_img} class="img-fluid" />
                                                        </a>
                                                    </div>
                                                    <div class="">
                                                        <h4 class="doc-name"><a href="javascript:void(0)">{packageService.service_name}</a></h4>
                                                        <p class="doc-speciality" align="justify">{ReactHtmlParser(packageService.description)}
                                                        </p>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                         ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                                      
                    <div class="card">
                        <div class="card-body pt-0">

                            <nav class="user-tabs mb-4">
                                <ul class="nav nav-tabs nav-tabs-bottom nav-justified">
                                    {/* <li class="nav-item">
                                        <a class="nav-link active" href="#doc_packages" data-bs-toggle="tab">Packages</a>
                                    </li> */}
                                    <li class="nav-item">
                                        <a class="nav-link " href="#doc_overview" data-bs-toggle="tab">Overview</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" href="#doc_locations" data-bs-toggle="tab">Locations</a>
                                    </li>
                                </ul>
                            </nav>
                            <div class="tab-content pt-0">
                                <div role="tabpanel" id="doc_overview" class="tab-pane fade show active">
                                    <div class="row">
                                        <div class="col-md-12 col-lg-9">

                                            <div class="widget about-widget">
                                                <h4 class="widget-title">About Me</h4>
                                                <p>{vendorDtl.about_shop}</p>
                                            </div>
                                            <div class="widget education-widget">
                                                <h4 class="widget-title">Speciality</h4>
                                                <div class="experience-box">
                                                    <ul class="experience-list">
                                                        <li>
                                                            <div class="experience-user">
                                                                <div class="before-circle"></div>
                                                            </div>
                                                            <div class="experience-content">
                                                                <div class="timeline-content">
                                                                    <a href="#/" class="name">{vendorDtl.shop_specialization}</a>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="widget education-widget">
                                                <h4 class="widget-title">Services</h4>
                                                <div class="experience-box">
                                                    <ul class="experience-list">
                                                        <li>
                                                            <div class="experience-user">
                                                                <div class="before-circle"></div>
                                                            </div>
                                                            <div class="experience-content">
                                                                <div class="timeline-content">
                                                                    <a href="#/" class="name">{vendorDtl.shop_services}</a>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="widget education-widget">
                                                <h4 class="widget-title">Amenties</h4>
                                                <div class="experience-box">
                                                    <ul class="experience-list">
                                                        <li>
                                                            <div class="experience-user">
                                                                <div class="before-circle"></div>
                                                            </div>
                                                            <div class="experience-content">
                                                                <div class="timeline-content">
                                                                    <a href="#/" class="name">{vendorDtl.amenties}</a>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="widget education-widget">
                                                <h4 class="widget-title">Facilities</h4>
                                                <div class="experience-box">
                                                    <ul class="experience-list">
                                                        <li>
                                                            <div class="experience-user">
                                                                <div class="before-circle"></div>
                                                            </div>
                                                            <div class="experience-content">
                                                                <div class="timeline-content">
                                                                    <a href="#/" class="name">{vendorDtl.facilities}</a>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div role="tabpanel" id="doc_locations" class="tab-pane fade">

                                    <div class="location-list">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="clinic-content">
                                                    <h4 class="clinic-name"><a href="#">{vendorDtl.shop_name}</a>
                                                    </h4>
                                                    <p class="doc-speciality">{vendorDtl.shop_specialization}</p>
                                                    <div class="clinic-details mb-0">
                                                        <h5 class="clinic-direction"> 
                                                            <i class="fas fa-map-marker-alt"></i> &nbsp; 
                                                            {vendorDtl.shop_address} - {vendorDtl.pin_code}
                                                        </h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                        
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <LoginModel />
                <AppointmentModel />
            </div>
        </>
    )
}