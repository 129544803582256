import React from 'react'

function VideoMeeting() {
  return (
    <>
    <iframe src="http://localhost:3001/" allow="camera;microphone" style={{"width":"100%","height": "800px"}}></iframe>
    </>
  )
}

export default VideoMeeting
