import React, { useEffect, useState } from "react";
import Https from "../Https";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import Bottom from "./modules/Bottom";
import Footer from "./includes/Footer";
import Header from "./includes/Header";

export default function TransitionCareCenter() {
  const [faqShow, setFaqShow] = useState(false);
  const { pathname } = useLocation();
  const modify_pathName = pathname.replace('/', '');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);


  const [serviceCities, setServiceCities] = useState([]);
  //////////  use effect for fetch state list
  useEffect(() =>{
    fetAllCities();
  },[]); 
  // alert(modify_pathName);
  //////////  call api for get all states
  const fetAllCities = () =>{
      Https.get('ace-service-cities/'+modify_pathName).then(response=>{
        setServiceCities(response.data.data);
      })
  }

  const [firstName, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [passport_no, setPassportNo] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [nationality, setNationality] = useState("");
  const [messages, setMessages] = useState("");
  const [cityInput, setCityInput] = useState("");
  const enquiryType = 'Transition Care Center';
  const submitInquiryforContactOther = () => {
    if (firstName == '' || mobile == '') {
      alert('Name or Mobile Number field is required');
    }
    else {
      Https.post('store-contact-inquiry', { first_name: firstName, last_name: last_name, mobile: mobile, email: email, passport_no: passport_no,city_id:cityInput, gender: gender, nationality: nationality, messages: messages, enquiryType: enquiryType }).then(response => {
        console.log(response.status);
        if (response.status == 200) {
          setFirstName("");
          setLastName("");
          setMobile("");
          setEmail("")
          setPassportNo("");
          setAge("");
          setGender("");
          setNationality("")
          setMessages("");
          alert('Your request has been registered successfully. One of our agent will get in touch soon. Thank You!');

        }
        else {
          setFirstName("");
          setLastName("");
          setMobile("");
          setEmail("")
          setPassportNo("");
          setAge("");
          setGender("");
          setNationality("")
          setMessages("");
        }
      })
    }
  }
  return (
    <>
      <div className="main-wrapper">
        <section class="section section-search-1">
          <div class="container">
            <div class="breadcrumb-bar">
              <div class="container-fluid">
                <div class="row align-items-center">
                  <div class="col-md-12 col-12">
                    
                  </div>
                </div>
              </div>
            </div>
            <div class="row ">
              <div class="col-md-6 mb-6 search-doctor">
                <div class="search-area" style={{ maxWidth: "545px" }}>
                  <h2 class="text-center">Enquiry for <span style={{ textTransform: "capitalize", color: "red" }}>Transition Care </span>Center</h2>
                  <form class="search-input" action="javascript:void(0)" onSubmit={submitInquiryforContactOther}>
                    <div class="row">
                      <div class="col-6 col-md-6">
                        <div class="form-group">
                          <label>First Name</label>
                          <input type="text" class="form-control" required name="firstName" onChange={e => setFirstName(e.target.value)} value={firstName} />
                        </div>
                      </div>
                      <div class="col-6 col-md-6">
                        <div class="form-group">
                          <label>Last Name</label>
                          <input className="form-control" type="text" name="last_name" onChange={e => setLastName(e.target.value)} value={last_name} />
                        </div>
                      </div>
                      <div class="col-6 col-md-6">
                        <div class="form-group">
                          <label>Mobile No</label>
                          <input type="text" required class="form-control allow_only_number" maxlength="10" name="mobile" onChange={e => setMobile(e.target.value)} value={mobile} />
                        </div>
                      </div>
                      <div class="col-6 col-md-6">
                        <div class="form-group">
                          <label>Email ID</label>
                          <input className="form-control" required type="email" name="email" onChange={e => setEmail(e.target.value)} value={email} />
                        </div>
                      </div>
                      <div class="col-6 col-md-6">
                        <div class="form-group">
                          <label>City</label>
                          <select className="form-control" name="city_id" id="city_id" placeholder="Search Location" required="required" onChange={e=>setCityInput(e.target.value)}>
                              <option value=""> Select Cities </option>
                              {serviceCities.map((serviceCity,index)=>(
                              <option value={serviceCity.city_id}>{serviceCity.city_name}</option>
                              ))}
                          </select>
                          {/* <input type="text" class="form-control" name="age" onChange={e => setAge(e.target.value)} value={age} /> */}
                        </div>
                      </div>
                      <div class="col-6 col-md-6">
                        <div class="form-group">
                          <label>Gender</label>
                          <select class="form-select form-control" name="gender" onChange={e => setGender(e.target.value)}>
                            <option value="Male">Male</option>
                            <option value="FeMale">FeMale</option>
                            <option value="Other">Other</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-12 col-md-12">
                        <div class="form-group">
                          <label>Message</label>
                          <input type="text" class="form-control" name="messages" onChange={e => setMessages(e.target.value)} value={messages} />
                        </div>
                      </div>
                    </div>
                    <div class="submit-section">
                      <button type="submit" class="btn btn-primary search-btn submit-btn">Submit</button>
                    </div>
                  </form>
                </div>
              </div>
              <div class="col-md-6">
                <img src="../doccure_assets/img/gulsanji_4.png" class="img-fluid dr-img" alt="" />
              </div>
            </div>
          </div>
        </section>
        <section class="section section-features mb-2" >
          <div class="container">
            <div class="col-md-6">
              <h1 class="ConditionSectionTitle">Why should you choose AceMedicare Transition Care Center?</h1><br />
              <h4 class="ConditionSectionDescription">Your hospital therapy stabilises you, but full recovery necessitates extensive care.</h4><br />
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="row justify-content-end">
                  <div class=" card col-md-4 small-cards align-self-start">
                    <div class="card-body">
                      <span class="text-center">
                        <img alt="cardImage" src="doccure_assets/img/b.png" width="45" height="45" decoding="async" data-nimg="feature" loading="lazy" />
                      </span><br /><br />
                      <h4 class="small-card-title text-left">Transition Care is ranked first.</h4><br />
                      <p>India's First Neurology, Cardiology, Oncology, and Healthy Aging Transition Care Chain.</p>
                    </div>
                  </div>
                  <div class=" card col-md-4 small-cards align-self-start">
                    <div class="card-body">
                      <span class="text-center">
                        <img alt="cardImage" src="doccure_assets/img/c.png" width="45" height="45" />
                      </span><br /><br />
                      <h4 class="small-card-title text-left">Expert Team</h4><br />
                      <p>Multidisciplinary Rehabilitation Team comprised of doctors, nurses, and specialist therapists, among others.</p>
                    </div>
                  </div>
                  <div class=" card col-md-4 small-cards align-self-start">
                    <div class="card-body">
                      <span class="text-center">
                        <img alt="cardImage" src="doccure_assets/img/f.png" width="45" height="45" decoding="async" data-nimg="future" loading="lazy" />
                      </span><br /><br />
                      <h4 class="small-card-title text-left">Holistic Healing</h4><br />
                      <p>Rehabilitation that is all-inclusive and integrative for your physical and mental well-being</p>
                    </div>
                  </div>
                  <div class=" card col-md-4 small-cards align-self-start">
                    <div class="card-body">
                      <span class="text-center">
                        <img alt="cardImage" src="doccure_assets/img/e.png" width="45" height="45" decoding="async" data-nimg="future" loading="lazy" />
                      </span><br /><br />
                      <h4 class="small-card-title text-left">Approach Based on Protocols</h4><br />
                      <p>Professionals with extensive training and expertise in rehab treatment.</p>
                    </div>
                  </div>
                  <div class=" card col-md-4 small-cards align-self-start">
                    <div class="card-body">
                      <span class="text-center">
                        <img alt="cardImage" src="doccure_assets/img/d.png" width="45" height="45" decoding="async" data-nimg="future" loading="lazy" />
                      </span><br /><br />
                      <h4 class="small-card-title text-left">Environment Feels Like Home</h4><br />
                      <p>A really homey ambiance with casual places that add to your comfort.</p>
                    </div>
                  </div>
                  <div class=" card col-md-4 small-cards align-self-start">
                    <div class="card-body">
                      <span class="text-center">
                        <img alt="cardImage" src="doccure_assets/img/a.png" width="45" height="45" decoding="async" data-nimg="future" loading="lazy" />
                      </span><br /><br />
                      <h4 class="small-card-title text-left">Individualized Attention</h4><br />
                      <p>when you're at our facility, we assist and support you the best degree of recovery.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Bottom />
      </div>
    </>
  );
}