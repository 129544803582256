import React from 'react'
import { useState, useRef, useEffect, memo  } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import SearchPacakgeWithCity from '../modules/SearchPacakgeWithCity';
import OurServices from '../modules/OurServices';
import Https from '../../Https';



export default function PharmacyIndex() {

    var OurServicesColor = "#f8f9fa";

    const [searchResultStatus, setSearchResultStatus] = useState(false);
    const [medicines, setMedicines] = useState([]);
    const [inputMedicineName, setInputMedicineName] = useState();
    


    const liveSearchPackage = (event) => {
        const searchData = event.target.value;
        if(searchData==null || searchData==''|| searchData==undefined)
        {
            setSearchResultStatus(false);
        }
        else
        {
            setSearchResultStatus(true);
        }
        setInputMedicineName(searchData);
        Https.get('our-products/'+searchData).then(response=>{
            setMedicines(response.data.data);
            // console.log('search package result')
        });
     }


  return (
    <>
        <div class="page-content header-bg">
            <div class="top-search">
                <div class="container">
                    <div class="search-area">
                        <form action="javascript:void(0)">
                            <div class="list inset">
                                <ul>
                                    <li class="d-flex">
                                        <div class="item-icon">
                                            <i class="search-icon fa fa-search"></i>
                                        </div>
                                        <div class="item-col">
                                            <input type="text" className="form-control" value={inputMedicineName} onChange={liveSearchPackage} required="required"
                                            placeholder="Search for Medicine"  />
                                            {
                                            searchResultStatus ?
                                            <div className='dropdown' style={{position:"absolute","background":"#fff",padding: "5px 5px 5px 5px",width:"490px","zIndex":"1"}}>
                                            {medicines.map((medicine, index)=>(
                                                <div className="dropdown-row" style={{padding: "5px 5px 5px 5px"}}>
                                                    <a href={`/search-product-list/${medicine.slug}`} >{medicine.title}</a>
                                                    
                                                </div>
                                            ))}
                                            </div>
                                            :
                                            null
                                            }
                                        </div>
                                    </li>
                                </ul>
                                <button type="submit" class="button button-large button-primary btn"><i className="fas fa-search"></i> <span>Search</span></button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="page-content">
                <div class="container"> 
                    <div class="patient-details">
                        <div class="inner-patient-detaials">
                            <div class="patient-col">
                                <div class="inner-patient-col">
                                    <div class="progress-col">
                                        <div class="c100 p80 small">
                                            <span class="pat-img"><img src="mobile_assets/img/dentist-1.svg" alt="dentist" /></span>
                                            <div class="slice">
                                                <div class="bar"></div>
                                                <div class="fill"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <h6>Off UpTo</h6>
                                    <h4>20%</h4>
                                    <span class="date">Order Medicines</span>
                                </div>
                            </div>
                            <div class="patient-col">
                                <a href="/lab-tests">
                                    <div class="inner-patient-col">
                                        <div class="progress-col">
                                            <div class="c100 p40 small">
                                                <span class="pat-img"><img src="mobile_assets/img/patient.svg" alt="patient" /></span>
                                                <div class="slice">
                                                    <div class="bar"></div>
                                                    <div class="fill"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <h6>Off UpTo</h6>
                                        <h4>70%</h4>
                                        <span class="date">Book Lab Test</span>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section class="section section-features">
                <div class="container-fluid">
                    <div class="container inv-section">
                        <div class="top-inv-col">
                            
                        </div>
                        <div class="row">
                            <div class="col-4">
                                <h5>
                                    <strong><i class="fab fa-whatsapp"></i></strong>&nbsp;
                                    WhatsApp
                                </h5>
                            </div>
                            <div class="col-4">
                                <h5>
                                    <strong><i class="fas fa-file-prescription"></i></strong>&nbsp;
                                    Prescription
                                </h5>
                            </div>
                            <div class="col-4">
                                <h5>
                                    <a href="tel:+8603002000">
                                    <strong><i class="fas fa-phone-alt"></i></strong>&nbsp;
                                    Call
                                    </a>
                                </h5>
                            </div>
                        </div>
                    </div>
                    <div class="appointments-details">
                        <div class="inner-appointment">
                            <div class="appointment-col">
                                <a href="/my-appointment" class="btn">My <br />Appointments</a>
                            </div>
                            <div class="appointment-col">
                                <a href="/appointment/step-first" class="btn">Book <br />Appointments</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <OurServices data={OurServicesColor} />   
           
        </div>  
    </>
  )
}
