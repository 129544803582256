import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import SideBar from './SideBar'
import Https from "../../Https";
import Header from '../includes/Header';
import Footer from '../includes/Footer';
import AlertDanger from '../ReturnResponse/AlertDanger';
import AlertSuccess from '../ReturnResponse/AlertSuccess';
import AlertWarning from '../ReturnResponse/AlertWarning';

export default function ProfileSetting() {
    /////////// get user details
    // const user = '';//JSON.parse(sessionStorage.getItem('user'));
    // const userDetail = '';//JSON.parse(sessionStorage.getItem('user'));

    // const token = '';//sessionStorage.getItem('token');

    const[successAlertMsg, setSuccessAlertMsg] = useState();
    const[warningAlertMsg, setWarningAlertMsg] = useState();
    const[dangerAlertMsg, setDangerAlertMsg] = useState();

    const userDetails = localStorage.getItem('user_info');
    const jsonUserDetails = JSON.parse(userDetails);
    const userToken = jsonUserDetails.token;
  
    var userid = jsonUserDetails.user_info.id;
    const userMobile = jsonUserDetails.user_info.mobile;

    // variable & function for image preview
    const[image, setImage] = useState('');

    //////////  variable & function for get user Details
    // const[userDetail, setUserDetail] = useState([]);
    const[user_details, set_user_details] = useState(); 

    //////////  use effect for fetch city list
    useEffect(() =>{
        fetUserDetail();
    },[]);
    //////////  call api for get all cities
    const fetUserDetail = () =>{
        Https.get('get-user-detail-by-id/'+userid).then(response=>{
            
            // setUserDetail(response.data.data);
            set_user_details(response.data.data);
            setFirstName(response.data.data.name)
            setLastName(response.data.data.last_name)
            setMobile(response.data.data.mobile)
            setEmail(response.data.data.email)
            setDob(response.data.data.dob)
            setBloodGroup(response.data.data.blood_group)
            setAddress(response.data.data.address)
            setCityID(response.data.data.city_id)
            setInputCityName(response.data.data.city_name)
            setCityInputId(response.data.data.city_id)
            setIndiaCityInput(response.data.data.city_id)
            setStateID(response.data.data.state_id)
            setCountryID(response.data.data.name)
            setPinCode(response.data.data.pin_code)
            setProfileImage(response.data.data.profile_image)
            set_profile_file(response.data.data.profile_image)
        })
    }

    // const userDetail = JSON.parse(user);



    //////////  variable & function for get cities
    const [cities, setCities] = useState([]);
    //////////  use effect for fetch city list
    useEffect(() =>{
        fetAllCities();
    },[]);
    //////////  call api for get all cities
    const fetAllCities = () =>{
        Https.get('get_all_cities').then(response=>{
            setCities(response.data.data);
        })
    }
    //////////  variable & function for get states
    const [states, setStates] = useState([]);
    //////////  use effect for fetch state list
    useEffect(() =>{
        fetAllStates();
    },[]);
    //////////  call api for get all states
    const fetAllStates = () =>{
        Https.get('get-states').then(response=>{
            setStates(response.data.data);
        })
    }
    //////////  variable & function for get countries
    const [countries, setCountries] = useState([]);
    //////////  use effect for fetch state list
    useEffect(() =>{
        fetAllCountries();
    },[]);
    //////////  call api for get all states
    const fetAllCountries = () =>{
        Https.get('get_all_countries').then(response=>{
            setCountries(response.data.data);
        })
    }

    // // use effect for fetch image preview
    // useEffect(() =>{
    //     imagePreview();
    // },[]);
    // function for image preview
    // imagePreview = (e) =>{
    //     setImage = e.target.files[0];
    // }
    ////////////  varable & function for update profile
    const [firstName, setFirstName] = useState(jsonUserDetails.user_info.name);
    const [last_name, setLastName] = useState(jsonUserDetails.user_info.last_name);
    const [mobile, setMobile] = useState(jsonUserDetails.user_info.mobile);
    const [email, setEmail] = useState(jsonUserDetails.user_info.email);
    const [dob, setDob] = useState(jsonUserDetails.user_info.dob);
    const [bloodGroup, setBloodGroup] = useState(jsonUserDetails.user_info.blood_group);
    const [address, setAddress] = useState(jsonUserDetails.user_info.address);
    const [cityID, setCityID] = useState(jsonUserDetails.user_info.city_id);
    const [stateID, setStateID] = useState(jsonUserDetails.user_info.state_id);
    const [countryID, setCountryID] = useState(jsonUserDetails.user_info.country_id);
    const [pincode, setPinCode] = useState(jsonUserDetails.user_info.pin_code);
    const [profileImage, setProfileImage] = useState("");
    const [profile_file, set_profile_file] = useState([]);
    const [image_status, set_image_status] = useState(false);
    
    const changeImgHandler = (event) => {
        setProfileImage(event.target.files[0]); 
        set_profile_file(URL.createObjectURL(event.target.files[0]));
        set_image_status(true);
	};
    // const packageid = packageDetails.id;
    const enquiryType = 'Package';
    const updateUserProfile = (e) =>{
        // const formData = {
        // 'firstName':firstName,
        // 'last_name':last_name,
        // 'mobile':mobile,
        // 'email':email,
        // 'dob':dob,
        // 'bloodGroup':bloodGroup,
        // 'address':address,
        // 'cityID':cityID,
        // 'stateID':stateID,
        // 'countryID':countryID,
        // 'pincode':pincode,
        // 'profileImage':profileImage,
        // 'userID':jsonUserDetails.user_info.id,
        // }
        const fData = new FormData();
        fData.append("profileImage",profileImage);
        fData.append("firstName",firstName);
        fData.append("last_name",last_name);
        fData.append("mobile",mobile);
        fData.append("email",email);
        fData.append("dob",dob);
        fData.append("bloodGroup",bloodGroup);
        fData.append("address",address);
        fData.append("cityID",cityID);
        fData.append("stateID",stateID);
        fData.append("countryID",countryID);
        fData.append("pincode",pincode);
        fData.append("profileImage",profileImage);
        fData.append("userID",jsonUserDetails.user_info.id);
        e.preventDefault();
       
        Https.post('update-user-profile/',fData).then(response =>{
            setSuccessAlertMsg("Profile Update Successfully.");
            setTimeout(() =>{
                setWarningAlertMsg('');
                window.location.reload();
            },5000);
        })

    }

    const get_location_with_pin = (event) => {
        const input_pin_code = event.target.value;
        setPinCode(input_pin_code);
        Https.post('get-location-with-pin-code',{pin_code: input_pin_code}).then(response=>{
            set_city_district(response.data.PostOfficeName)
            set_state(response.data.State)
        })
    }
    const [city_district, set_city_district] = useState();
    const [state, set_state] = useState();

    const [searchResultStatus, setSearchResultStatus] = useState(false);
    const [searchCities, setSearchCities] = useState([]);
    const [inputCityName, setInputCityName] = useState(jsonUserDetails.user_info.city_name);
    const [cityInputId, setCityInputId] = useState(jsonUserDetails.user_info.city_id);
    const [indiaCityInput, setIndiaCityInput] = useState(jsonUserDetails.user_info.city_name);


    const liveSearchCity = (event) => {
        var searchData = event.target.value;
        
        if(searchData==null || searchData==''|| searchData==undefined)
        {
            setSearchResultStatus(false);
        }
        else
        {
            setSearchResultStatus(true);
        }
        setInputCityName(searchData);
        Https.get('search-city-with-city-name/'+inputCityName).then(response=>{
            setSearchCities(response.data.data);
        });
     }

     const searchCityResult = (event) => {
        const city_id = event.target.getAttribute('data-city_id');
        const city_name = event.target.getAttribute('data-city_name');
        setIndiaCityInput(city_id);
        setCityID(city_id);
        set_city_district(city_id);
        setCityInputId(city_id);
        setInputCityName(city_name);
        setSearchResultStatus(false);
     }
    

  return (
    <>
    <div className="main-wrapper">
        <div className="page-content change-password-col register-col">
            <div className="list no-hairlines custom-form">
                <div class="tab-content">
                    <div class="tab-pane active" id="basic-info">
                        <div class="panel panel-default">
                            <div id="collapseOne" class="panel-collapse collapse in">
                                <div class="panel-body">
                                    <div class="setting-widget">
                                        <div class="list no-hairlines-md">
                                            <div class="widget-title">
                                                <h5>Basic Information</h5>
                                            </div>
                                            <form onSubmit={updateUserProfile} enctype='multipart/form-data'>
                                            <div class="file-upload">
                                                <a href="javascript:void(0)" class="file-upload-img">
                                                
                                                {((jsonUserDetails.user_info.profile_image==null) || (image_status==true) ?
                                                <img src={profile_file} alt={`${firstName} ${last_name} User Image`} class="img-fluid img-circle" style={{width:"100px", height:"85px"}} />
                                                :
                                                <img src={'https://acemedicare-admin.hospicares.in/public/user/profileImage/'+profileImage} alt={`${firstName} ${last_name} User Image`} class="img-fluid img-circle" style={{width:"100px", height:"85px"}} />
                                                )}
                                                <span class="cam-icon"><img src="mobile_assets/img/placeholder-small.svg" alt="" /></span>
                                                </a>
                                            </div>
                                            
                                            <div class="row form-row">
                                                <div class="col-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="change-avatar">
                                                            <div class="profile-img">
                                                            {/*
                                                                <img src="mobile_assets/img/doctors/doctor-thumb-02.jpg" class="img-fluid img-circle" width="85" alt="User Image" />

                                                                <img src={'http://127.0.0.1:8000/user/profileImage/'+jsonUserDetails.user_info.profile_image} alt={firstName+' '+last_name} />
                                                                */}
                                                            </div>
                                                            <div class="upload-img">
                                                                <div class="change-photo-btn">
                                                                    <span><i class="fa fa-upload"></i> Upload Photo</span>
                                                                    <input type="file" className='upload' onChange={changeImgHandler} />
                                                                </div>
                                                                <small class="form-text text-muted">Allowed JPG, GIF or PNG. Max
                                                                    size of 2MB</small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6">
                                                    <div class="form-group">
                                                        <label for="firstName">First Name</label>
                                                        <input class="form-control" name="firstName" id="firstName" value={firstName} onChange={e => setFirstName(e.target.value)} type="text" /> 
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6">
                                                    <div class="form-group">
                                                        <label for="last_name">Last Name</label>
                                                        <input class="form-control" name="last_name" id="last_name" value={last_name} onChange={e => setLastName(e.target.value)} type="text" /> 
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6">
                                                    <div class="form-group">
                                                        <label for="dob">Date of Birth</label>
                                                        <div class="cal-icon">
                                                            <input type="date" name="dob" id="dob" class="form-control"
                                                                value={dob} onChange={e =>setDob(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6">
                                                    <div class="form-group">
                                                        <label for="bloodGroup">Blood Group</label>
                                                        <select class="form-select form-control" onChange={e =>setBloodGroup(e.target.value)}>
                                                            <option value="">Select Blood Group</option>
                                                            <option value="A-" selected={bloodGroup == "A-"}>A-</option>
                                                            <option value="A+" selected={bloodGroup == "A+"}>A+</option>
                                                            <option value="B-" selected={bloodGroup == "B-"}>B-</option>
                                                            <option value="B+" selected={bloodGroup == "B+"}>B+</option>
                                                            <option value="AB-" selected={bloodGroup == "AB-"}>AB-</option>
                                                            <option value="AB+" selected={bloodGroup == "AB+"}>AB+</option>
                                                            <option value="O-" selected={bloodGroup == "O-"}>O-</option>
                                                            <option value="O+" selected={bloodGroup == "O+"}>O+</option>
                                                        </select>
                                                        {/*
                                                        <input type="text" name="bloodGroup" id="bloodGroup" class="form-control"
                                                                value={bloodGroup} onChange={e =>setBloodGroup(e.target.value)} />
                                                            */}
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6">
                                                    <div class="form-group">
                                                        <label for="email">Email ID</label>
                                                        <input type="email" name="email" id="email" class="form-control" value={email} onChange={e =>setEmail(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6">
                                                    <div class="form-group">
                                                        <label>Mobile</label>
                                                        <input class="form-control allow_only_number" name="mobile" readOnly id="mobile" value={mobile} onChange={e => setMobile(e.target.value)} type="number" maxLength={10} />
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="form-group">
                                                        <label for="address">Address</label>
                                                        <input type="text" name="address" id="address" class="form-control" value={address} onChange={e => setAddress(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6">
                                                    <div class="form-group">
                                                        <label for="pincode">Zip Code</label>
                                                        <input type="text" name="pincode" id="pincode" class="form-control allow_only_number" value={pincode} onChange={get_location_with_pin} />
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6">
                                                    <div class="form-group">
                                                        <label for="cityID">City</label>
                                                        {/*
                                                        <select className="form-control" name="cityID" id="cityID" placeholder="Selecte City" onChange={e=>setCityID(e.target.value)}>
                                                            <option value="">~~ Select Cities ~~</option>
                                                            {cities.map((cities,index)=>(
                                                            <option value={cities.id} selected={cityID == cities.id}>{cities.name}</option>
                                                            ))}
                                                        </select>
                                                        */}
                                                        <input type="text" className="form-control" value={inputCityName} onChange={liveSearchCity} required="required" placeholder="Search City" />
                                                        {
                                                        searchResultStatus ?
                                                        <div className='dropdown' style={{position:"absolute","background":"#fff",padding: "5px 5px 5px 5px",width:"380px","zIndex":"1",height:"200px",overflowX: "hidden",overflowY: "auto"}}>
                                                        {searchCities.map((searchCitie, index)=>(
                                                            <div className="dropdown-row" style={{padding: "5px 5px 5px 5px"}}>
                                                                <a href='javascript:void(0)' onClick={searchCityResult} data-city_name={searchCitie.name} data-city_id={searchCitie.id} >{searchCitie.name}</a>
                                                            </div>
                                                        ))}
                                                        </div>
                                                        :
                                                        null
                                                        }
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6">
                                                    <div class="form-group">
                                                        <label>State</label>
                                                        <select className="form-control" name="stateID" id="stateID" placeholder="Select State" onChange={e=>setStateID(e.target.value)}>
                                                            <option value="">~~ Select State ~~</option>
                                                            {states.map((states,index)=>(
                                                            <option value={states.id} selected={stateID == states.id}>{states.name}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                                
                                                <div class="col-12 col-md-6">
                                                    <div class="form-group">
                                                        <label>Country</label>
                                                        <select className="form-control" name="countryID" id="countryID" onChange={e=>setCountryID(e.target.value)}>
                                                            <option value="India">India</option>
                                                            {/*
                                                            {countries.map((countries,index)=>(
                                                            <option value={countries.id} selected={countryID == countries.id}>{countries.name}</option>
                                                            ))}
                                                            */}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="submit-section">
                                                <button type="submit" class="btn btn-primary submit-btn">Save Changes</button>
                                            </div>
                                            </form>
                                                
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}
