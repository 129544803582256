import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import SideBar from './SideBar'
import Https from "../../Https";
import Header from '../includes/Header';
import Footer from '../includes/Footer';

export default function ChangePassword() {
    /////////// get user details
    // const user = '';//JSON.parse(sessionStorage.getItem('user'));
    // const userDetail = '';//JSON.parse(sessionStorage.getItem('user'));

    // const token = '';//sessionStorage.getItem('token');

    const userDetails = localStorage.getItem('user_info');
    const jsonUserDetails = JSON.parse(userDetails);
    const userToken = jsonUserDetails.token;
  
    var userid = jsonUserDetails.user_info.id;
    const userMobile = jsonUserDetails.user_info.mobile;

    // variable & function for image preview
    const[image, setImage] = useState('');

    //////////  variable & function for get user Details
    // const[userDetail, setUserDetail] = useState([]);
    const[testUserDtl, setTestUserDtl] = useState("");  
    //////////  use effect for fetch city list
    useEffect(() =>{
        fetUserDetail();
    },[]);
    //////////  call api for get all cities
    const fetUserDetail = () =>{
        Https.get('get-user-detail-by-id/'+userid).then(response=>{
            // setUserDetail(response.data.data);
            setTestUserDtl(response.data.data);
            console.log(testUserDtl);
        })
    }

    // const userDetail = JSON.parse(user);



    //////////  variable & function for get cities
    const [cities, setCities] = useState([]);
    //////////  use effect for fetch city list
    useEffect(() =>{
        fetAllCities();
    },[]);
    //////////  call api for get all cities
    const fetAllCities = () =>{
        Https.get('get_all_cities').then(response=>{
            setCities(response.data.data);
        })
    }
    //////////  variable & function for get states
    const [states, setStates] = useState([]);
    //////////  use effect for fetch state list
    useEffect(() =>{
        fetAllStates();
    },[]);
    //////////  call api for get all states
    const fetAllStates = () =>{
        Https.get('get-states').then(response=>{
            setStates(response.data.data);
        })
    }
    //////////  variable & function for get countries
    const [countries, setCountries] = useState([]);
    //////////  use effect for fetch state list
    useEffect(() =>{
        fetAllCountries();
    },[]);
    //////////  call api for get all states
    const fetAllCountries = () =>{
        Https.get('get_all_countries').then(response=>{
            setCountries(response.data.data);
        })
    }

    // // use effect for fetch image preview
    // useEffect(() =>{
    //     imagePreview();
    // },[]);
    // function for image preview
    // imagePreview = (e) =>{
    //     setImage = e.target.files[0];
    // }
    ////////////  varable & function for update profile
    const [firstName, setFirstName] = useState(jsonUserDetails.user_info.name);
    const [last_name, setLastName] = useState(jsonUserDetails.user_info.last_name);
    const [old_password, set_old_password] = useState();
    const [new_password, set_new_password] = useState();
    const [confirm_password, set_confirm_password] = useState();
    const [profileImage, setProfileImage] = useState();
   
    const changeImgHandler = (event) => {
        
        //alert(event.target.files[0]);
		setProfileImage(event.target.files[0]); 
	};
    const formData = new FormData()
    formData.append('old_password',old_password);
    formData.append('new_password',new_password);
    formData.append('confirm_password',confirm_password);
    formData.append('userID',jsonUserDetails.user_info.id);
    
    // const packageid = packageDetails.id;
    const enquiryType = 'Package';
    const updateUserProfile = (e) =>{
        e.preventDefault();
        // Https.post('update-user-profile/'+token,{firstName:firstName, last_name:last_name,mobile:mobile,email:email, dob:dob,bloodGroup:bloodGroup, address:address, cityID:cityID,countryID:countryID, pincode:pincode,profileImage:profileImage}).then(response =>{
        Https.post('update-user-profile/'+userToken,formData).then(response =>{
            // console.log(response);
        })

    }


  return (
    <>
    <div className="main-wrapper">
        <div className="page-content change-password-col register-col">
            <div className="list no-hairlines custom-form">
                <div class="tab-content">
                    <div class="tab-pane active" id="basic-info">
                        <div class="panel panel-default">
                            <div id="collapseOne" class="panel-collapse collapse in">
                                <div class="panel-body">
                                    <div class="setting-widget">
                                        <div class="list no-hairlines-md">
                                            <div class="widget-title">
                                                <h5>Change Password</h5>
                                            </div>
                                            <form onSubmit={updateUserProfile} enctype='multipart/form-data'>
                                            <div class="file-upload">
                                                <a href="javascript:void(0)" class="file-upload-img">
                                                {(jsonUserDetails.user_info.profile_image==null ?
                                                <img src="mobile_assets/img/doctors/doctor-thumb-02.jpg" class="img-fluid img-circle" width="85" alt="User Image" />
                                                :
                                                <img src={'http://127.0.0.1:8000/user/profileImage/'+jsonUserDetails.user_info.profile_image} alt={firstName+' '+last_name} />
                                                )}
                                                <span class="cam-icon"><img src="mobile_assets/img/placeholder-small.svg" alt="" /></span>
                                                </a>
                                            </div>
                                            
                                            <div class="row form-row">
                                                <div class="col-12 col-md-6">
                                                    <div class="form-group">
                                                        <label for="firstName">Old Password</label>
                                                        <input class="form-control" name="old_password" id="old_password" value={old_password} onChange={e => set_old_password(e.target.value)} type="password" /> 
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6">
                                                    <div class="form-group">
                                                        <label for="last_name">New Password</label>
                                                        <input class="form-control" name="last_name" id="last_name" value={new_password} onChange={e => set_new_password(e.target.value)} type="password" /> 
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6">
                                                    <div class="form-group">
                                                        <label for="last_name">Confirm Password</label>
                                                        <input class="form-control" name="last_name" id="last_name" value={confirm_password} onChange={e => set_confirm_password(e.target.value)} type="password" /> 
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="submit-section">
                                                <button type="submit" class="btn btn-primary submit-btn"> Changes Password</button>
                                            </div>
                                            </form>
                                            <br></br>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}
