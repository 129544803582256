import React from 'react'
import { useState, useEffect,useRef } from 'react';
import { Link,useNavigate,useLocation,useParams } from 'react-router-dom';
import Https from '../../Https';
import AlertSuccess from '../ReturnResponse/AlertSuccess';
import AlertWarning from '../ReturnResponse/AlertWarning';
import AlertDanger from '../ReturnResponse/AlertDanger';

function MyHistory() {
    const { pathname } = useLocation();
    useEffect(() => {
	  window.scrollTo(0, 0);
	}, [pathname]);
    const {appt_no} = useParams();
    
    const navigate = useNavigate();
    const[successAlertMsg, setSuccessAlertMsg] = useState();
    const[warningAlertMsg, setWarningAlertMsg] = useState();
    const[dangerAlertMsg, setDangerAlertMsg] = useState();

    const userDetails = localStorage.getItem('user_info');
    const jsonUserDetails = JSON.parse(userDetails);

    const [appointments, setAppointments] = useState([]);
    ////////// use effect for fetch active department list
    useEffect(()=>{
        fetchAllAppointment();
    },[]);
    /////////   call api for get active department list                
    const fetchAllAppointment = () =>{
        Https.post('/get_appointments_by_user_id',{userId:jsonUserDetails.user_info.id}).then((response) =>{
            console.log('myapt');
            console.log(response);
            setAppointments(response.data);
        });  
    }    

    
  return (
    <>
    <div class="main-wrapper">
        <div class="tab-pane" id="prescriptions">
            <div class="panel panel-default">
                <div id="collapseTwo" class="panel-collapse collapse">
                    <div class="panel-body">
                        <div class="patient-appointments-col">
                            {appointments.map((appointment, index)=>(
                            <div class="patient-widget">
                                <div class="patient-top-details">
                                    <div>
                                        <span class="invoice-id">{appointment.appointment_no}</span>
                                    </div>
                                    <div>
                                        <span class="date-col">{appointment.appointment_date}</span>
                                    </div>
                                </div>
                                <div class="invoice-widget">
                                    <div class="pat-info-left">
                                        <div class="pat-info-cont">
                                            <h4 class="pat-name"><a href="javascript:void(0)">{appointment.department_name}</a></h4>
                                            <div class="patient-details-col">
                                                <span class="">{appointment.appointment_mode}</span>
                                            </div>
                                            <div class="patient-details-col">
                                                <span class="">{appointment.appointment_time}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="pat-info-right">
                                        <div class="pat-info-cont">
                                            <div class="status-col no-shadow">
                                                <div class="status-btn">
                                                    <a href='javascript:void(0)' data-metting_url={appointment.zoom_join_url} class="btn view-eye check_link" onClick="join_metting()">
                                                        <i><img src="assets/img/icon-awesome-eye.svg" alt=""/></i>Call
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default MyHistory