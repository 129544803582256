import React, { useEffect, useState } from "react";
import Https from "../../Https";
import { useNavigate, useLocation } from 'react-router-dom';

export default function SearchPacakgeWithCity(props) {
    

    const propsCitySlug = props.citiesSlug;
    console.log("props slug");
    console.log(props);
    
    // const router = useRouter();
    const navigate = useNavigate();
    const [poplourPackageMasters, setPoplourPackageMasters] = useState([]);
    // const [importantPackageMasters, setImportantPackageMasters] = useState([]);
    const [imagePaths, setimagePaths] = useState([]);
    const [cities, setCities] = useState([]);
    const [popularPackages, setPopularPackages] = useState([]);
    const [treatPackages, setTreatPackages] = useState([]);
    const [packageImgPath, setPackageImgPath] = useState([]);


    const [searchData, setSearchData] = useState({
        stateID:'',
        packageID: '',
    });
    useEffect(()=>{
        fetchPoplourpackageMasters();
    },[]);
    // useEffect(()=>{
    //     fetchImportantpackageMasters();
    // },[]);
    useEffect(()=>{
        fetchPopularPackages();
    },[]);
    //////////  use effect for fetch package image path
   useEffect(()=>{
        fetchPackageImagePath();
    },[]);

    useEffect(()=>{
        fetchImagePath();
    },[]);

    const fetchPoplourpackageMasters = () =>{
        Https.get('/poplour-package-master').then(response=>{
            setPoplourPackageMasters(response.data.data);
        });
    }
    // const fetchImportantpackageMasters = () =>{
    //     Https.get('/important-package-master').then(response=>{
    //         setImportantPackageMasters(response.data.data);
    //     });
    // }
    const fetchPopularPackages = () =>{
        Https.get('/get-popular-packages').then(response=>{
            setPopularPackages(response.data.data);
        });
    }
    //////////  call api for get image path
    const fetchPackageImagePath = () =>{
        Https.get('/package-image-path').then(response=>{
        setPackageImgPath(response.data);
        });
    }
    const fetchImagePath = () =>{
        Https.get('/package-master-image-path').then(response=>{
            setimagePaths(response.data);
        });
    }
    // ////////// use effect for fetch active package list
    // useEffect(()=>{
    //     fetchAllPackages();
    // },[]);
    // /////////   call api for get active package list
    // const fetchAllPackages = () =>{
    //     Https.get('all-active-packages').then(response=>{
    //         setTreatPackages(response.data.data);
    //     });
    // }
    //////////  use effect for fetch state list
    useEffect(() =>{
        fetAllCities();
    },[]);
    //////////  call api for get all states
    const fetAllCities = () =>{
        Https.get('get-hospital-cities').then(response=>{
            setCities(response.data.data);
        })
    }
    const [cityInput, setCityInput] = useState(props.city_id);
    const [packageInput, setPackageInput] = useState(props.package_slug);

    const searchPackage = (e) =>{
        
        Https.get('search-hospital-packages/'+cityInput+'/'+packageInput).then(response =>{
            console.log(response);
            if(cityInput==undefined || packageInput==undefined)
            {

            }
            else
            {
                window.location.assign('/search-package-results/'+cityInput+'/'+packageInput);
                // navigate('/search-package-results/'+cityInput+'/'+packageInput);
            }

        });
    }
    
    const selectCity = (e) =>{
        const selectedCityID = e.target.value;
        setCityInput(selectedCityID);
        Https.get('find-package-by-city-id/'+selectedCityID).then(response =>{
        //    setTreatPackages(response.data.data);
        });
    }



    const [searchResultStatus, setSearchResultStatus] = useState(false);

    const [inputPackageName, setInputPackageName] = useState(props.package_slug);


    const liveSearchPackage = (event) => {
        const searchData = event.target.value;
        if(searchData==null || searchData==''|| searchData==undefined)
        {
            setSearchResultStatus(false);
        }
        else
        {
            setSearchResultStatus(true);
        }
        setInputPackageName(searchData);
        Https.get('search-active-packages/'+inputPackageName).then(response=>{
            setTreatPackages(response.data.data);
            // console.log('search package result')
        });
     }

     const searchPackageResult = (event) => {
        const package_id = event.target.getAttribute('data-package_id');
        const package_name = event.target.getAttribute('data-package_name');
        setPackageInput(package_id);
        setInputPackageName(package_name);
        setSearchResultStatus(false);
     }
  return (
    <>
        <form action="javascript:void(0)">
            <div class="list inset">
                <ul>
                    <li class="d-flex">
                        <div class="item-icon">
                            <i class="search-icon fas fa-map-marker-alt"></i>
                        </div>
                        <div class="item-col">
                            {/* <select className="form-control" name="city_id" id="city_id" placeholder="Search Location" required="required" onChange={e=>setCityInput(e.target.value)}> */}
                            <select className="form-control" name="city_id" id="city_id" required="required" onChange={(e)=>selectCity(e)}>
                                <option value=""> Select Cities </option>
                                {cities.map((cities,index)=>(
                                <option value={cities.citySlug} selected={cities.citySlug == props.city_id}>{cities.cityName}</option>
                                ))}
                            </select>
                            {/* <input type="text" placeholder="Search Location (Ex : Chennai, etc)" /> */}
                        </div>
                    </li>
                    <li class="d-flex">
                        <div class="item-icon">
                            <i class="search-icon fas fa-building"></i>
                        </div>
                        <div class="item-col">
                            {/*
                            <select className="form-control" name="package_id" id="package_id" required="required" onChange={e=>setPackageInput(e.target.value)} placeholder="Search Doctors, Clinics, Hospitals, Diseases Etc">
                                <option value=""> Select Packages </option>
                                {treatPackages.map((treatPackages, index)=>(
                                    <option value={treatPackages.package_id}>{treatPackages.package_name}</option>
                                ))}
                            </select>
                            */}
                            <input type="text" className="form-control" value={inputPackageName} onChange={liveSearchPackage} required="required"
                            placeholder="Search Treatment Packages"  />
                            {
                            searchResultStatus ?
                            <div className='dropdown' style={{position:"absolute","background":"#fff",padding: "5px 5px 5px 5px",width:"490px","zIndex":"1"}}>
                            {treatPackages.map((treatPackage, index)=>(
                                <div className="dropdown-row" style={{padding: "5px 5px 5px 5px"}}>
                                    <a href='javascript:void(0)' onClick={searchPackageResult} data-package_name={treatPackage.name} data-package_id={treatPackage.slug} >{treatPackage.name}</a>
                                    
                                </div>
                            ))}
                            </div>
                            :
                            null
                            }
                        </div>
                    </li>
                </ul>
                <button type="submit" onClick={searchPackage} class="button button-large button-primary btn"><i className="fas fa-search"></i> <span>Search</span></button>
                {/* <a href="search-doctor.html" class="button button-large button-primary btn">Search
                    Now</a> */}
            </div>
        </form>
        
    </>
    )
}