import React from 'react'
import { useState, useEffect } from 'react';
import { Link,useNavigate,useLocation,useParams } from 'react-router-dom';
import Https from '../../Https';

function AppointmentDetails() {

    const { pathname } = useLocation();
    useEffect(() => {
	  window.scrollTo(0, 0);
	}, [pathname]);
    const {appt_no} = useParams();
    const navigate = useNavigate();
    const[successAlertMsg, setSuccessAlertMsg] = useState();
    const[warningAlertMsg, setWarningAlertMsg] = useState();
    const[dangerAlertMsg, setDangerAlertMsg] = useState();

    const userDetails = localStorage.getItem('user_info');
    const jsonUserDetails = JSON.parse(userDetails);

    const [appointment, setAppointment] = useState([]);
    ////////// use effect for fetch active department list
    useEffect(()=>{
        fetchAllAppointment();
    },[]);
    /////////   call api for get active department list                
    const fetchAllAppointment = () =>{
        Https.post('/get_appointment_row',{appointment_no:appt_no}).then((response) =>{
            console.log('appt details')
            console.log(response);
            setAppointment(response.data);
        });  
    }
  return (
    <>
    <div className="main-wrapper" style={{backgroundColor:"#fff"}}>
        <div className="page-content change-password-col register-col">
            <div className="list no-hairlines custom-form">
                <div class="page-content">
                    <div class="container inv-section">
                        <div class="top-inv-col">
                            <div class="inv-logo">
                            <img src={window.location.origin + '/doccure_assets/img/logo.png'} className="img-fluid" alt="Ace Medicare" style={{maxWidth: "155px"}} />
                            </div>
                            <div class="order-details">
                                <p>Appt No: <span>{appointment.appointment_no}</span></p>
                                <p>Date: <span>{appointment.created_at}</span></p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <h5>Invoice From</h5>
                                <ul class="inv-receiver">
                                    <li>{appointment.name}<br /> +91-{appointment.mobile},<br /> {appointment.email}</li>
                                </ul>
                                <h5>Invoice To</h5>
                                <ul class="inv-receiver">
                                    <li>Ace Medicare<br />  +91-860 300 2000,<br /> support@acemedicare.in</li>
                                </ul>
                            </div>
                            <div class="col-6">
                                <div class="payment-method">
                                    <h5>Payment Status</h5>
                                    <ul>
                                        <li>Fee: {appointment.amount}.00<br /> Payment Status: {appointment.payment_status}<br /> Booking Status: Success</li>
                                    </ul>
                                </div>
                                <ul class="inv-receiver">
                                    <li>
                                    {(appointment.status==3 || appointment.status==4) ?
                                        <div>
                                            Dr. Prescription
                                            <a href={appointment.prescriptionImage} download={appointment.doctor_prescription}>
                                                <i class="fa fa-download" aria-hidden="true" style={{float:"right"}}></i>&nbsp;&nbsp;&nbsp;
                                            </a>
                                        </div>
                                        :''
                                    }
                                    </li>
                                    <li>
                                    {appointment.follow_up_doctor_prescription ?
                                        <div>
                                            FollowUp Prescription
                                            <a href={appointment.followUpImage} download={appointment.follow_up_prescription}>
                                                <i class="fa fa-download" aria-hidden="true" style={{float:"right"}}></i>&nbsp;&nbsp;&nbsp;
                                            </a>
                                        </div>
                                        :''
                                    }
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <table class="inv-table" style={{width:"100%"}}>
                            <tbody>
                                <tr>
                                    <th class="text-align-left">Description</th>
                                    <th class="text-align-center">VAT</th>
                                    <th class="text-align-center">Total</th>
                                </tr>
                                <tr>
                                    <td>{appointment.symptoms}</td>
                                    <td class="text-align-center">0</td>
                                    <td class="text-align-center">{appointment.amount}.00</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colspan="2"><b>Subtotal</b></td>
                                    <td colspan="1">{appointment.amount}.00</td>
                                </tr>
                            </tfoot>
                        </table>
                        <div class="invoice-info">
                            <h5>All Health Problems? One Solution "Ace Medicare"</h5>
                            <p class="text-muted">Discover the best doctors, clinic & hospital in city nearest to you.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default AppointmentDetails