import React from 'react'

function AlertSuccess(props) {
    // alert(props.alert);
  return (
    <>
        {props.alert==null || props.alert=='' ? '':
        <div className="alert alert-success">
            <strong>{props.alert}</strong>
        </div>
        }
    </>
  )
}

export default AlertSuccess