import React, { useEffect, useState } from "react";
import Https from "../Https";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import Bottom from "./modules/Bottom";
import Header from "./includes/Header";
import Footer from "./includes/Footer";


export default function InternationalPatient() {
	// function for load comonent from top
	const { pathname } = useLocation();
	const modify_pathName = pathname.replace('/', '');
	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	const [firstName, setFirstName] = useState("");
	const [last_name, setLastName] = useState("");
	const [mobile, setMobile] = useState("");
	const [email, setEmail] = useState("");
	const [passport_no, setPassportNo] = useState("");
	const [age, setAge] = useState("");
	const [gender, setGender] = useState("");
	const [nationality, setNationality] = useState("");
	const [messages, setMessages] = useState("");
	const enquiryType = 'International Patient';

	const submitInquiryforContactOther = () => {
		if (firstName == '' || mobile == '') {
			alert('Name or Mobile Number field is required');
		}
		else {
			Https.post('store-contact-inquiry', { first_name: firstName, last_name: last_name, mobile: mobile, email: email, passport_no: passport_no, age: age, gender: gender, nationality: nationality, messages: messages, enquiryType: enquiryType }).then(response => {
				console.log(response.status);
				if (response.status == 200) {
					setFirstName("");
					setLastName("");
					setMobile("");
					setEmail("")
					setPassportNo("");
					setAge("");
					setGender("");
					setNationality("")
					setMessages("");
					alert('Your request has been registered successfully. One of our agent will get in touch soon. Thank You!');

				}
				else {
					setFirstName("");
					setLastName("");
					setMobile("");
					setEmail("")
					setPassportNo("");
					setAge("");
					setGender("");
					setNationality("")
					setMessages("");
				}
			})
		}
	}
	return (
		<>
			<div className="main-wrapper">
				<section class="section section-search-1">
					<div class="container">
						<div class="breadcrumb-bar">
							<div class="container-fluid">
								<div class="row align-items-center">
									<div class="col-md-12 col-12">
									
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-6">
								<img src="doccure_assets/img/dr-slider.png" class="img-fluid dr-img" alt="" />
							</div>
							<div class="col-md-6 search-doctor">
								<div class="search-area" style={{ maxWidth: "545px" }}>
									<h2 class="text-center">Enquiry for <span style={{ textTransform: "capitalize", color: "red" }}>International</span> Patients</h2>
									<form class="search-input" action="javascript:void(0)" onSubmit={submitInquiryforContactOther}>
										<div class="row">
											<div class="col-6 col-md-6">
												<div class="form-group">
													<label>First Name</label>
													<input type="text" class="form-control" required name="firstName" onChange={e => setFirstName(e.target.value)} value={firstName} />
												</div>
											</div>
											<div class="col-6 col-md-6">
												<div class="form-group">
													<label>Last Name</label>
													<input className="form-control" type="text" name="last_name" onChange={e => setLastName(e.target.value)} value={last_name} />
												</div>
											</div>
											<div class="col-6 col-md-6">
												<div class="form-group">
													<label>Mobile No</label>
													<input type="text" required class="form-control allow_only_number" maxlength="10" name="mobile" onChange={e => setMobile(e.target.value)} value={mobile} />
												</div>
											</div>
											<div class="col-6 col-md-6">
												<div class="form-group">
													<label>Email ID</label>
													<input className="form-control" required type="email" name="email" onChange={e => setEmail(e.target.value)} value={email} />
												</div>
											</div>
											<div class="col-6 col-md-6">
												<div class="form-group">
													<label>Age</label>
													<input type="text" class="form-control" name="age" onChange={e => setAge(e.target.value)} value={age} />
												</div>
											</div>
											<div class="col-6 col-md-6">
												<div class="form-group">
													<label>Gender</label>
													<select class="form-select form-control" name="gender" onChange={e => setGender(e.target.value)}>
														<option value="Male">Male</option>
														<option value="FeMale">FeMale</option>
														<option value="Other">Other</option>
													</select>
												</div>
											</div>
											<div class="col-12 col-md-12">
												<div class="form-group">
													<label>Message</label>
													<input type="text" class="form-control" name="messages" onChange={e => setMessages(e.target.value)} value={messages} />
												</div>
											</div>
										</div>
										<div class="submit-section">
											<button type="submit" class="btn btn-primary search-btn submit-btn">Submit</button>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</section>
				<div class="content">
					<div class="container-fluid">
						<div class="row">
							<div class="col-sm-9">
								<div class="card">
									<div class="card-body">
										<h3>Pre-Departure Services: Exclusive Services for International Patients </h3><br></br>
										<h4> Teleconsultations and Pre-Departure Assessment </h4><br></br>
										<ul>
											<li>Facilitate making travel plans (through a partner such as Cox and Kings, Thomas Cook or Kuoni)</li>
											<li>Visa Assistance</li>
											<li>Booking a hotel (Budget, Deluxe and Luxury Hotels)</li>
											<li>Pickup and drop-off at New Delhi International Airport</li>
											<li>Admittance to a hospital's bedside</li>
										</ul>

										<h4> Afterwards Services:  </h4><br></br>
										<ul>
											<li>Best possible patient care</li>
											<li>Hospital with central air conditioning</li>
											<li>Selection of rooms, including private and opulent suites</li>
											<li>Various international food options</li>
											<li>Translators for certain languages (Arabic, French, Spanish)</li>
											<li>Utilizing mobile devices to communicate and obtain information</li>
											<li>Wi-Fi ready rooms</li>
											<li>Within the hospital, the mobile desk</li>
											<li>Bookstore, Gift Store, and Money Exchange</li>
											<li>24-hour relationship management</li>
											<li>Online access to the patient's development is provided for family and friends.</li>
											<li>Send greetings and messages</li>
										</ul><br></br>

										<h4>Services Following Discharge:</h4><br></br>
										<ul>
											<li>Travel plans after discharge made through a travel companion </li>
											<li>After being treated, stay at one of several hotels in Delhi. </li>
											<li> A Delhi sightseeing tour</li>
											<li> Visit and stay in the Himalayas or any other Indian tourist destination</li>
											<li> Travel back home is made possible</li>
											<li> Telephone consultation with the attending physician</li>
										</ul><br></br>

										<h4>Travel and lodging: </h4><br></br>
										<ul>
											<li>In order to provide easy travel and lodging arrangements for its overseas patients</li>
											<li>Artemis has partnered with some of India's top travel agencies.</li>
										</ul><br></br>

										<h4>The following are the travel and lodging services that Artemis facilitates:</h4><br></br>
										<ul>
											<li>Arrangements for getting to India</li>
											<li>Visa Assistance</li>
											<li>Booking a hotel (Budget, Deluxe and Luxury Hotels)</li>
											<li>Pickup and drop-off at New Delhi International Airport</li>
											<li>After being treated, stay at one of several hotels in India.</li>
											<li>A India sightseeing tour</li>
											<li>Visit and stay in the Himalayas or any other Indian tourist destination</li>
											<li>Making travel preparations to return home</li>
										</ul><br></br>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Bottom />
		</>
	)
}
