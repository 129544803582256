import Https from "../../Https";
import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'


export default function Bottom() {
    //////////  function & variable for master packages
    const [poplourPackageMasters, setPoplourPackageMasters] = useState([]);

    useEffect(()=>{
        fetchPoplourpackageMasters();
    },[]);

    const fetchPoplourpackageMasters = () =>{
        Https.get('/poplour-package-master').then(response=>{
            setPoplourPackageMasters(response.data.data);  
        });  
    }

    const [popularPackages, setPopularPackages] = useState([]);
    
    useEffect(()=>{
        fetchPopularPackages();
    },[]);
    const fetchPopularPackages = () =>{
        Https.get('/get-popular-packages').then(response=>{
            setPopularPackages(response.data.data);  
        });  
    }
   
  return (
    <>
        <section class="section section-specialities">
            <div class="container">
                <div class="section-header text-center">
                    <div class="row">
                        <div class="col-md-1"></div>
                        <div class="col-md-10">
                            {poplourPackageMasters.map((poplourPackageMaster,index)=>(
                            <a href={`group-treatment-package/${poplourPackageMaster.package_master_slug}`}>{poplourPackageMaster.name} | </a>
                            ))}
                        </div>
                        <div class="col-md-1"></div>
                    </div>
                </div>
                <div class="widget about-widget text-center" style={{fontSize:"12px"}}>
                    <span class="widget-title"><strong>Popular Packages : </strong></span>
                    <div>
                        {popularPackages.map((setPopularPackage, index) =>(
                        <a href={`/package-details/${setPopularPackage.slug}`}>{setPopularPackage.name} | </a>
                        ))}
                    </div>
                </div>
                
            </div>
        </section>
    </>
    )
}