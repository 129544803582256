import React from 'react'
import { useState, useRef, useEffect, memo  } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Https from '../Https';
import LookingFor from './includes/home/LookingFor';
import { Link } from "react-router-dom";
import Testimonials from './modules/Testimonials';
import AceMedicareInNumber from './modules/AceMedicareInNumber';
import Bottom from './modules/Bottom';
import GetInTouch from './modules/GetInTouch';
import ForPatient from './modules/ForPatient';
import AceFaqs  from './modules/AceFaqs';
import AppointmentModel from './modules/AppointmentModel';
import Header from './includes/Header';
import Footer from './includes/Footer';
import UserAuth from '../UserAuth';
import { Helmet } from 'react-helmet';
import SearchPacakgeWithCity from './modules/SearchPacakgeWithCity';
import OurServices from './modules/OurServices';
import SearchAllCities from './modules/SearchAllCities';


export default function Home(props) {
    // function for load comonent from top
	const { pathname } = useLocation();
	useEffect(() => {
	  window.scrollTo(0, 0);
	}, [pathname]);

    const navigate = useNavigate();
    const [poplourPackageMasters, setPoplourPackageMasters] = useState([]);
    const [importantPackageMasters, setImportantPackageMasters] = useState([]);
    const [imagePaths, setimagePaths] = useState([]);
    const [cities, setCities] = useState([]);
    const [popularPackages, setPopularPackages] = useState([]);
    const [treatPackages, setTreatPackages] = useState([]);
    const [packageImgPath, setPackageImgPath] = useState([]);
 
   
    const [searchData, setSearchData] = useState({
        stateID:'', 
        packageID: '',    
    });
    useEffect(()=>{
        fetchPoplourpackageMasters();
    },[]);
    useEffect(()=>{
        fetchImportantpackageMasters();
    },[]);
    useEffect(()=>{
        fetchPopularPackages();
    },[]);
    //////////  use effect for fetch package image path
   useEffect(()=>{
        fetchPackageImagePath();
    },[]);
    
    useEffect(()=>{
        fetchImagePath();
    },[]);

    const fetchPoplourpackageMasters = () =>{
        Https.get('/poplour-package-master').then(response=>{
            setPoplourPackageMasters(response.data.data);  
        });  
    }
    const fetchImportantpackageMasters = () =>{
        Https.get('/important-package-master').then(response=>{
            setImportantPackageMasters(response.data.data);  
        });  
    }
    const fetchPopularPackages = () =>{
        Https.get('/get-popular-packages').then(response=>{
            setPopularPackages(response.data.data);  
        });  
    }
    //////////  call api for get image path
    const fetchPackageImagePath = () =>{
        Https.get('/package-image-path').then(response=>{
        setPackageImgPath(response.data);    
        });     
    } 
    const fetchImagePath = () =>{
        Https.get('/package-master-image-path').then(response=>{    
            setimagePaths(response.data);    
        });  
    }
    ////////// use effect for fetch active package list
    useEffect(()=>{
        fetchAllPackages();
    },[]);
    /////////   call api for get active package list                
    const fetchAllPackages = () =>{
        Https.get('all-active-packages').then(response=>{
        setTreatPackages(response.data.data);
        });  
    }
    //////////  use effect for fetch state list
    useEffect(() =>{
        fetAllCities();
    },[]);
    //////////  call api for get all states
    const fetAllCities = () =>{
        Https.get('get-hospital-cities').then(response=>{
            setCities(response.data.data);
        })
    }
    const [cityInput, setCityInput] = useState();
    const [packageInput, setPackageInput] = useState();
    const searchPackage = (e) =>{
        // console.log(cityInput+ ' ' + packageInput);
        Https.get('search-hospital-packages/'+cityInput+'/'+packageInput).then(response =>{
            if(cityInput==undefined || packageInput==undefined)
            {

            }
            else
            {
                navigate('/search-package-results/'+cityInput+'/'+packageInput);
            }
            
        });
    }
    const selectCity = (e) =>{
        const selectedCityID = e.target.value;
        setCityInput(selectedCityID);
        Https.get('find-package-by-city-id/'+selectedCityID).then(response =>{
        //    console.log(response.data.data);
           setTreatPackages(response.data.data);
        });
    }


    // useEffect(() =>{
    //     doctorOnCall();
    // },[]);

    const doctorOnCall = (e) => {
        
        // myFunction()
        // zini_function();
        // document.querySelector('.zini-widget-wrapper has-progress').click();
        // document.querySelector('.zini-widget-container').click();
        // document.querySelector('.is-mobile').click();
        // document.querySelector('.zini_script').click();
        // launcher-button-container
        alert('hello doctor');   
    }
    
  return (
    <>
        <div className="page-content header-bg">
            <div className="top-search">
                <div className="container">
                    <div className="search-area">
                        <SearchPacakgeWithCity />
                    </div>
                </div>
            </div>
            <OurServices />   
            
            <section id="home" className="divider">
                <div className="container-fluid p-0">
                    <div id="carouselExampleIndicators" className="carousel slide carousel-fade" data-ride="carousel"
                        data-interval="2500">
                        <div className="carousel-inner" style={{borderRadius: "30px 30px 0px 0px"}}>
                            <div className="carousel-item active">
                                <img className="d-block w-100" src="doccure_assets/img/slider/gusanji.png" alt="First slide" />
                                <div className="carousel-caption d-none d-md-block">
                                    <div className="slick-wrapper">
                                        <div className="slider-1"> 
                                            <div className="col-12 d-flex">
                                                <div className="col-7 col-lg-7">
                                                    <div className="">
                                                        <h2 style={{fontSize: "39px"}}>Health Problems? One Solution </h2>
                                                        <h2 style={{color:"#ffd307", marginTop:"-12px"}}>Ace MediCare</h2>
                                                        <span className="text-uppercase d-block mb-3">Discover the best doctors, clinic & hospital the city nearest to you.</span>
                                                        <a href="tel:8603002000" style={{backgroundColor:"#1e5c92", borderColor:"#1e5c92",color:"white"}} className="btn btn-rounded btn-outline-secondary" tabindex="0"> <i className="fa fa-phone" aria-hidden="true"></i> Call Us: +91-860 300 2000</a>&nbsp;&nbsp;&nbsp;
                                                        <a href="#getIntouch" style={{backgroundColor:"rgb(255, 211, 7)", borderColor:"rgb(255, 211, 7)", color:"rgb(0 0 0)"}} className="btn btn-rounded btn-outline-secondary" tabindex="0" data-bs-toggle="modal" data-bs-target="#appointment_model"> <i className="fa fa-calendar" aria-hidden="true" style={{color:"rgb(0 0 0)"}}></i> Book Free Appointment</a>
                                                        <p></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <span>We Provide Solution</span>
                                    <h2>To stressless Life</h2>
                                    <p>People who are more perfectionist are most likely to be depressed,<br />Because they
                                        stress themselves out so much.</p> */}
                                </div>
                                <div className="container-fluid home_Slider">
                                    <div className="banner-wrapper">
                                        <div className="banner-header">
                                            <h3 style={{color:"#186db5"}}>Health Problems? <br />One Solution </h3>
                                            <h2 style={{color:"#ffd307", marginTop:"-12px"}}>Ace MediCare</h2>
                                            
                                            <a href="tel:8603002000" style={{backgroundColor:"#1e5c92", borderColor:"#1e5c92",color:"white", fontSize:"12px",marginTop:"-10px"}} className="btn btn-rounded btn-outline-secondary" tabindex="0"> <i className="fa fa-phone" aria-hidden="true"></i> Call Us: &nbsp; 860 300 2000</a>
                                            <br />
                                            <a href="#getIntouch" style={{backgroundColor:"rgb(255, 211, 7)", borderColor:"rgb(255, 211, 7)", color:"rgb(0 0 0)", fontSize:"12px", marginTop:"5px"}} className="btn btn-rounded btn-outline-secondary" tabindex="0" data-bs-toggle="modal" data-bs-target="#appointment_model"> <i className="fa fa-calendar" aria-hidden="true" style={{color:"rgb(0 0 0)"}}></i> Book Free Appointment</a> 
                                            <br />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>  
    </>
  )
}
