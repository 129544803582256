import React from "react";
import ServiceSideBar from "../modules/ServiceSideBar";

export default function AmbulancewithNurse(){
  return(
    <>
      <div class="content">
            <div class="container-fluid">
                <div class="row">
                  <div class="col-sm-9">
                    <div class="card">
                      <div class="card-body">
                        <h3>Describe An Ambulance With Nurse </h3><br></br>
                        <p className=""> Only nurses, who are capable of handling patients or victims, may be present in some ambulances. They may occasionally assist paramedics in stabilising patients' fundamental conditions as they work alongside them. To preserve the health of patients, they could receive training to operate cutting-edge medical equipment.</p>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
      </div>
    </>
  );
}