import Https from "../../Https";
import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'


export default function SearchModel() {
    const navigate = useNavigate();
    const [poplourPackageMasters, setPoplourPackageMasters] = useState([]);
    const [importantPackageMasters, setImportantPackageMasters] = useState([]);
    const [imagePaths, setimagePaths] = useState([]);
    const [cities, setCities] = useState([]);
    const [popularPackages, setPopularPackages] = useState([]);
    const [treatPackages, setTreatPackages] = useState([]);
    const [packageImgPath, setPackageImgPath] = useState([]);
 
   
    const [searchData, setSearchData] = useState({
        stateID:'', 
        packageID: '',    
    });
    useEffect(()=>{
        fetchPoplourpackageMasters();
    },[]);
    useEffect(()=>{
        fetchImportantpackageMasters();
    },[]);
    useEffect(()=>{
        fetchPopularPackages();
    },[]);
    //////////  use effect for fetch package image path
   useEffect(()=>{
        fetchPackageImagePath();
    },[]);
    
    useEffect(()=>{
        fetchImagePath();
    },[]);

    const fetchPoplourpackageMasters = () =>{
        Https.get('/poplour-package-master').then(response=>{
            setPoplourPackageMasters(response.data.data);  
        });  
    }
    const fetchImportantpackageMasters = () =>{
        Https.get('/important-package-master').then(response=>{
            setImportantPackageMasters(response.data.data);  
        });  
    }
    const fetchPopularPackages = () =>{
        Https.get('/get-popular-packages').then(response=>{
            setPopularPackages(response.data.data);  
        });  
    }
    //////////  call api for get image path
    const fetchPackageImagePath = () =>{
        Https.get('/package-image-path').then(response=>{
        setPackageImgPath(response.data);    
        });     
    } 
    const fetchImagePath = () =>{
        Https.get('/package-master-image-path').then(response=>{    
            setimagePaths(response.data);    
        });  
    }
    ////////// use effect for fetch active package list
    useEffect(()=>{
        fetchAllPackages();
    },[]);
    /////////   call api for get active package list                
    const fetchAllPackages = () =>{
        Https.get('all-active-packages').then(response=>{
        setTreatPackages(response.data.data);
        });  
    }
    //////////  use effect for fetch state list
    useEffect(() =>{
        fetAllCities();
    },[]);
    //////////  call api for get all states
    const fetAllCities = () =>{
        Https.get('get-hospital-cities').then(response=>{
            setCities(response.data.data);
        })
    }
    const [cityInput, setCityInput] = useState();
    const [packageInput, setPackageInput] = useState();
   
    const searchPackage = (e) =>{
        // console.log(cityInput+ ' ' + packageInput);
        Https.get('search-hospital-packages/'+cityInput+'/'+packageInput).then(response =>{
            if(cityInput==undefined || packageInput==undefined)
            {

            }
            else
            {
                navigate('/search-package-results/'+cityInput+'/'+packageInput);
            }
            
        });
    }
  return (
    <>
         <div class="modal fade custom-modal custom-medicalrecord-modal" id="searchModel"
        style={{display: "none;",marginTop: "-120px"}} data-select2-id="searchModel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Search Package</h5>
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                    </button>
                </div>
                <form id="medical_records_form" enctype="multipart/form-data">
                    <div class="modal-body">
                        <div class="banner-wrapper">
                            <h5 class="mb-3">Book Treatment Package in hospitals the city nearest to you.</h5>
                            <div class="search-box search-box-3">
                                <form action="javascript:void(0)">
                                    <div class="form-group search-location">
                                        <select className="form-control" name="city_id" id="city_id" placeholder="Search Location" required="required" onChange={e=>setCityInput(e.target.value)}>
                                            <option value=""> Select Cities </option>
                                            {cities.map((cities,index)=>(
                                            <option value={cities.cityRowId}>{cities.cityName}</option>
                                            ))}
                                        </select>
                                        {/* <input type="text" class="form-control" placeholder="Search Location" /> */}
                                    </div>
                                    <div class="form-group search-info">
                                        <select className="form-control" name="package_id" id="package_id" required="required" onChange={e=>setPackageInput(e.target.value)} placeholder="Search Doctors, Clinics, Hospitals, Diseases Etc">
                                            <option value=""> Select Packages </option>
                                            {treatPackages.map((treatPackages, index)=>(
                                                <option value={treatPackages.id}>{treatPackages.name}</option>
                                            ))}
                                        </select>
                                        {/* <input type="text" class="form-control"
                                            placeholder="Search Doctors, Clinics, Hospitals, Diseases Etc" /> */}
                                    </div>
                                    <button type="submit" onClick={searchPackage} class="btn btn-primary search-btn btn-search mt-0"><i className="fas fa-search"></i> <span>Search</span></button>
                                </form>
                            </div>
                        </div>
                    </div>
                </form>
                </div>
            </div>
        </div>
    </>
    )
}