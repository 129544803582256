import { useState, useEffect } from 'react'
import Https from '../../Https';
import AlertDanger from '../ReturnResponse/AlertDanger';
import AlertSuccess from '../ReturnResponse/AlertSuccess';
import AlertWarning from '../ReturnResponse/AlertWarning';
export default function MyCart() {

    var userDetails = JSON.parse(localStorage.getItem('user_info'));
    const [myCarts, setMyCarts] = useState([]);
    const[successAlertMsg, setSuccessAlertMsg] = useState();
    const[warningAlertMsg, setWarningAlertMsg] = useState();
    const[dangerAlertMsg, setDangerAlertMsg] = useState();
    //////////  use effect for fetch package details 
    useEffect(() =>{ 
        fetch_my_session_cart_data();  
    },[]); 
    /////////   call api for get product with product details
    const fetch_my_session_cart_data = () =>{
        Https.get('/get-user-session-cart-data/'+userDetails.user_info.id).then(response=>{
            console.log('my cart') 
            setMyCarts(response.data);  
            console.log(response); 
        });  
    }

    const addQty = (event) => {
        const session_cart_id = event.target.getAttribute('data-session_cart_id');
        const data = {
            'session_cart_id': session_cart_id, 
            'type':'add',
        };
        Https.post('/update-user-cart-quantity',data).then(response=>{
            window. location. reload();
        })
    }

    const removeQty = (event) => {
        const session_cart_id = event.target.getAttribute('data-session_cart_id');
        const data = {
            'session_cart_id': session_cart_id, 
            'type':'remove',
        };
        Https.post('/update-user-cart-quantity',data).then(response=>{
            window. location. reload();
        })
    }
    const removeItmefromCart = (event) => {
        const session_cart_id = event.target.getAttribute('data-session_cart_id');
        const data = {
            'session_cart_id': session_cart_id, 
            'user_id':userDetails.user_info.id,
        };
        Https.post('/remove-item-from-cart',data).then(response=>{
            setSuccessAlertMsg('Item Removed Successfully.');
            setTimeout(() =>{
                setSuccessAlertMsg('');
                window. location. reload();
            },3000);
        })
    }

    


  return (
    <>
    <div class="page-content header-bg pt-0">
        <div class="chat-list-new">
            <div class="container">
                <div class="chat-title">
                    <div class="chat-list-title"><i><img src="../mobile_assets/img/chat.svg" alt="" /></i> {myCarts.length} Item Are Selected</div>
                    <div class="user"> <i class="fa fa-trash" aria-hidden="true"></i>   </div>
                </div>
                <AlertSuccess alert={successAlertMsg} />
                <AlertWarning alert={warningAlertMsg} />
                <AlertDanger alert={dangerAlertMsg} />
                <div class="tab-content">
                {myCarts.map((myCart, index)=>(
                    <div class="tab-pane active" id="appoinments">
                        <div class="panel panel-default">
                            <div id="collapseOne" class="panel-collapse collapse in">
                                <div class="panel-body">
                                    <div class="patient-appointments-col">
                                        <div class="patient-widget">
                                            <div class="patient-top-details">
                                                <div>
                                                    <span class="invoice-id"></span>
                                                </div>
                                                <div>
                                                    <span class="date-col">
                                                    <strong><a href="javascript:void(0)" onClick={removeItmefromCart} data-session_cart_id={myCart.session_cart_id} >X</a></strong>
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="invoice-widget">
                                                <div class="pat-info-left">
                                                    <div class="patient-img">
                                                        <a href={`/products/product-detail/${myCart.slug}`}>
                                                            <img src={`https://acemedicare-admin.hospicares.in/products/product/${myCart.main_image}`} class="rounded-circle" width="55" alt={myCart.title} />
                                                        </a>
                                                    </div>
                                                    <div class="pat-info-cont">
                                                        <h4 class="pat-name"><a href="#">{myCart.title}</a></h4>
                                                        <div class="hour-col">
                                                            <div class="item-text"><i class="fas fa-rupee-sign"></i> {myCart.sale_price}.00<span class="smile"></span></div>
                                                        </div>
                                                        <div class="patient-details-col">
                                                            <span class="">
                                                            Qty: <input type='button' value="+" data-session_cart_id={myCart.session_cart_id} onClick={addQty} /> {myCart.session_qty} <input type='button' value="-" onClick={removeQty} data-session_cart_id={myCart.session_cart_id}  />
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
                </div>
                <li className="bottom-button">
                    <a href="/pharmacy/place-order">
                        <button type='submit' className="btn">Place Order</button>
                    </a>
                </li>
                
            </div>
        </div>
    </div>
    <br></br>
    
    </>
  )
}
