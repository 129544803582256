import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Https from '../../Https';
import AlertDanger from '../ReturnResponse/AlertDanger';
import AlertSuccess from '../ReturnResponse/AlertSuccess';
import AlertWarning from '../ReturnResponse/AlertWarning';

export default function PlaceOrder() {
    const[successAlertMsg, setSuccessAlertMsg] = useState();
    const[warningAlertMsg, setWarningAlertMsg] = useState();
    const[dangerAlertMsg, setDangerAlertMsg] = useState();

    const navigate = useNavigate();
    const userDetails = localStorage.getItem('user_info');
    const jsonUserDetails = JSON.parse(userDetails);


    const get_location_with_pin = (event) => {
        const input_pin_code = event.target.value;
        setPinCode(input_pin_code);
        Https.post('get-location-with-pin-code',{pin_code: input_pin_code}).then(response=>{
            console.log(response);
            set_city_district(response.data.PostOfficeName)
            set_state(response.data.State)
        })
    }
    
    const [firstName, setFirstName] = useState(jsonUserDetails.user_info.name);
    const [last_name, setLastName] = useState(jsonUserDetails.user_info.last_name);
    const [mobile, setMobile] = useState(jsonUserDetails.user_info.mobile);
    const [email, setEmail] = useState(jsonUserDetails.user_info.email);
    const [pinCode, setPinCode] = useState(jsonUserDetails.user_info.pin_code);
    const [address, setAddress] = useState(jsonUserDetails.user_info.address);
    const [locationTown, setLocationTown] = useState();
    const [city_district, set_city_district] = useState();
    const [state, set_state] = useState();

    const submitFirstStep = () => {
        const data = {
            firstName: firstName,
            last_name: last_name,
            mobile: mobile,
            email: email,
            pinCode: pinCode,
            address: address,
            locationTown:locationTown,
            city_district: city_district,
            state: state,
        };
        if(pinCode=='' || pinCode==null)
        {
            setSuccessAlertMsg('Pin Code is Required');
            setTimeout(() =>{
                setSuccessAlertMsg('');
                window. location. reload();
            },3000);
        }
        if(address=='' || address==null)
        {
            setSuccessAlertMsg('Address is Required');
            setTimeout(() =>{
                setSuccessAlertMsg('');
                window. location. reload();
            },3000);
        }
        localStorage.setItem('place_order', JSON.stringify(data));
        navigate('/pharmacy/order-summary');
        
   }

    
    
  return (
    <>
    <div className="main-wrapper">
        <div className="page-content change-password-col register-col">
            <div className="list no-hairlines custom-form">
                <div className="back-btn"><a href="#" className="back link">
                        <img src="../../assets/img/left-arrow-big-black.svg" alt="" /></a>
                </div>
                
                <AlertSuccess alert={successAlertMsg} />
                <AlertWarning alert={warningAlertMsg} />
                <AlertDanger alert={dangerAlertMsg} />


                <div className="page-content profile-settings">

                    <div className="container">
                        <div className="tab-content">
                            <div className="tab-pane active" id="payment">
                                <div className="panel panel-default">
                                    <div id="collapseThree" className="panel-collapse collapse">
                                        <div className="panel-body">
                                            <div className="widget-title">
                                                <h5>Place Your Order</h5>
                                            </div>
                                            <form action="javascript:void(0)" onSubmit={submitFirstStep}>
                                                <div className="setting-widget">
                                                    <div className="list no-hairlines-md pricing-col no-border">
                                                        <ul>                                  
                                                            <li className="item-content item-input">
                                                                <div className="item-col">
                                                                    <div className="item-title item-label">Name
                                                                        <span>*</span></div>
                                                                    <div className="item-input-wrap">
                                                                        <input type="text" className="form-control" placeholder="Patient Name" required name="firstName" onChange={e => setFirstName(e.target.value)} value={firstName} />
                                                                        <span className="input-clear-button"></span>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li className="item-content item-input">
                                                                <div className="item-col">
                                                                    <div className="item-title item-label">Mobile
                                                                        <span>*</span></div>
                                                                    <div className="item-input-wrap">
                                                                        <input type="text" required  className="form-control allow_only_number" maxlength="10" name="mobile" onChange={e => setMobile(e.target.value)} value={mobile}/>
                                                                        <span className="input-clear-button"></span>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li className="item-content item-input">
                                                                <div className="item-col">
                                                                    <div className="item-title item-label">Email
                                                                        <span>*</span></div>
                                                                    <div className="item-input-wrap">
                                                                        <input type="text" required  className="form-control" name="mobile" onChange={e => setEmail(e.target.value)} value={email}/>
                                                                        <span className="input-clear-button"></span>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li className="item-content item-input">
                                                                <div className="item-col">
                                                                    <div className="item-title item-label">Pin Code</div>
                                                                    <div className="item-input-wrap">
                                                                        <input type="text" required  className="form-control" onChange={get_location_with_pin} value={pinCode} />
                                                                        <span className="input-clear-button"></span>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li className="item-content item-input">
                                                                <div className="item-col">
                                                                    <div className="item-title item-label">Address</div>
                                                                    <div className="item-input-wrap">
                                                                        <input type="text" required  className="form-control" onChange={e => setAddress(e.target.value)} value={address} />
                                                                        <span className="input-clear-button"></span>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li className="item-content item-input">
                                                                <div className="item-col">
                                                                    <div className="item-title item-label">Location Town</div>
                                                                    <div className="item-input-wrap">
                                                                        <input type="text"  className="form-control" onChange={e => setLocationTown(e.target.value)} value={locationTown}  />
                                                                        <span className="input-clear-button"></span>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li className="item-content item-input">
                                                                <div className="item-col">
                                                                    <div className="item-title item-label">City/ District</div>
                                                                    <div className="item-input-wrap">
                                                                        <input type="text"  className="form-control" onChange={e => setLocationTown(e.target.value)} value={city_district}  />
                                                                        <span className="input-clear-button"></span>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li className="item-content item-input">
                                                                <div className="item-col">
                                                                    <div className="item-title item-label">City/ District</div>
                                                                    <div className="item-input-wrap">
                                                                        <input type="text"  className="form-control" onChange={e => set_state(e.target.value)} value={state}  />
                                                                        <span className="input-clear-button"></span>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li className="item-content item-input">
                                                                <div className="item-col">
                                                                    <div className="item-title item-label">Country</div>
                                                                    <div className="item-input-wrap">
                                                                        <input type="text" required  className="form-control" value="India" readOnly />
                                                                        <span className="input-clear-button"></span>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="setting-widget">
                                                    <div className="list no-hairlines-md pricing-col no-border">
                                                        <ul>
                                                            <li className="item-content item-input col-50 gender">
                                                                <div className="item-col">
                                                                    <a href="/pharmacy/my-cart" className="btn" style={{backgroundColor:"#20c0f3",borderRadius:"50px",textTransform:"uppercase",color:"#fff",border:"0",padding:"12px 15px",width:"100%",display:"inline-block"}}>Previous</a>
                                                                </div>
                                                            </li>
                                                            <li className="item-content item-input col-50 dob">
                                                                <div className="item-col">
                                                                    <button type='submit' className="btn"> Next</button>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>






            {/* 

                <div className="register-inner-col">
                    <h3>Make Appointment</h3>
                    <div className="col-12 col-md-6 border-right">
                        <form action="javascript:void(0)" onSubmit={submitFirstStep}>
                            <div className="step_first">
                                <label>Department</label>
                                <div className="form-group">
                                    <select className="form-control" name="departmentId" id="departmentId" required="required" onChange={onChangeDepartment}>
                                        <option value=""> Select Department </option>
                                        <option value="Other">Other</option>
                                        {departments.map((department, index)=>(
                                            <option value={department.id}>{department.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <label>Describe Your Symptoms?</label>
                                <div className="form-group">
                                    <textarea type="text" required placeholder="Describe Your Symptoms?" className="form-control" name="symptoms" onChange={e => setSymptoms(e.target.value)} value={symptoms}/>
                                </div>
                                <label>Upload Your Prescription</label>
                                <div className="form-group">
                                    <input type="file" id="prescription" name="prescription" multiple onChange={e => setPrescription(e.target.value)} value={prescription}/>
                                </div>
                                <label>Appointment Mode </label>
                                <div className="form-group">
                                    Audio &nbsp;<input type="radio" name="appointmentMode" onChange={e => setAppointmentMode(e.target.value)} value="audio"/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                    Video &nbsp;<input type="radio" name="appointmentMode" checked onChange={e => setAppointmentMode(e.target.value)} value="video"/>
                                </div>
                                <div className="tab-content">
                                    <div className="tab-pane active" id="time-date">
                                        <div className="panel panel-default">
                                            <div id="collapseOne" className="panel-collapse collapse in">
                                                <div className="panel-body">
                                                    <div className="date-time-col">
                                                        <div className="date-col-day">
                                                            {dateDays.map((dateDay, index)=>(
                                                            <div>
                                                                <span className="active">{dateDay.date}</span>
                                                                <sup>{dateDay.day}</sup>
                                                            </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <label>Schedules</label>
                                <div className="form-group">
                                    <select className="form-control" name="departmentId" id="departmentId" required="required" onChange={onChangeDepartment}>
                                        <option value=""> Select Schedule </option>
                                        {schedules.map((schedule, index)=>(
                                            <option value={schedule}>{schedule}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group text-center" style={{float:"right"}}>
                                    <Link to="/appointment/doctor-on-call" className="btn btn-primary submit-btn">&nbsp;&nbsp;Back&nbsp;&nbsp;</Link> &nbsp;
                                    <button type="submit" className="btn book-btn" style={{float:"right"}}>
                                    &nbsp;&nbsp;Next&nbsp;&nbsp;</button>
                                </div>
                                <br/>
                                <br/>
                            </div>
                        
                        </form>
                    </div>
                </div>
            */}
            </div>
        </div>
    </div>
    </>
  )
}
