import React, { useEffect, useState } from 'react'
import { Link,useNavigate } from 'react-router-dom'
import SideBar from '../user/SideBar';
import Https from '../../Https';
import Header from '../includes/Header';
import Footer from '../includes/Footer';
import AlertDanger from '../ReturnResponse/AlertDanger';
import AlertSuccess from '../ReturnResponse/AlertSuccess';
import AlertWarning from '../ReturnResponse/AlertWarning';


export default function ServiceBooking() {
    const[successAlertMsg, setSuccessAlertMsg] = useState();
    const[warningAlertMsg, setWarningAlertMsg] = useState();
    const[dangerAlertMsg, setDangerAlertMsg] = useState();

    const navigate = useNavigate();

    const userDetails = localStorage.getItem('user_info');
    const jsonUserDetails = JSON.parse(userDetails);
    const userToken = jsonUserDetails.token;
    const [userId, setUserId] = useState(jsonUserDetails.user_info.id);
  
    var userid = jsonUserDetails.user_info.id;
    const userMobile = jsonUserDetails.user_info.mobile;

    // variable & function for image preview
    const[image, setImage] = useState('');

    //////////  variable & function for get user Details
    // const[userDetail, setUserDetail] = useState([]);
    const[user_details, set_user_details] = useState(); 

    //////////  use effect for fetch city list
    useEffect(() =>{
        fetUserDetail();
    },[]);
    //////////  call api for get all cities
    const fetUserDetail = () =>{
        Https.get('get-user-detail-by-id/'+userid).then(response=>{
            set_user_details(response.data.data);
            name(response.data.data.name+' '+response.data.data.last_name)
            setMobile(response.data.data.mobile)
        })
    }
    ////////////  varable & function for update profile
    const [firstName, setFirstName] = useState(jsonUserDetails.user_info.name);
    const [last_name, setLastName] = useState(jsonUserDetails.user_info.last_name);
    const [name, setName] = useState(jsonUserDetails.user_info.name +' '+jsonUserDetails.user_info.last_name);
    const [mobile, setMobile] = useState(jsonUserDetails.user_info.mobile);
    const [service,setService] = useState();
    const [remarks, setRemarks] = useState();
    const [amount, setAmount] = useState();

    
    const bookAceServices = (event) => {
        event.preventDefault();
        const fdata = new FormData();
        fdata.append("name",name);
        fdata.append("mobile",mobile);
        fdata.append("service",service);
        fdata.append("remarks",remarks);
        fdata.append("amount",amount);
        fdata.append("userId",userId);
        Https.post('book_services',fdata).then((response) => {
            console.log(response);
            window.location.href = response.data.data.instrumentResponse.redirectInfo.url;
        })
    }
    
    
    

  return (
    <>
    <div className="main-wrapper">
        <div className="page-content change-password-col register-col">
            <div className="list no-hairlines custom-form">
                <div class="tab-content">
                    <div class="tab-pane active" id="basic-info">
                        <div class="panel panel-default">
                            <div id="collapseOne" class="panel-collapse collapse in">
                                <div class="panel-body">
                                    <div class="setting-widget">
                                        <div class="list no-hairlines-md">
                                            <div class="widget-title">
                                                <h5>Basic Information</h5>
                                            </div>
                                            <form onSubmit={bookAceServices} enctype='multipart/form-data'>
                                            <div class="row form-row">
                                                <div class="col-12 col-md-6">
                                                    <div class="form-group">
                                                        <label for="name"> Name</label>
                                                        <input class="form-control" name="name" id="name" value={`${firstName} ${last_name}`} onChange={e => setName(e.target.value)} type="text" /> 
                                                    </div>
                                                </div>
                                              
                                                <div class="col-12 col-md-6">
                                                    <div class="form-group">
                                                        <label>Mobile</label>
                                                        <input class="form-control allow_only_number" name="mobile" readOnly id="mobile" value={mobile} onChange={e => setMobile(e.target.value)} type="number" maxLength={10} />
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6">
                                                    <div class="form-group">
                                                        <label>Services</label>
                                                        <select className="form-control" name="services" id="services" onChange={e=>setService(e.target.value)}>
                                                            <option value="Ambulance">Ambulance</option>
                                                            <option value="Hospitalization">Hospitalization</option>
                                                            <option value="HomeCare">Home Care</option>
                                                            <option value="DoctorAtHome">Doctor At Home</option>
                                                            <option value="Nurses">Nurses</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6">
                                                    <div class="form-group">
                                                        <label for="firstName">Amount</label>
                                                        <input class="form-control" name="firstName" id="firstName" onChange={e => setAmount(e.target.value)} type="number" /> 
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6">
                                                    <div class="form-group">
                                                        <label>Remarks</label>
                                                        <textarea
                                                            type="text"
                                                            required
                                                            placeholder="Describe Your Problem?"
                                                            className="form-control"
                                                            name="remarks"
                                                            onChange={(e) =>
                                                                setRemarks(e.target.value)
                                                            }
                                                            value={remarks}
                                                            />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="submit-section">
                                                <button type="submit" class="btn btn-primary submit-btn">Make Payment</button>
                                            </div>
                                            </form>
                                                
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}
