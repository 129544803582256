import React from "react";
import ServiceSideBar from "../modules/ServiceSideBar";

export default function AirAmbulance(){
  return(
    <>
      <div class="content">
            <div class="container-fluid">
                <div class="row">
                  <div class="col-sm-9">
                    <div class="card">
                      <div class="card-body">
                        <h3>Describe an Air Ambulance ? </h3><br></br>
                        <p className="">When land ambulance transport might put a patient's life in jeopardy or in isolated locations without ground access, air ambulance services are employed. Modern, cutting-edge life support technology is available on Air Ambulance's aircraft. The Emergency Services also include the Air Ambulance service. Personnel with advanced cardiac life support, critical care medicine, and flight physiology training operate air ambulances. Due to India's 400+ airfields, many of which provide night landing capabilities, air ambulance services can reach numerous locations around the country.</p><br></br>


                        <h3>History</h3><br></br>
                        <p className="">Before the famous Kitty Hawk trip, which saw 160 injured victims transported by air balloon, the first air evacuation took place in 1870, 33 years earlier. balloons by French physiologist and the founder of aeromedicine, Paul Bert, during the Prussian siege of Paris. The first such air ambulance was a French aircraft, the Dorand AR II, which was deployed in 1917. With a mortality rate of 4 per 100,000, over 1 million casualties from World War II were transported by air. The Scottish Isles were served by the first British Civilian Air Ambulance Service, which didn't begin operations until 1933.</p>
                        <p className="">At Fort Sam Houston in San Antonio, Texas, the Military Assistance to Safety and Traffic (MAST) system went into operation in 1969. The Department of Transportation launched MAST as an experiment to investigate if military helicopters might supplement current emergency medical services. During India's conflicts with Pakistan, air ambulance services were employed.The Indian Air Force has performed air evacuations of civilian casualties during national tragedies, but until the India Aero medical Services were established in 1995, there was no such service available solely for civilians.</p><br></br>

                        <h3>Air Ambulance Types</h3><br></br>
                        <h4>There are two different kinds of air ambulances:</h4><br></br>
                        <p className="">Paul Bert, a French physiologist and the founder of aeromedicine, carried out the first air evacuation in 1870, 33 years before the famous Kitty Hawk mission, which involved the evacuation of 160 injured victims during the Prussian siege of Paris using air balloons. The first such air ambulance was a French aircraft, the Dorand AR II, which was deployed in 1917. With a mortality rate of 4 per 100,000, over 1 million casualties from World War II were transported by air. The Scottish Isles were served by the first British Civilian Air Ambulance Service, which didn't begin operations until 1933. At Fort Sam Houston in San Antonio, Texas, the Military Assistance to Safety and Traffic (MAST) system went into operation in 1969. In MAST,began as a test by the Department of Transportation to see if military helicopters could supplement current emergency medical services. During India's conflicts with Pakistan, air ambulance services were employed. The Indian Air Force has performed air evacuations of civilian casualties during national tragedies, but until the India Aero medical Services were established in 1995, there was no such service available solely for civilians.</p><br></br>

                        <h4>Heli-Medical Ambulance </h4>
                        <li>It is the main form of emergency operations because of its special qualities, including flying into inaccessible regions, taking off from and landing in tight spaces, and flying over challenging terrain. However, depending on the kind of helicopter, its operational range is often restricted to 150–300 miles. In particular, the ability to approach incident or accident sites where access by road may be extremely challenging or where roads may not even exist, the helicopter offers significant benefits over vehicle transportation.</li><br></br>

                        <h4>Air Ambulance with fixed wings</h4>
                        <li>Long distance patient transfers are possible, but they must be loaded and unloaded at airports or existing airfields. Since hospitals are rarely near airports, ground transportation is required at both ends.of the aircraft. Depending on factors including distance, terrain, patient condition, diagnosis, flight duration, and civil aviation norms and regulations, the best type of air ambulance will be selected for a given case.</li>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
      </div>
    </>
  );
}