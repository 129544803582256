import React from 'react'
import { useState, useEffect } from 'react';
import { Link,useNavigate } from 'react-router-dom';
import Https from '../../Https';
import AlertSuccess from '../ReturnResponse/AlertSuccess';
import AlertWarning from '../ReturnResponse/AlertWarning';
import AlertDanger from '../ReturnResponse/AlertDanger';

function MobileVarification() {

    const navigate = useNavigate();
    const[successAlertMsg, setSuccessAlertMsg] = useState();
    const[warningAlertMsg, setWarningAlertMsg] = useState();
    const[dangerAlertMsg, setDangerAlertMsg] = useState();

    const urlParams = new URLSearchParams(window.location.search);
    const shareCode = urlParams.get('shareCode');

    const [mobile, setMobile] = useState();

    const handleChange = (e) => {
        const mobile_value = e.target.value.replace(/\D/g, "");
        console.log(mobile_value.length);
        if(mobile_value.length>10)
        {
            return false;
        }
        else
        {
            setMobile(mobile_value);
        } 
    }
    const getOtp = () =>{
        if(mobile.length==10)
        {
            Https.post('/check-user-mobile-by-otp',{mobile:mobile}).then((response) =>{
                
                if(response.data.response.status===200)
                {
                    const otp_uid = response.data.response.data.uid;
                    localStorage.setItem('otp_uid', otp_uid);
                    localStorage.setItem('shareCode', shareCode);
                    navigate('/otp-varification');
                }
                else
                {
                    setWarningAlertMsg(response.data.response.message);
                    setTimeout(() =>{
                        setWarningAlertMsg('');
                    },5000);
                }
                
            });
        }
        else
        {
            setWarningAlertMsg('Invalid Mobile Number.');
            setTimeout(() =>{
                setWarningAlertMsg('');
            },5000);
        }
    }























    


    const userDetails = localStorage.getItem('user_info');
    // console.log('this is user details');
    // console.log(userDetails);
    useEffect(() => {
        checkUser();
        }, []);
    if(userDetails===null){
        var checkUser = () => { };
    }
    else
    {
        const jsonUserDetails = JSON.parse(userDetails);
        const userToken = jsonUserDetails.token;
    
        var userid = jsonUserDetails.user_info.id;
        const userMobile = jsonUserDetails.user_info.mobile;
    
        var checkUser = () => {
        Https.get('user-data-with-token',{ headers: {"Authorization" : `Bearer ${userToken}`} }).then((response) => {
            if(userid==response.data.id)
            {
                navigate('/home');
            }
            
            else
            {
                navigate('/login');
            }
            
        });
        }
    }
    


  return (
    <>
    <div className="main-wrapper custome_background">
        <div className="page-content change-password-col register-col">
            <div className="list no-hairlines custom-form">
                <div className="back-btn"><a href="#" className="back link">
                        <img src="../../assets/img/left-arrow-big-black.svg" alt="" /></a>
                </div>
                <div className="register-icon">
                    <img src="assets/img/register-top-img.png" alt="" />
                </div>
                <div className="logo">
                    <a href="javascript:void(0)">
                        <img src="doccure_assets/img/logo.png" alt="Ace Medicare" />
                    </a>
                </div>
                <AlertSuccess alert={successAlertMsg} />
                <AlertWarning alert={warningAlertMsg} />
                <AlertDanger alert={dangerAlertMsg} />
                <div className="register-inner-col">
                    <div className="top-title">
                        <div>
                            <h3>Verify Your Phone Number</h3>
                        </div>
                        
                    </div>
                    <ul className="change-list">
                        <li className="item-content item-input">
                            <div className="item-col">
                                <div className="item-input-wrap">
                                    <input type="number" className='input_number allow_only_number' value={mobile} onChange={handleChange} placeholder="Enter Mobile Number" />
                                </div>
                            </div>
                        </li>
                        
                        <li className="col-50 save-password">
                        </li>
                        <li className="col-50 forgot-password">
                            <a href="/forget-password">Forgot Password ?</a>
                        </li>
                        <li className="item-content item-input">
                            <div className="item-input-wrap submit-btn-col">
                                <button type="button" onClick={getOtp} className="btn btn-submit">Send Code via SMS</button>
                            </div>
                        </li>
                    </ul>
                    <span className="login-back">Don't have an login ? <a href="/login">Login Now!</a></span>
                </div>
                <div className="register-inner-col">
                    <img src="doccure_assets/img/login-banner.png" alt="Ace Medicare" />
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default MobileVarification