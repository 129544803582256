import React from "react";
import ServiceSideBar from "../modules/ServiceSideBar";

export default function AmbulancewithDoctor(){
  return(
    <>
      <div class="content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-9">
              <div class="card">
                <div class="card-body">
                  <h3>Describe An Ambulance With Doctor </h3><br></br>
                  <p className="">A paramedic is a qualified medical worker who can offer those in need quick emergency medical evaluation and assistance. They deal with persons who are going through:</p>
                    <ul>
                      <li>A cardiac arrest</li>
                      <li>An attack</li>
                      <li>Spinal wounds</li>
                      <li>An overdose of drugs</li>
                      <li>Mental disorder</li>
                    </ul><br></br>

                  <p className="">Some paramedics have completed vocational training to get a diploma in paramedical sciences. The paramedic or health sciences degree is increasingly being demanded by ambulance services. Paramedics that specialise in intensive care undergo additional postgraduate training.</p><br></br>
                  <p className="">A public practitioner registry maintained by the Australian Health Practitioners Regulation Authority lists the names of qualified paramedics who have registered with the Paramedicine Board of Australia (AHPRA). Learn more about the paramedics' and other staff members' training here.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}