import React from 'react'
import { useState, useEffect } from 'react';
import { Link,useNavigate } from 'react-router-dom';
import Https from '../../Https';
import AlertSuccess from '../ReturnResponse/AlertSuccess';
import AlertWarning from '../ReturnResponse/AlertWarning';
import AlertDanger from '../ReturnResponse/AlertDanger';

function MobileVarification() {

    const navigate = useNavigate();
    const[successAlertMsg, setSuccessAlertMsg] = useState();
    const[warningAlertMsg, setWarningAlertMsg] = useState();
    const[dangerAlertMsg, setDangerAlertMsg] = useState();

    ///// form validation errors variables
    const[errorname, setErrorname] = useState();
    const[erroremail, setErroremail] = useState();
    const[errorpassword, setErrorpassword] = useState();

    const varified_mobile = localStorage.getItem('varified_mobile');
    const [username, setUsername] = useState();
    const [mobile, setMobile] = useState(varified_mobile);
    const [shareCode, setShareCode] = useState(localStorage.getItem('shareCode'));
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    // const manageName = (e) => {

    // }
    // const manageEmail = (e) => {

    // }
    // const managePassword = (e) => {

    // }
    const handleChange = (e) => {
        const mobile_value = e.target.value.replace(/\D/g, "");
        console.log(mobile_value.length);
        if(mobile_value.length>10)
        {
            return false;
        }
        else
        {
            setMobile(mobile_value);
        } 
    }
    const userRegistration = () =>{
        Https.post('/new-register',{name:username,mobile:varified_mobile,email:email,'password':password,'shareCode':shareCode,'source':'ace-medicare'}).then((response) =>{
            if(response.data.code==422)
            {
                // show all validtion errors
                setErrorname(response.data.response.message.name);
                setErroremail(response.data.response.message.email);
                setErrorpassword(response.data.response.message.password);
                setTimeout(() =>{
                    setErrorname('');setErroremail('');setErrorpassword('');
                },5000);
            }
            else if(response.data.code==400)
            {
                setWarningAlertMsg(response.data.response.message);
                setTimeout(() =>{
                    setWarningAlertMsg('');
                },5000);
            }
            else if(response.data.code==200)
            {
                setWarningAlertMsg(response.data.response.message);
                setTimeout(() =>{
                    setWarningAlertMsg('');
                },5000);
                localStorage.setItem('user_info', JSON.stringify(response.data.response.data));
                // navigate('/home');
                window.location.replace("/home");
            }
            else
            {
                navigate('/login');
            }
            console.log(response);
            // console.log(response.data.response.message.name);
        })
    }


  return (
    <>
    <div className="main-wrapper">
        <div className="page-content change-password-col register-col">
            <div className="list no-hairlines custom-form">
                <div className="back-btn"><a href="#" className="back link">
                        <img src="../../assets/img/left-arrow-big-black.svg" alt="" /></a>
                </div>
                <div className="register-icon">
                    <img src="assets/img/register-top-img.png" alt="" />
                </div>
                <div className="logo">
                    <a href="javascript:void(0)">
                        <img src="doccure_assets/img/logo.png" alt="doccure" />
                    </a>
                </div>
                <AlertSuccess alert={successAlertMsg} />
                <AlertWarning alert={warningAlertMsg} />
                <AlertDanger alert={dangerAlertMsg} />
                <div className="register-inner-col">
                <form action="javascript:void(0)" onSubmit={userRegistration}>
                    <ul className="change-list">
                        <li className="item-content item-input">
                            <div className="item-col">
                                <div className="item-input-wrap">
                                    <AlertWarning alert={errorname} />
                                    <input type="text" placeholder='Enter Your Name' value={username} onChange={e => setUsername(e.target.value)} required />
                                </div>
                            </div>
                        </li>
                        <li className="item-content item-input">
                            <div className="item-col">
                                <div className="item-input-wrap">
                                    
                                    <input type="text" className='allow_only_number' value={mobile} onChange={handleChange} readOnly />
                                </div>
                            </div>
                        </li>
                        <li className="item-content item-input">
                            <div className="item-col">
                                <div className="item-input-wrap">
                                    <AlertWarning alert={erroremail} />
                                    <input type="text" placeholder='Enter Your Email' value={email} onChange={e => setEmail(e.target.value)} required />
                                </div>
                            </div>
                        </li>
                        <li className="item-content item-input">
                            <div className="item-col">
                                <div className="item-input-wrap">
                                    <AlertWarning alert={errorpassword} />
                                    <input type="password" placeholder='Password' value={password} onChange={e => setPassword(e.target.value)} required />
                                </div>
                            </div>
                        </li>
                        <li className="item-content item-input">
                            <div className="item-input-wrap submit-btn-col">
                                <button type="submit" className="btn btn-submit">Submit</button>
                            </div>
                        </li>
                    </ul>
                </form>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default MobileVarification