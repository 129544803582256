import React from "react";
import ServiceSideBar from "../modules/ServiceSideBar";

export default function ICUsetupatHome(){
  return(
    <>
      <div class="content">
            <div class="container-fluid">
                <div class="row">
                  <div class="col-sm-9">
                    <div class="card">
                      <div class="card-body">
                        <h3>Intensive/Critical Care </h3><br></br>
                        <p className=""> The ideal places for healing are at home. For patients who still need intensive care but are no longer in the acute stage of their disease, Portea ICU at Home services are available.</p>
                        <p className=""> The cost of Critical Care at Home services is substantially less than the cost of a hospital stay and includes the care and supervision of highly qualified critical care therapists, specialists, and nurses.</p>
                        <p className=""> To ensure the best possible medical treatment, our clinical processes were created in collaboration with recognised specialists and top hospitals. Here are six ways we help patients and their families benefit from receiving critical care at home. </p><br></br>

                        <h3>What Situations Require Critical Care?</h3><br></br>
                        <p className="">In the event that no active treatments need to be made, many hospitals and doctors urge that long-term ICU care be given at home wherever feasible.</p>
                        <p className="">At home, the patient is more content, and recovery may proceed unhindered by the risk of hospital-acquired illnesses. The family also has it easier financially because home health care is at least 30% less expensive than hospital treatment.</p>
                        <p className="">Many patients with respiratory or neurological diseases that take a long time to recover require this service, along with proper equipment support and specialised care from qualified nurses.</p><br></br>

                        <h3>INTENSIVE CARE PACKAGES</h3><br></br>
                        <p className="">Care plans are created specifically for each patient's requirements and are intended to provide high-quality, easily accessible health care at a reasonable cost.</p>
                          <ul>
                            <li>clinical group</li>
                            <li>2 nursing assistants with ICU experience, each working 12 hours</li>
                            <li>Physiotherapist (as suggested by primary physician) (as advised by primary physician)</li>
                            <li>(1-2 visits per week) Respiratory therapist</li>
                            <li>ICU supplies</li>
                            <li>clinical quality checks every week</li>
                          </ul>
                        <h4>Infrastructure in ICUs </h4>
                          <ul>
                            <li>Infusion pumps for ventilators</li>
                            <li>DVT Pedal</li>
                            <li>Other equipment as determined by a Portea doctor's clinical evaluation Home visit reports</li>
                            <li>E-Monitoring</li>
                          </ul><br></br>

                        <h3>DECLINE PACKAGE</h3><br></br>
                          <ul>
                            <li>clinical group</li>
                            <li>2 nursing assistants with ICU experience, each working 12 hours</li>
                            <li>Physiotherapist (as suggested by primary physician) (as advised by primary physician)</li>
                            <li>(1-2 visits per week) Respiratory therapist</li>
                            <li>ICU supplies</li>
                            <li>clinical quality checks every week</li>
                          </ul>
                        <h4>Infrastructure in ICUs</h4>
                          <ul>
                            <li>Pumps for bi-pap therapy</li>
                            <li>DVT Pedal</li>
                            <li>Other equipment as determined by the Portea doctor's clinical evaluation</li>
                            <li>E-Monitoring</li>
                          </ul><br></br>

                        <h3>ASSISTANT PACKAGE</h3><br></br>
                          <ul>
                            <li>clinical group</li>
                            <li>2 nursing assistants with ICU experience, each working 12 hours</li>
                            <li>Physiotherapist (as suggested by primary physician) (as advised by primary physician)</li>
                            <li>(1-2 visits per week) Respiratory therapist</li>
                            <li>clinical quality checks every week</li>
                          </ul>
                        <h4>Facilities for ICUs Oxygen concentrator</h4>
                          <ul>
                            <li>Other equipment as determined by a Portea doctor's clinical evaluation Home visit reports Condition of E-Monitoring</li>
                            <li>GCS: 10 or greater, stable vital signs, and limited mobility</li>
                            <li>Condition of E-Monitoring</li>
                          </ul><br></br>

                        <h3>Why Choose Ace Medicare ?</h3><br></br>

                        <h4>CERTIFIED ICU NURSES & ICU DOCTOR</h4><br></br>
                        <li> With extensive hands on experience, professional certifications and training our specialists ICU Doctors ensure quality care.</li><br></br>

                        <h4>BEST IN CLASS ICU DEVICES</h4><br></br>
                        <li>We offer a wide range of products for all patients needs across an affordable price range.</li><br></br>

                        <h4>COST ADVANTAGE</h4><br></br>
                        <li>Complete expenses of the critical care services come at a fraction of cost of the same services at a hospital.</li><br></br>

                        <h4> WORKING WITH THE TREATING PHYSICIAN</h4><br></br>
                        <li>We involve your own physician in the care plan design and share patient data periodically with him/ her.</li><br></br>

                         <h4>CONTINUOUS MONITORING</h4><br></br>
                         <li>Our trained specialists monitor patient progress and recovery data from our state of the art devices.</li><br></br>

                         <h4>LOWER RISK OF INFECTION</h4><br></br>
                         <li>Home ICU setups not only save time, money but also substantially reduce the risk of infections for the patient.the patient's chance of contracting an illness.</li><br></br>

                        <h3>How Do Intensive Care Units (ICU) Or Critical Care Units (CCU) Work?</h3><br></br>
                          <ul>
                            <li>In order to establish whether the patient needs long-term ICU care at home, the doctor will assess them and recommend them to Portea.</li>
                            <li>The patient decides to go with Portea's treatment plan after having a thorough conversation with a qualified health manager.</li>
                            <li>Our clinical team evaluates the patient and ascertains the patient's needs, including those for devices and consumables, as the first step in the patient evaluation process. the patient's residence will be assessed to determine the best location for an ICU setup.</li>
                            <li>Our staff delivers the equipment and sets up the ICU one day before to the patient's arrival at home.</li>
                            <li>The chief nurse and the Portea Clinical team prepare the house for the arrival of the patients.</li>
                            <li>The patient's condition is continuously monitored by our proprietary software.</li>
                          </ul><br></br>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
      </div>
    </>
  );
}