import React from "react";
import ServiceSideBar from "../modules/ServiceSideBar";

export default function DoctoratHome(){
  return(
    <>
      <div class="content">
            <div class="container-fluid">
                <div class="row">
                  <div class="col-sm-9">
                    <div class="card">
                      <div class="card-body">
                        <h3>What are the advantages of having a doctor visit you at home? </h3><br></br>
                        <p className=""> TThe most practical response to the growing pandemic concern is to bring healthcare services inside the comfort and security of the home. Modern technology's development has helped this issue tremendously. One such business that offers expert medical care in the convenience of your home is Home Doctor Service. It represents one of the most significant advancements in home health care.</p>


                        <h3> Advantages of home medical visit services </h3><br></br>
                        <p className="">There are many benefits to having a doctor come to your home, and at-home medical care is becoming more and more popular.</p>
                        <p className="">Going to a doctor's appointment at home is better to visiting a clinic or hospital for a number of reasons. Let's examine a few advantages of doctor at home services:</p>

                        <h4>Services that are adaptable and well-suited</h4><br></br>
                        <li> Families can choose from a wide range of medical consultation options. By providing the necessary care that patients demand, these services are tailored to fit their needs. In an emergency, the patient can dial the doctor's direct number and have them come to their home for a consultation and treatment.</li><br></br>

                        <h4>No Lines to Wait in </h4><br></br>
                        <li>In hospitals, we frequently wait in lengthy lines, which may not be Always be accommodating when the sufferer is weak and exposed. Additionally, with the increase of Covid instances, scheduling a doctor's home visit might assist you from contracting viruses and infections. Patients are more relaxed when they are examined by a doctor in the familiar surroundings of their home.</li><br></br>

                        <h4>Individualized Care</h4><br></br>
                        <li>Compared to the majority of patients' care in hospitals or clinics, home doctor visits are more individualised. As they gain a thorough grasp of your environment, doctors can assess you more accurately. Additionally, they are less easily distracted because you are their only patient and have their whole focus.</li><br></br>

                        <h4> Time Restrictions</h4><br></br>
                        <li> Doctors' and patients' physical and mental health are negatively impacted by time restrictions.Doctors frequently feel overworked and unable to give their patients the care they need in the time available. Doctors who see patients at home can devote more time and provide more individualised care. As a result, one may easily arrange their day and fit it into their hectic schedule.</li><br></br>

                         <h4>Convenience</h4><br></br>
                         <li>It is more convenient to have a doctor come to your home because doing so saves you the time, money, and stress of going to the hospital. Home visits are more private and comforting for terminally ill patients since they keep them in a secure setting.</li><br></br>

                         <h4>Economical</h4><br></br>
                         <li>If you're like the majority of people, you've definitely panicked and hurried to an emergency department or after-hours clinic, only to learn that you could have saved hundreds of rupees by simply waiting until the morning.as well as seeing your primary care doctor. Having a doctor come to your home allows you to avoid costly emergency room visits. Furthermore, the price of an in-home doctor visit is considerably less expensive than standard hospital fees. Patients who actually need the service can save time, money, and hospital resources by using a home doctor service.</li><br></br>

                         <h4>Ideal for patients who are elderly</h4><br></br>
                         <li>Patients who are elderly are frequently quite frail and prone to infections. They become weaker as a result of this damaging their immune system. They may not always have the means to go to the doctor. You don't have to be concerned about subjecting your loved ones to the inconvenience of going to the doctor.</li><br></br>

                        <h3>Who Needs Doctor Visits at Home?</h3><br></br>
                        <p className="">It is advised that patients with the following illnesses choose a doctor visit at home.</p>
                          <ul>
                            <li>Elderly people with COPD (Chronic Pulmonary Disease).</li>
                            <li>Patients with diabetes or those needing infection control.</li>
                            <li>Patients with recently developed illnesses.</li>
                            <li>Cardiac patients.</li>
                            <li>Patients with viral illnesses, such as the seasonal flu.</li>
                            <li>Utilizing doctor-at-home services has several benefits and is getting more and more popular.</li>
                          </ul><br></br>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
      </div>
    </>
  );
}