import React from 'react'
import { useState, useEffect,useRef } from 'react';
import { Link,useNavigate,useLocation,useParams } from 'react-router-dom';
import Https from '../../Https';
import AlertSuccess from '../ReturnResponse/AlertSuccess';
import AlertWarning from '../ReturnResponse/AlertWarning';
import AlertDanger from '../ReturnResponse/AlertDanger';

function SuccessAppt() {
    const { pathname } = useLocation();
    useEffect(() => {
	  window.scrollTo(0, 0);
	}, [pathname]);
    const {appt_no} = useParams();
    
    const navigate = useNavigate();
    const[successAlertMsg, setSuccessAlertMsg] = useState();
    const[warningAlertMsg, setWarningAlertMsg] = useState();
    const[dangerAlertMsg, setDangerAlertMsg] = useState();

    const userDetails = localStorage.getItem('user_info');
    const jsonUserDetails = JSON.parse(userDetails);

    const [appointment, setAppointment] = useState([]);
    ////////// use effect for fetch active department list
    useEffect(()=>{
        fetchAllAppointment();
    },[]);
    /////////   call api for get active department list                
    const fetchAllAppointment = () =>{
        Https.post('/get_appointment_row',{appointment_no:appt_no}).then((response) =>{
            console.log('appointment data');
            console.log(response);
            setAppointment(response.data);
            setTimeout(() =>{
                navigate('/my-appointment');
            },5000);
        });  
    }




    const Ref = useRef(null);
 
    // The state for our timer
    const [timer, setTimer] = useState('00:00:00');
 
    const getTimeRemaining = (e) => {
        const total = Date.parse(e) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        const hours = Math.floor((total / 1000 / 60 / 60) % 24);
        return {
            total, hours, minutes, seconds
        };
    }
 
    const startTimer = (e) => {
        let { total, hours, minutes, seconds }
                    = getTimeRemaining(e);
        if (total >= 0) {
 
            // update the timer
            // check if less than 10 then we need to
            // add '0' at the beginning of the variable
            setTimer(
                (hours > 9 ? hours : '0' + hours) + ':' +
                (minutes > 9 ? minutes : '0' + minutes) + ':'
                + (seconds > 9 ? seconds : '0' + seconds)
            )
        }
    }
 
    const clearTimer = (e) => {
 
        // If you adjust it you should also need to
        // adjust the Endtime formula we are about
        // to code next   
        setTimer('00:00:10');
 
        // If you try to remove this line the
        // updating of timer Variable will be
        // after 1000ms or 1sec
        if (Ref.current) clearInterval(Ref.current);
        const id = setInterval(() => {
            startTimer(e);
        }, 1000)
        Ref.current = id;
    }
 
    const getDeadTime = () => {
        let deadline = new Date();
 
        // This is where you need to adjust if
        // you entend to add more time
        deadline.setSeconds(deadline.getSeconds() + 5);
        return deadline;
    }
 
    // We can use useEffect so that when the component
    // mount the timer will start as soon as possible
 
    // We put empty array to act as componentDid
    // mount only
    useEffect(() => {
        clearTimer(getDeadTime());
    }, []);
 
    // Another way to call the clearTimer() to start
    // the countdown is via action event from the
    // button first we create function to be called
    // by the button
    const onClickReset = () => {
        clearTimer(getDeadTime());
    }
    

  return (
    <>
    <div class="main-wrapper">
        <div class="page-content pt-0 pb-0">
            <div class="booking-success-col">
                <div class="booking-middle">
                    <div class="booking-middle-inner">
                        <span class="tick-icon"><img src="../../mobile_assets/img/check-circle-big.png" alt="Appointment Booked Successfully" /></span>
                        <div class="success-full-text">
                            <h3>Appointment booked Successfully!</h3>
                            <p>Dear {jsonUserDetails.name} Your Appointment No: <br/><span>{appointment.appointment_no}</span> on <span>{appointment.appointment_date} {appointment.appointment_time} {appointment.appointment_day}</span> in <span>{appointment.department_name} Department</span></p>
                        </div>
                        <div class="view-btn-col"><a href="javascript:void(0)" class="btn">{timer}</a></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default SuccessAppt